<template>
  <div class="text">
    {{ record.title }}
  </div>
</template>

<script>
  export default {
    name: "Texts",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        spinning: false
      };
    },
    methods: {
    },
    mounted() {
    }
  };

</script>

<style scoped>
  .text {
    width: 100%;
    height: 100%;
    font-size: 1em;
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
    color: #bff7fe;
  }
</style>