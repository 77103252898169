<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="ClassRoom">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_topbtn_bg01.png" alt="">
            <div class="ClassRoomBox">
                {{ roomName }}
            </div>
            <a-modal
                title="设置教室"
                :width="500"
                v-if="isRoom"
                :visible="true"
                @ok="handleCancel"
                @cancel="handleCancel"
                :maskClosable="false"
            >
                <a-form class="addClass" autocomplete="off">
                    <a-form-item label="教室" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select placeholder="请选择教室" v-model="classRoomId" @change="change()">
                        <a-select-option v-for="(item, index) in dataInfo" :key="index" :value="item.id">
                        {{ item.roomName }}
                        </a-select-option>
                    </a-select>
                    </a-form-item>
                </a-form>
            </a-modal>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiClassRoom
    } from '@/api/other'
    export default {
        name: "ClassRoom",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
        },
        // 监听属性 类似于data概念      
        computed: {
        },
        data() {
            return {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 7 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 13 }
                },
                dataInfo: [],
                isRoom: false,
                roomName: '暂无教室',
                classRoomId: 0,
                spinning: false
            }
        },
        methods: {
            change() {
                for (let i = 0; i < this.dataInfo.length; i++) {
                    if (this.dataInfo[i].id === this.classRoomId) {
                        this.roomName = this.dataInfo[i].roomName
                        break
                    }
                }
            },
            handleCancel() {
                this.$emit('classRoom', this.classRoomId)
                this.isRoom = false
            },
            loadData() {
                this.spinning = true
                bigScreenZixishiClassRoom(
                    {
                        subSchoolId: this.currentSubSchoolId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                        if (this.dataInfo.length > 0) {
                            this.classRoomId = this.dataInfo[0].id
                            this.roomName = this.dataInfo[0].roomName
                        }
                        this.isRoom = true
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            }
         },
        mounted() {
            const that = this
            this.loadData()
        }
    }
</script>

<style scoped>
.ClassRoom {
    width: 100%;
    height: 100%;
    position: relative;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.ClassRoomBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: normal;
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 0 0.5em;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}
</style>