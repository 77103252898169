<template>
    <div class="CalssProblemStatistics">
        <div class="CalssProblemStatisticsBox">
            <div class="CalssProblemStatisticsBoxTop">
                <div
                    class="CalssProblemStatisticsBoxTopType"
                    :class="item.id === type ? 'CalssProblemStatisticsBoxTopTypeActive' : ''"
                    v-for="(item, index) in typeList"
                    :key="index"
                >
                    {{ item.name }}
                </div>
            </div>
            <div
                class="CalssProblemStatisticsBoxCenter"
                :style="{'top': `-${top * record.height}px`}"
                v-if="dataInfo.length > 0"
            >
                <div
                    class="CalssProblemStatisticsBoxCenterItem"
                    v-for="(item, index) in dataInfo"
                    :key="index"
                >
                    <div class="CalssProblemStatisticsBoxCenterItemTitle">
                        <div class="CalssProblemStatisticsBoxCenterItemTitleLeft">
                            {{ item.bclassName }}
                        </div>
                        <div class="CalssProblemStatisticsBoxCenterItemTitleRight">
                            <div class="CalssProblemStatisticsBoxCenterItemTitleRightBox">
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxTitle">
                                    人均全科学习时长
                                </div>
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxNum" v-html="formateTime(item.totalSec)">
                                </div>
                            </div>
                            <div class="CalssProblemStatisticsBoxCenterItemTitleRightBox">
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxTitle">
                                    人均全科做题量
                                </div>
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxNum">
                                    <span>
                                        {{ item.totalCount }}
                                    </span>
                                    道
                                </div>
                            </div>
                            <div class="CalssProblemStatisticsBoxCenterItemTitleRightBox">
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxTitle">
                                    平均做题得分率
                                </div>
                                <div class="CalssProblemStatisticsBoxCenterItemTitleRightBoxNum">
                                    <span>
                                        {{ item.total.rate }}
                                    </span>
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="CalssProblemStatisticsBoxCenterItemBox">
                        <div class="CalssProblemStatisticsBoxCenterItemBoxLeft">
                            <vue-seamless-scroll v-if="item.list.length>=1" :data="item.list"  class="seamless-warp" :class-option="defaultOption">
                                <ul class="ul-scoll">
                                  <li v-for="(items, indexs) in item.list" class="CalssProblemStatisticsBoxCenterItemBoxLeftItem" :key="indexs">
                                    <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemLeft">
                                        {{ items.name }}
                                    </div>
                                    <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRight">
                                        <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBox">
                                            <img
                                                class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBoxImg"
                                                src="../../assets/img/imgStaly1/class_subject_icon1.png"
                                            >
                                            <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBoxDiv" v-html="formateTime(items.sec)">
                                            </div>
                                        </div>
                                        <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBox">
                                            <img
                                                class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBoxImg"
                                                src="../../assets/img/imgStaly1/class_subject_icon2.png"
                                            >
                                            <span>{{ items.count }}</span>道
                                        </div>
                                        <div class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBox">
                                            <img
                                                class="CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBoxImg"
                                                src="../../assets/img/imgStaly1/class_subject_icon3.png"
                                            >
                                            <span>{{ items.rate }}</span>%
                                        </div>
                                    </div>
                                  </li>
                                </ul>
                            </vue-seamless-scroll>
                        </div>
                        <div class="CalssProblemStatisticsBoxCenterItemBoxRight">
                            <div :id="`radar${record.id}${index}`" style="width: 100%;height: 100%;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        bigScreenBclassLearningCount
    } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import 'echarts-gl'
    const echarts = require('echarts/lib/echarts')
    require('echarts/lib/chart/radar')
    require('echarts/lib/component/tooltip')
    require('echarts/lib/component/legend')
    require('echarts/lib/component/title')
    let intervalId
    export default {
        name: "CalssProblemStatistics",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length            
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
                }
            }
        },
        data() {
            return {
                dataInfo: [],
                list: [
                    {
                        value: '地理',
                        name: 'dili'
                    },
                    {
                        value: '化学',
                        name: 'huaxue'
                    },
                    {
                        value: '历史',
                        name: 'lishi'
                    },
                    {
                        value: '日语',
                        name: 'riyu'
                    },
                    {
                        value: '生物',
                        name: 'shengwu'
                    },
                    {
                        value: '数学',
                        name: 'shuxue'
                    },
                    {
                        value: '物理',
                        name: 'wuli'
                    },
                    {
                        value: '英语',
                        name: 'yingyu'
                    },
                    {
                        value: '语文',
                        name: 'yuwen'
                    },
                    {
                        value: '政治',
                        name: 'zhengzhi'
                    }
                ],
                top: 0,
                type: '0',
                typeList: [
                    {
                        name: '今天',
                        id: '0'
                    },
                    {
                        name: '近七天',
                        id: '1'
                    }
                ],
            };
        },
        methods: {
            formateTime(time) {
                if (time == 0 || time == '-') {
                    return '0秒'
                }
                const h = parseInt(time / 3600)
                const minute = parseInt(time / 60 % 60)
                const second = Math.ceil(time % 60)
                const hours = h < 10 ? '0' + h : h
                const formatSecond = second > 59 ? 59 : second
                return `${hours > 0 ? `<span style="font-size: 1.2em;">${hours}</span>时` : ''}${minute > 0 ? `<span style="font-size: 1.2em;">${minute}</span>分` : ''}${formatSecond > 0 ? `<span style="font-size: 1.2em;">${formatSecond}</span>秒` : ''}`
            },
            loaData(type) {
                const that = this
                that.type = type
                bigScreenBclassLearningCount(
                    {
                        subSchoolId: this.currentSubSchoolId,
                        timeType: this.type,
                        useFakeData: this.record.useFakeData,
                    },
                    (resp) => {
                        const resData = resp.data
                        for (let i = 0; i < resData.length; i++) {
                            const list = []
                            let totalSec = 0
                            let totalCount = 0
                            for (let j = 0;j < that.list.length; j++) {
                                resData[i][that.list[j].name].name = that.list[j].value
                                list.push(resData[i][that.list[j].name])
                                totalSec += resData[i][that.list[j].name].sec == '-' ? 0 : resData[i][that.list[j].name].sec * 1
                                totalCount += resData[i][that.list[j].name].count == '-' ? 0 : resData[i][that.list[j].name].count * 1
                            }
                            that.dataInfo.push({
                                bclassId: resData[i].bclassId,
                                bclassName: resData[i].bclassName,
                                totalSec: totalSec.toFixed(1),
                                totalCount: totalCount.toFixed(1),
                                total: resData[i].total,
                                list: list
                            })
                            that.$nextTick(() => {
                                that.radar(i)
                            })
                        }
                        intervalId = setInterval(() => {
                            if (that.top < that.dataInfo.length - 1) {
                                that.top += 1
                            } else {
                                that.dataInfo = []
                                that.$nextTick(() => {
                                    that.top = 0
                                    if (that.type === '1') {
                                        that.loaData('0')
                                    } else {
                                        that.loaData('1')
                                    }
                                    clearInterval(intervalId)
                                })
                            }
                        }, 8000)
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            radar (index) {
                const that = this
                const data = that.dataInfo[index].list
                const xData = []
                const yData = [
                    {
                        value: [],
                        name: '学习时长',
                        itemStyle: {
                            normal: {
                                color: '#089f5e'
                            },
                        },
                        areaStyle: { // 单项区域填充样式
                            normal: {
                                color: '#12618b'
                            }
                        }
                    },
                    {
                        value: [],
                        name: '做题数量',
                        itemStyle: {
                            normal: {
                                color: '#1d8dff'
                            },
                        },
                        areaStyle: { // 单项区域填充样式
                            normal: {
                                color: '#1348b4'
                            }
                        }
                    }
                ]
                for (let i = 0; i < data.length; i++) {
                    xData.push({
                        name: data[i].name
                    })
                    yData[0].value.push(data[i].sec === '-' ? 0 : data[i].sec * 1)
                    yData[1].value.push(data[i].count === '-' ? 0 : data[i].count * 60)
                }
                let echartsBox1 = echarts.init(document.getElementById('radar' + this.record.id + index))
                let option = {
                    legend: {
                        data: ['学习时长', '做题数量'],
                        textStyle: { // 图例项的文本样式。
                            color: '#c5c7dd',
                            fontSize: that.record.fontSize
                        }
                    },
                    radar: {
                        indicator: xData,
                        name: {
                            textStyle: {
                                color: '#a1a7c6',
                                fontSize: that.record.fontSize
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#1a3194',
                                },
                        },
                        splitArea: {
                            areaStyle : {
                                color: '#1a3296', // 图表背景的颜色
                            }
                        },
                        splitLine : {
                            lineStyle : {
                                color : '#2b409b', // 设置网格的颜色
                            },
                        }
                    },
                    series: [
                        {
                            type: 'radar',
                            data: yData
                        }
                    ]
                }
                echartsBox1.setOption(option)
            }
        },
        mounted() {
            this.loaData('0')
        }
    }
</script>

<style scoped>
    .CalssProblemStatisticsBox {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .CalssProblemStatisticsBoxTop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        height: 3em;
        width: 100%;
        z-index: 2;
        background: url('../../assets/img/imgStaly1/class_subject_bg.png') no-repeat;
        background-size: 100% 100%;
        background-color: #051962;
        font-size: 1.4em;
        font-weight: normal;
    }
    .CalssProblemStatisticsBoxTopType {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 2em;
        color: #02c7fd;
    }
    .CalssProblemStatisticsBoxTopTypeActive {
        color: #fff;
        background: url('../../assets/img/imgStaly1/toggle_select_btn.png') no-repeat;
        background-size: 100% 100%;
    }
    .CalssProblemStatisticsBoxCenter {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        transition: 1s;
    }
    .CalssProblemStatisticsBoxCenterItem {
        width: 100%;
        height: 100%;
        padding: 0 1em;
        padding-top: 4em;
        padding-bottom: 1em;
        border-bottom: 2px solid #0ce9fe;
        background: linear-gradient(rgba(9, 34, 142, 0),rgba(9, 34, 142, 1));
    }
    .CalssProblemStatisticsBoxCenterItemTitle {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 25%;
        padding: 3% 0;
        margin-top: 1%;
    }
    .CalssProblemStatisticsBoxCenterItemTitleLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
        height: 100%;
        aspect-ratio: 21 / 25;
        background: url('../../assets/img/imgStaly1/class_study_bg.png') no-repeat;
        background-size: auto 100%;
        font-size: 1.6em;
        font-weight: normal;
        color: #fff;
        padding: 1%;
    }
    .CalssProblemStatisticsBoxCenterItemTitleRight {
        display: flex;
        align-items: center;
        width: 95%;
        height: 100%;
        margin-left: 5%;
        background: linear-gradient(to right, rgba(15, 96, 219, 1), rgba(15, 96, 219, 0));
        border: 1px solid;
        border-right: none;
        border-image-source: linear-gradient(to right, rgba(4, 142, 239, 1), rgba(4, 142, 239, 0));
        border-image-slice: 1;
        padding-left: 5%;
    }
    .CalssProblemStatisticsBoxCenterItemTitleRightBox {
        width: 33%;
        text-align: center;
        font-size: 1.4em;
        font-weight: normal;
    }
    .CalssProblemStatisticsBoxCenterItemTitleRightBoxTitle {
        color: #02c9fe;
    }
    .CalssProblemStatisticsBoxCenterItemTitleRightBoxNum {
        color: #fff;
    }
    .CalssProblemStatisticsBoxCenterItemTitleRightBoxNum span {
        font-size: 1.2em;
    }
    .CalssProblemStatisticsBoxCenterItemBox {
        display: flex;
        width: 100%;
        height: 74%;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeft {
        width: 60%;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItem {
        display: flex;
        width: 100%;
        background: url('../../assets/img/imgStaly1/class_subject_bg.png') no-repeat;
        background-size: 100% 100%;
        margin: 1em 0;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItemLeft {
        padding: 0.5em 1em;
        font-size: 1em;
        color: #04e9f6;
        background: url('../../assets/img/imgStaly1/study_statitle_toggle.png') no-repeat;
        background-size: 100% 100%;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItemRight {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBox {
        display: flex;
        align-items: center;
        width: 30%;
        font-size: 1em;
        color: #fff;
        font-weight: normal;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBox span {
        font-size: 1.2em;
    }
    .CalssProblemStatisticsBoxCenterItemBoxLeftItemRightBoxImg {
        width: 1em;
        height: auto;
        margin-right: 0.5em;
    }
    .CalssProblemStatisticsBoxCenterItemBoxRight {
        width: 40%;
        height: 100%;
    }
</style>