<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="QuestionsRightRatio">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_content_smallbg.png" alt="">
            <div class="QuestionsRightRatioTitle">
                {{ record.title }}
            </div>
            <div class="QuestionsRightRatioCenter">
                <div class="QuestionsRightRatioBox" v-if="dataInfo.length > 0">
                    <div class="QuestionsRightRatioEcharts" :id="record.id"></div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiStudyingQuestionRightSort,
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    const echarts = require('echarts/lib/echarts')
    import $ from 'jquery'
    require("echarts/lib/chart/bar")
    export default {
        name: "QuestionsRightRatio",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
        }, 
        watch: {
            classRoomId () {
                this.classRoom()
            }
        }, 
        computed: {
            index () {
                const myDate = new Date()
                const hours = myDate.getHours()
				const minutes = myDate.getMinutes()
                const myTime = myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        return i
                    }
                    if (myTime < startTime) {
                        return i === 0 ? 0 : i - 1
                    }
                }
                return this.timeData.length === 0 ? 0 : this.timeData.length - 1
            }
        },
        data() {
            return {
                dataInfo: [],
                timeData: [],
                spinning: false
            }
        },
        methods: {
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.loadData()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            loadData() {
                const that = this
                bigScreenZixishiStudyingQuestionRightSort(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                        for (let i = 0; i < this.dataInfo.length; i++) {
                            const score = this.dataInfo[i].score
                            this.dataInfo[i].score = score * 1
                        }
                        if (this.dataInfo.length > 0) {
                            this.$nextTick(() => {
                                that.pie()
                            })
                        }
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            pie() {
                const xData = []
                const yData = []
                for (let i = 0; i < this.dataInfo.length; i++) {
                    xData.push(this.dataInfo[i].score)
                    yData.push(this.dataInfo[i].stuName)
                }
                let echarts1 = echarts.init($(`#${this.record.id}`)[0])
                let option = {
                    grid: {
                        left: this.record.fontSize * 3.2,
                        right: this.record.fontSize * 3.2,
                        top: 0,
                        bottom: 0
                    },
                    xAxis: {
                        show: false
                    },
                    yAxis: {
                        type: 'category',
                        data: yData,
                        inverse: true,
                        animationDuration: 300,
                        animationDurationUpdate: 300,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#9fbbea'
                            },
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.record.fontSize * 0.6 + 'px',
                            textStyle: {
                                color: "#9fbbea"
                            }
                        },
                        max: 5
                    },
                    series: [
                        {
                            realtimeSort: true,
                            type: 'bar',
                            data: xData,
                            barWidth: this.record.fontSize,
                            label: {
                                show: true,
                                position: 'right',
                                valueAnimation: true,
                                textStyle: {
                                    color: "#9fbbea"
                                },
                                formatter: (val) => {
                                    return `${val.value}%`
                                }
                            },
                            itemStyle:{
                                normal:{
                                    color: new echarts.graphic.LinearGradient(
                                        1, 0, 0, 0, [{//只要修改前四个参数就ok
                                                offset: 0,
                                                color: '#feb75b'
                                            }, //柱图渐变色
                                            {
                                                offset: 1,
                                                color: '#cc7d18'
                                            }
                                        ]
                                    ),
                                }
                            }
                        }
                    ],
                    animationDuration: 0,
                    animationDurationUpdate: 2000,
                    animationEasing: 'linear',
                    animationEasingUpdate: 'linear'
                }
                echarts1.setOption(option)
            },
            Loop() {
                const that = this
                bigScreenZixishiStudyingQuestionRightSort(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        for (let i = 0; i < resData.length; i++) {
                            let isStudent = true
                            for (let j = 0; j < this.dataInfo.length; j++) {
                                if (this.dataInfo[j].stuName === resData[i].stuName) {
                                    this.dataInfo[j].score = resData[i].score
                                    isStudent = false
                                }
                            }
                            if (isStudent) {
                                this.dataInfo.push(resData[i])
                            }
                        }
                        if (this.dataInfo.length > 0) {
                            this.$nextTick(() => {
                                that.pie()
                            })
                        }
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            classRoom() {
                const that = this
                this.time()
                setInterval(function(){
                    that.Loop()
                }, 5000)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.QuestionsRightRatio {
    width: 100%;
    height: 100%;
    position: relative;
}
.QuestionsRightRatioTitle {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    font-size: 1.2em;
    padding-left: 1em;
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.QuestionsRightRatioCenter {
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8%;
    padding-top: 0;
}
.QuestionsRightRatioBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.QuestionsRightRatioEcharts {
    width: 100%;
    height: 100%;
}
</style>