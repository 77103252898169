import { render, staticRenderFns } from "./AutonomousHeroesList2.vue?vue&type=template&id=5ba5a72f&scoped=true&"
import script from "./AutonomousHeroesList2.vue?vue&type=script&lang=js&"
export * from "./AutonomousHeroesList2.vue?vue&type=script&lang=js&"
import style0 from "./AutonomousHeroesList2.vue?vue&type=style&index=0&id=5ba5a72f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba5a72f",
  null
  
)

export default component.exports