<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="NumberExercises">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_content_bigbg.png" alt="">
            <div class="NumberExercisesTitle">
                {{ record.title }}
            </div>
            <div class="NumberExercisesCenter">
                <div class="NumberExercisesBox">
                    <div class="NumberExercisesBoxTop">
                        <div
                            v-for="(item, index) in top"
                            :key="index"
                            class="NumberExercisesItem NumberExercisesItemAvatar"
                        >
                            <div class="NumberExercisesItemLeft">
                                <div class="avatar">
                                    <img class="bgImg" style="border-radius: 50%;" src="../../assets/img/imgStaly1/study_content_rankavatar.png" alt="">
                                    <img v-if="item.headImg" class="nameImg" :src="item.headImg" alt="">
                                    <img v-else class="nameImg" src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                    <img v-if="index === 0" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank01.png" alt="">
                                    <img v-if="index === 1" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank02.png" alt="">
                                    <img v-if="index === 2" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank03.png" alt="">
                                </div>
                                {{ item.stuName }}
                            </div>
                            <div class="NumberExercisesItemRight">
                                <div class="NumberExercisesItemRightLeft">
                                    <div
                                        class="icon iconAvatar"
                                    >
                                    </div>
                                    做题：
                                </div>
                                <div class="NumberExercisesItemRightRight">
                                    <span class="number">{{ item.score }}</span>道
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="NumberExercisesBoxBottom">
                        <vue-seamless-scroll v-if="dataInfo.length>=1" :data="dataInfo" @ScrollEnd="ScrollEnd" :class-option="defaultOption" class="seamless-warp" style="margin-top: 0;">
                            <div
                            v-for="(item, index) in dataInfo"
                            :key="index"
                            class="NumberExercisesItem"
                        >
                            <div class="NumberExercisesItemLeft">
                                <div class="avatar">
                                    <img class="bgImg" style="border-radius: 50%;" src="../../assets/img/imgStaly1/study_content_rankavatar.png" alt="">
                                    <img v-if="item.headImg" class="nameImg" :src="item.headImg" alt="">
                                    <img v-else class="nameImg" src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                </div>
                                {{ item.stuName }}
                            </div>
                            <div class="NumberExercisesItemRight">
                                <div class="NumberExercisesItemRightLeft">
                                    <div
                                        class="icon"
                                    >
                                    </div>
                                    做题：
                                </div>
                                <div class="NumberExercisesItemRightRight">
                                    <span class="number">{{ item.score }}</span>道
                                </div>
                            </div>
                        </div>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import {
        bigScreenZixishiStudyingQuestionCountSort,
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    export default {
        name: "NumberExercises",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
            vueSeamlessScroll
        }, 
        watch: {
            classRoomId () {
                this.classRoom()
            }
        }, 
        computed: {
            defaultOption() {
                return {
                step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum - 3 : 6, // 开始无缝滚动的数据量 this.dataList.length             
                hoverStop: false, // 是否开启鼠标悬停stop          
                direction: 1, // 0向下 1向上 2向左 3向右      
                openWatch: true, // 开启数据实时监控刷新dom                
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            },
            index () {
                const myDate = new Date()
                const hours = myDate.getHours()
				const minutes = myDate.getMinutes()
                const myTime = myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        return i
                    }
                    if (myTime < startTime) {
                        return i === 0 ? 0 : i - 1
                    }
                }
                return this.timeData.length === 0 ? 0 : this.timeData.length - 1
            }
        },
        data() {
            return {
                dataInfo: [],
                timeData: [],
                top: [],
                spinning: false
            }
        },
        methods: {
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.loadData()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            loadData() {
                this.top = []
                this.dataInfo = []
                bigScreenZixishiStudyingQuestionCountSort(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        for (let i = 0; i < resData.length; i++) {
                            if (i < 3) {
                                this.top.push(resData[i])
                            } else {
                                this.dataInfo.push(resData[i])
                            }
                        }
                        console.log('')
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            classRoom() {
                const that = this
                this.time()
                setInterval(function(){
                    that.loadData()
                }, 10000)
            },
            ScrollEnd() {
                console.log("ScrollEnd")
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.NumberExercises {
    width: 100%;
    height: 100%;
    position: relative;
}
.NumberExercisesTitle {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 15%;
    font-size: 1.2em;
    padding-left: 1em;
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.NumberExercisesCenter {
    position: absolute;
    width: 100%;
    height: 85%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8%;
    padding-top: 0;
}
.NumberExercisesBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.NumberExercisesBoxTop {
    width: 100%;
}
.NumberExercisesBoxBottom {
    width: 100%;
    height: calc(100% - 10.5em);
    overflow: hidden;
}
.NumberExercisesItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3em;
    border: 1px solid #05a3d1;
    background-color: #08489a;
    border-radius: 1.5em;
    padding-right: 1em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
    color: #fff;
}
.NumberExercisesItemAvatar {
    border: 1px solid #7a7061;
    background-color: #163d66;
}
.NumberExercisesItemLeft {
    display: flex;
    align-items: center;
    height: 100%;
    width: 50%;
}
.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 2.7em;
    width: 2.7em;
    border-radius: 50%;
    margin-left: 0.1em;
    margin-right: 0.5em;
}
.nameImg {
    height: 2.1em;
    width: 2.1em;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.rankImg {
    height: 1em;
    width: 1em;
    position: absolute;
    left: -0.1em;
    top: -0.1em;
    margin: auto;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.NumberExercisesItemRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
}
.NumberExercisesItemRightLeft {
    display: flex;
    align-items: center;
    justify-content: right;
    flex: 1;
}
.icon {
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #0da3f5;
    margin-right: 0.5em;
}
.iconAvatar {
    background-color: #fdb75c;
}
.NumberExercisesItemRightRight {
    min-width: 3.1em;
    text-align: right;
}
.number {
    font-size: 1.2em;
}
</style>