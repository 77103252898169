var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ClassCulture"},[_c('div',{staticClass:"dataScreen_content_bottom_box dataScreen_content_bottom_box4"},[_c('span',{staticClass:"dataScreen_content_bottom_box_title dataScreen_content_bottom_box_title2"},[_vm._v(_vm._s(_vm.record.title))]),_c('div',{staticClass:"dataScreen_content_top_left_bottom"},[_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(0),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.classGoal))])]),_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(1),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.slogan))])]),_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(2),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.bclassStudyStyle))])]),_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(3),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.classMotto))])]),_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(4),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.bclassAtmosphere))])]),_c('div',{staticClass:"dataScreen_content_top_left_bottom_box"},[_vm._m(5),_c('span',{staticClass:"dataScreen_content_top_left_bottom_box_right"},[_vm._v(_vm._s(_vm.bclassInfo.bclass.classPledge))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left"},[_c('span',[_vm._v("目标")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left"},[_c('span',[_vm._v("口号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left"},[_c('span',[_vm._v("学风")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"},[_c('span',[_vm._v("班训")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"},[_c('span',[_vm._v("班风")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"},[_c('span',[_vm._v("誓词")])])
}]

export { render, staticRenderFns }