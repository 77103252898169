<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="dataScreen_content_2box_bottom_bottom">
      <div class="dataScreen_title" style="height: 20%;">
        <TitlesImg :type="record.titleImgType" />
        <div class="titlePosition" style="margin-left: 2em;">
          {{ record.title }}
        </div>
      </div>
      <div class="dataScreen_content_2box_bottom_bottom_content">
        <div class="dataScreen_content_2box_bottom_bottom_content_echarts" :id="record.id">
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/bar')
  require('echarts/lib/chart/pictorialBar')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  require('echarts/lib/component/dataZoom')
  require('echarts/lib/chart/custom')
  import {
    bigscreenLeftV2
  } from '@/api/other'
  export default {
    name: "StyleStudyRanking",
    components: { TitlesImg },
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        spinning: false,
        maxNum: 6
      };
    },
    methods: {
      loadData(sType) {
        this.spinning = true
        const that = this
        bigscreenLeftV2({
            sType: sType,
            subSchoolId: this.currentSubSchoolId,
            gradeIds: this.record.gradeIds.join(','),
            useFakeData: this.record.useFakeData?this.record.useFakeData:0
          },
          (resp) => {
            const resData = resp.data
            let xAxisData = []
            const VALUE = [];
            for (let i = 0; i < resData.length; i++) {
              const e = resData[i]
              xAxisData.push(e.name)
              VALUE.push(parseInt(e.score))
            }
            let options = {
              xAxis: {
                type: 'category',
                data: xAxisData,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: '#7ebaf2',
                  },
                },
                axisTick: {
                  show: false,
                  length: 9,
                  alignWithLabel: true,
                  lineStyle: {
                    color: '#7DFFFD',
                  },
                },
                axisLabel: {
                  fontSize: this.record.fontSize * 0.7,
                  interval: 0,
                  rotate: 330
                },
              },
              yAxis: {
                type: 'value',
                min: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: '#7ebaf2',
                  },
                },
                splitLine: {
                  show: false,
                },

                axisTick: {
                  show: false,
                },
                axisLabel: {
                  fontSize: this.record.fontSize,
                },
                boundaryGap: ['20%', '20%'],
              },
              dataZoom: [
                //滑动条
                {
                  xAxisIndex: 0, //这里是从X轴的0刻度开始
                  show: false, //是否显示滑动条，不影响使用
                  type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                  startValue: -1, // 从头开始。
                  endValue: that.maxNum, // 一次性展示6个
                  realtime: false, //是否实时更新
                  filterMode: "filter"
                }
              ],
              series: [
                {
                  // /数据图
                  data: VALUE,
                  type: 'bar',
                  barWidth: this.record.fontSize,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      { offset: 0, color: 'rgba(206, 122, 15, 0)' },
                      { offset: 1, color:  'rgba(206, 122, 15, 1)' }
                    ])
                  },
                  z: 1,
                },
                {
                  // /数据图
                  data: VALUE,
                  type: 'bar',
                  barGap: 0,
                  barWidth: this.record.fontSize,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      { offset: 0, color: 'rgba(251, 147, 11, 0)' },
                      { offset: 1, color: 'rgba(251, 147, 11, 1)' }
                    ])
                  },
                  z: 1,
                },
                {
                  // 最上面菱形
                  data: VALUE,
                  type: 'pictorialBar',
                  symbol: 'diamond',
                  symbolSize: [this.record.fontSize * 2, this.record.fontSize],
                  symbolPosition: 'end',
                  symbolOffset: ['', '-50%'],
                  // 添加的属性。
                  label: {
                    show: true,
                    position: 'top',
                    color: '#FFFFFF'
                  },
                  itemStyle: {
                    color:'rab(245, 173, 63)'
                  },
                  z: 3
                },
              ]
            }
            let synthesisBox = echarts.init(document.getElementById(this.record.id))
            if (xAxisData.length > that.maxNum) {
              setInterval(function () {
                // 每次向后滚动一个，最后一个从头开始。
                console.log(options.dataZoom[0])
                if (options.dataZoom[0].endValue == xAxisData.length) {
                  options.dataZoom[0].endValue = that.maxNum;
                  options.dataZoom[0].startValue = 0;
                } else {
                  options.dataZoom[0].endValue = options.dataZoom[0].endValue + 1;
                  options.dataZoom[0].startValue = options.dataZoom[0].startValue + 1;
                }
                synthesisBox.setOption(options);
              }, 2000);
            } else {
              synthesisBox.setOption(options)
            }
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      }
    },
    mounted() {
      this.loadData(1)
    }
  };

</script>

<style scoped>

</style>
