  <template>
    <div class="itemBoxComparison">
      <div class="footer">
        <img style="max-width: 100%;" :src="record.img" alt="">
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "Footer",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      data() {
        return {
        };
      },
      methods: {
      },
      mounted() {
      }
    };
  
  </script>
  
  <style scoped>
    .footer{
      width: 100%;
      display: flex;
      justify-content: center;
    }
  </style>
  