<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
  <div class="dataScreen_content_3box_top">
    <div
      style="position: absolute;top: 4%;right: 0;left: 0;margin: auto;font-size: 1em;color: #b27513;font-weight: 700;display: flex;justify-content: center;">
      {{ record.title }}
    </div>
    <img src="../../assets/img/imgStaly1/jiaoxue_difficulty_show_bg.png" class="dataScreen_content_3box_top_img" alt="">
    <div class="AchievementTop">
      <div class="AchievementTopBox">
        <span class="AchievementTopBoxSpan" :class="gradeName==item?'AchievementTopBoxActived':''"
          v-for="(item, index) in gradeNames" :key="index">{{item}}</span>
      </div>
    </div>
    <div class="AchievementBox">
      <div class="AchievementBottom">
        <div class="dataScreen_content_3box_top_content_right_box" :style="{'width': '32%'}"
          v-for="(item, index) in studyList" :key="index">
          <div class="AchievementBoxTop">
            <img src="../../assets/img/imgStaly1/jiaoxue_discipline_btn.png" class="AchievementBoxTopImg" alt="">
            <div class="AchievementBottomTitle">
              <span>{{item.disciplineName}}</span>
            </div>
          </div>
          <div class="AchievementEcharts">
            <div class="AchievementEchartsBing" :id="'bing'+ record.id + index"></div>
            <img class="AchievementEchartsBg" src="../../assets/img/imgStaly1/jiaoxue_3dbase_img.png" alt="">
          </div>
          <div class="AchievementEchartsBar" :id="'bar'+ record.id + index"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- </a-spin> -->
</template>

<script>
  import 'echarts-gl'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/pie')
  require('echarts/lib/chart/line')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  import {
    bigScreenShowType2
  } from '@/api/other'
  export default {
    name: "AutonomousHeroesList",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {
    },
    data() {
      return {
        spinning: false,
        studyList: [],
        gradeNames: [],
        gradeName: '',
        gradeId: '',
        xuekeIds: '',
        index: 0,
        index2: 0,
        maxNum: 2,
        color: [
          ['#fa8b19', '#f7e422'],
          ['#08acff', '#81dafe'],
          ['#9138e4', '#e96fcf'],
          ['#3bad7d', '#58dba7'],
          ['#f34946', '#ff8676'],
          ['#6a5eff', '#a58efd']
        ]
      };
    },
    methods: {
      loadData() {
        this.spinning = true
        const that = this
        bigScreenShowType2({
          disciplineIds: this.xuekeIds,
          gradeIds: this.gradeId,
          subSchoolId: this.currentSubSchoolId,
          useFakeData: this.record.useFakeData
        },
          (resp) => {
            const resData = resp.data[0].items
            this.studyList = resData
            that.$nextTick(() => {
              that.bar()
              that.bing()
            })
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      bar() {
        const that = this
        for (let i = 0; i < this.studyList.length; i++) {
          let data = this.studyList[i].bclassScoreAccuracys
          let colorarr
          for (let k = 0; k < this.record.xuekeIds.length; k++) {
            if (this.record.xuekeIds[k] == this.studyList[i].disciplineId) {
              colorarr = this.color[k % this.color.length]
            }
          }
          let yData = []
          let serData = []
          data.forEach(item => {
            yData.push(item.bclassName)
            serData.push(item.avgAccuracy)
          })
          let options = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {},
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
              min: 0,  //取0为最小刻度
              max: 100, //取100为最大刻度
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  fontSize: this.record.fontSize * 0.6,
                  color: '#CCECF9',
                },
                formatter: `{value}%`,
              },
              splitLine: {
                lineStyle: {
                  color: '#0C399D'
                }
              },
            },
            yAxis: {
              type: 'category',
              data: yData,
              axisLine: {
                show: true,
                lineStyle: {
                  width: 1,
                  color: '#0C399D',
                }
              },
              axisLabel: {
                margin: this.record.fontSize * 0.7,
                textStyle: {
                  fontSize: this.record.fontSize * 0.7,
                  color: '#CCECF9',
                }
              },
            },
            dataZoom: [
              //滑动条
              {
                yAxisIndex: 0, //这里是从X轴的0刻度开始
                show: false, //是否显示滑动条，不影响使用
                type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                startValue: -1, // 从头开始。
                endValue: that.maxNum, // 一次性展示4个
                realtime: false, //是否实时更新
                filterMode: "filter"
              }
            ],
            series: [
              {
                type: 'bar',
                data: serData,
                barWidth: '50%',
                itemStyle: {
                  barBorderRadius: [50, 50, 50, 50], //柱体圆角   
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 1, 0, [{//只要修改前四个参数就ok
                      offset: 0,
                      color: colorarr[0]
                    }, //柱图渐变色
                    {
                      offset: 1,
                      color: colorarr[1]
                    }
                  ]
                  )
                }
              }
            ]
          }
          let echartsBox1 = echarts.init(document.getElementById('bar' + this.record.id + i))
          if (yData.length > that.maxNum && that.record.xuekeIds.length > 3) {
            echartsBox1.setOption(options)
            let refreshIntervalId = setInterval(function () {
              // 每次向后滚动一个，最后一个从头开始。
              if (options.dataZoom[0].endValue == yData.length) {
                if (that.record.xuekeIds.length > 1) {
                  clearInterval(refreshIntervalId)
                  if (i == 0) {
                    that.Loop()
                  }
                } else {
                  options.dataZoom[0].endValue = that.maxNum
                  options.dataZoom[0].startValue = 0
                  echartsBox1.setOption(options)
                }
              } else {
                options.dataZoom[0].endValue = options.dataZoom[0].endValue + 1
                options.dataZoom[0].startValue = options.dataZoom[0].startValue + 1
                echartsBox1.setOption(options)
              }
            }, 2000)
          } else {
            echartsBox1.setOption(options)
            if (that.record.gradeIds.length > 1 && i == 0) {
              setTimeout(() => {
                that.Loop()
              }, 5000)
            }
          }
        }
      },
      Loop() {
        if (this.record.xuekeIds.length > 3) {
          this.index2++
          if (this.index2 >= this.record.xuekeIds.length) {
            this.index2 = 0
            if (this.record.gradeIds.length > 1) {
              this.setGrade()
            }
          }
          let list = []
          list[0] = this.record.xuekeIds[this.index2]
          list[1] = (this.index2 + 1) < this.record.xuekeIds.length ? this.record.xuekeIds[this.index2 + 1] : this.record.xuekeIds[(this.index2 + 1) - this.record.xuekeIds.length]
          list[2] = (this.index2 + 2) < this.record.xuekeIds.length ? this.record.xuekeIds[this.index2 + 2] : this.record.xuekeIds[(this.index2 + 2) - this.record.xuekeIds.length]
          this.xuekeIds = list.join(',')
          this.loadData()
        } else {
          this.setGrade(1)
        }
      },
      setGrade(val) {
        if (this.record.gradeIds.length > 1) {
          this.index++
          if (this.index >= this.record.gradeIds.length) {
            this.index = 0
          }
          if (this.record.gradeNames.length > 3) {
            this.gradeNames = [this.record.gradeNames[this.index -1] ? this.record.gradeNames[this.index -1] : this.record.gradeNames[this.record.gradeNames.length -1], this.record.gradeNames[this.index], this.record.gradeNames[this.index+1] ? this.record.gradeNames[this.index+1] : this.record.gradeNames[0]]
          }
          this.gradeName = this.record.gradeNames[this.index]
          this.gradeId = this.record.gradeIds[this.index]
          if (val) {
            this.loadData()
          }
        }
      },
      // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
      getParametricEquation(startRatio, endRatio, isSelected, isHovered, k) {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
          isSelected = false;
        }

        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== "undefined" ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;

        // 返回曲面参数方程
        return {
          u: {
            min: -Math.PI,
            max: Math.PI * 3,
            step: Math.PI / 32
          },

          v: {
            min: 0,
            max: Math.PI * 2,
            step: Math.PI / 20
          },

          x: function (u, v) {
            if (u < startRadian) {
              return (
                offsetX +
                Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            if (u > endRadian) {
              return (
                offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          y: function (u, v) {
            if (u < startRadian) {
              return (
                offsetY +
                Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            if (u > endRadian) {
              return (
                offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
              );
            }
            return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
          },

          z: function (u, v) {
            if (u < -Math.PI * 0.5) {
              return Math.sin(u);
            }
            if (u > Math.PI * 2.5) {
              return Math.sin(u);
            }
            return Math.sin(v) > 0 ? 1 : -1;
          }
        };
      },
      // 生成模拟 3D 饼图的配置项
      getPie3D(pieData, internalDiameterRatio) {
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
          typeof internalDiameterRatio !== "undefined" ?
            (1 - internalDiameterRatio) / (1 + internalDiameterRatio) :
            1 / 3;

        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
          sumValue += pieData[i].value;

          let seriesItem = {
            name: typeof pieData[i].name === "undefined" ?
              `series${i}` : pieData[i].name,
            type: "surface",
            parametric: true,
            wireframe: {
              show: false
            },
            pieData: pieData[i],
            pieStatus: {
              selected: false,
              hovered: false,
              k: k
            }
          };

          if (typeof pieData[i].itemStyle != "undefined") {
            let itemStyle = {};

            typeof pieData[i].itemStyle.color != "undefined" ?
              (itemStyle.color = pieData[i].itemStyle.color) :
              null;
            typeof pieData[i].itemStyle.opacity != "undefined" ?
              (itemStyle.opacity = pieData[i].itemStyle.opacity) :
              null;

            seriesItem.itemStyle = itemStyle;
          }
          series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i++) {
          endValue = startValue + series[i].pieData.value;

          series[i].pieData.startRatio = startValue / sumValue;
          series[i].pieData.endRatio = endValue / sumValue;
          series[i].parametricEquation = this.getParametricEquation(
            series[i].pieData.startRatio,
            series[i].pieData.endRatio,
            true,
            false,
            1
          );

          startValue = endValue;

          legendData.push(series[i].name);
        }

        // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
        series.push({
          name: "mouseoutSeries",
          type: "surface",
          parametric: true,
          wireframe: {
            show: false
          },
          itemStyle: {
            opacity: 1,
            color: "rgba(18,236,252,.5)"
          },
          parametricEquation: {
            u: {
              min: 0,
              max: Math.PI * 2,
              step: Math.PI / 20
            },
            v: {
              min: 0,
              max: Math.PI,
              step: Math.PI / 1.4
            },
            x: function (u, v) {
              return Math.sin(v) * Math.sin(u) + Math.sin(u);
            },
            y: function (u, v) {
              return Math.sin(v) * Math.cos(u) + Math.cos(u);
            },
            z: function (u, v) {
              return Math.cos(v) > 0 ? 0.1 : -0.1;
            }
          }
        });

        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
          //animation: false,
          legend: {
            show: true,
            data: legendData,
            textStyle: {
              color: '#fff'
            }
          },
          tooltip: {
            formatter: params => {
              if (params.seriesName !== 'mouseoutSeries') {
                return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
              }
            }
          },
          xAxis3D: {
            min: -1.3,
            max: 1.3
          },
          yAxis3D: {
            min: -1.3,
            max: 1.3
          },
          zAxis3D: {
            min: -1.3,
            max: 1.3
          },
          grid3D: {
            show: false,
            boxHeight: 10,
            // top: '30%',
            left: "0",
            bottom: "50%",
            viewControl: {
              //3d效果可以放大、旋转等，请自己去查看官方配置
              alpha: 20,
              // beta: 40,
              rotateSensitivity: 0,
              zoomSensitivity: 0,
              panSensitivity: 0,
              autoRotate: true,
              //   autoRotateSpeed: 5,
              //   autoRotateAfterStill: 10
            },
          },
          series: series
        };
        return option;
      },
      bing() {
        for (let i = 0; i < this.studyList.length; i++) {
          let colorarr
          for (let k = 0; k < this.record.xuekeIds.length; k++) {
            if (this.record.xuekeIds[k] == this.studyList[i].disciplineId) {
              colorarr = this.color[k % this.color.length]
            }
          }
          // 传入数据生成 option
          let options = this.getPie3D([
            {
              name: '客观题',
              value: this.studyList[i].keguanNum,
              itemStyle: {
                color: colorarr[0],
              }
            }, {
              name: '主观题',
              value: this.studyList[i].zhuguanNum,
              itemStyle: {
                color: colorarr[1]
              }
            },
          ], 0.59)
          let synthesisBox = echarts.init(document.getElementById('bing' + this.record.id + i))
          synthesisBox.setOption(options)
        }
      }
    },
    mounted() {
      if (this.record.gradeNames.length < 4) {
        this.gradeNames = this.record.gradeNames
      } else {
        this.gradeNames = [this.record.gradeNames[this.record.gradeNames.length -1],this.record.gradeNames[0],this.record.gradeNames[1]]
      }
      this.gradeName = this.record.gradeNames[this.index]
      this.gradeId = this.record.gradeIds[this.index]
      if (this.record.xuekeIds.length < 4) {
        this.xuekeIds = this.record.xuekeIds.join(',')
      } else {
        let list = [this.record.xuekeIds[this.index2], this.record.xuekeIds[this.index2 + 1], this.record.xuekeIds[this.index2 + 2]]
        this.xuekeIds = list.join(',')
      }
      this.loadData()
      // this.Loop()
    }
  };

</script>

<style scoped>
  .AchievementTop {
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AchievementTopBox {
    display: flex;
    height: 50%;
    width: 80%;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/img/imgStaly1/jiaoxue_toggle_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .AchievementTopBoxSpan {
    display: inline-block;
    padding: 1% 5%;
    color: #FFF;
    font-size: 1em;
    text-align: center;
  }

  .AchievementTopBoxActived {
    background-image: url('../../assets/img/imgStaly1/jiaoxue_toggle_select.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .AchievementBox {
    position: absolute;
    left: 0;
    top: 15%;
    width: 100%;
    height: 85%;
    padding: 8%;
    padding-bottom: 4%;
    display: flex;
  }

  .AchievementBottom {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .AchievementBoxTop {
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AchievementBoxTopImg {
    width: 50%;
  }

  .AchievementBottomTitle {
    position: absolute;
    top: 15%;
    left: 0;
    color: #DDFAFC;
    font-size: 1em;
    width: 100%;
    text-align: center;
  }

  .AchievementEchartsBar {
    width: 100%;
    height: 50%;
  }

  .AchievementEcharts {
    position: relative;
    margin-top: 5%;
    width: 100%;
    height: 35%;
  }

  .AchievementEchartsBg {
    position: absolute;
    width: 50%;
    top: 66%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .AchievementEchartsBing {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
</style>