<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="HomeWork">
      <vue-seamless-scroll v-if="HomeWorkList.length>=1" :data="HomeWorkList" @ScrollEnd="ScrollEnd"
        class="seamless-warp" :class-option="defaultOption">
        <ul class="ul-scoll">
          <li v-for="(item, index) in HomeWorkList" class="ul_scoll_li" :key="index">
            <div class="HomeWork_Box">
              <img src="../../assets/img/imgStaly1/homework_bg.png" style="width: 100%;height: 100%;" alt="">
              <div class="HomeWork_Box_Center">
                <div class="HomeWork_Box_Center_Top">
                  <div class="HomeWork_Box_Center_Top_Left">
                    {{ item.examPaperName }}
                  </div>
                  <div class="HomeWork_Box_Center_Top_Right">
                    <img class="HomeWork_Box_Center_Top_Right_img" src="../../assets/img/imgStaly1/complete_student_icon.png" alt="">
                    完成人数：{{ item.stuCount }}
                  </div>
                </div>
                <div class="HomeWork_Box_Center_Bottom">
                  <div style="width: 10em;">
                    <img class="HomeWork_Box_Center_Bottom_img" src="../../assets/img/imgStaly1/proportion_icon.png" alt="">
                    及格率：{{ item.goodRate }}
                  </div>
                  <div>
                    <img class="HomeWork_Box_Center_Bottom_img" src="../../assets/img/imgStaly1/proportion_icon.png" alt="">
                    优秀率：{{ item.perfectRate }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </a-spin>
</template>

<script>
  import {
    bigScreenHomeWork
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "HomeWork",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        spinning: false,
        HomeWorkList: []
      };
    },
    methods: {
      loadData() {
        this.spinning = true
        const that = this
        bigScreenHomeWork(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            that.HomeWorkList = resData
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      ScrollEnd() {
        console.log("ScrollEnd")
      }
    },
    mounted() {
      this.loadData()
    }
  };

</script>

<style scoped>
  .HomeWork {
    width: 100%;
    height: 100%;
  }
  .HomeWork_Box {
    position: relative;
    width: 100%;
    height: 6em;
  }
  .HomeWork_Box_Center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 0.8em;
    color: #b2b9d7;
    padding: 1em;
  }
  .HomeWork_Box_Center_Top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3em;
  }
  .HomeWork_Box_Center_Top_Left {
    height: 100%;
    font-size: 1em;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .HomeWork_Box_Center_Top_Right {
    width: 10em;
    height: 100%;
    margin-right: 1em;
    display: flex;
    align-items: flex-end;
  }
  .HomeWork_Box_Center_Top_Right_img {
    width: 1em;
    margin-bottom: 0.2em;
    margin-right: 0.5em;
  }
  .HomeWork_Box_Center_Bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3em;
  }
  .HomeWork_Box_Center_Bottom_img {
    width: 1em;
    margin-right: 0.3em;
    margin-bottom: 0.3em;
  }
</style>