<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="ClassStatistics">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_stacontent_bg.png" alt="">
            <div class="ClassStatisticsBox">
                <div class="ClassStatisticsItem">
                    <div class="ClassStatisticsItemLeft">
                        <img src="../../assets/img/imgStaly1/study_stacontent_icon1.png" alt="">
                    </div>
                    <div class="ClassStatisticsItemRight">
                        <div class="ClassStatisticsItemTitle">
                            学习人数
                        </div>
                        <div class="ClassStatisticsItemBottom">
                            <span class="ClassStatisticsItemNum">{{ dataInfo.studyStuCount }}</span>
                        </div>
                    </div>
                </div>
                <div class="ClassStatisticsItem">
                    <div class="ClassStatisticsItemLeft">
                        <img src="../../assets/img/imgStaly1/study_stacontent_icon2.png" alt="">
                    </div>
                    <div class="ClassStatisticsItemRight">
                        <div class="ClassStatisticsItemTitle">
                            平均做题数量
                        </div>
                        <div class="ClassStatisticsItemBottom">
                            <span class="ClassStatisticsItemNum">{{ dataInfo.avgQuestionCount }}</span>
                        </div>
                    </div>
                </div>
                <div class="ClassStatisticsItem">
                    <div class="ClassStatisticsItemLeft">
                        <img src="../../assets/img/imgStaly1/study_stacontent_icon3.png" alt="">
                    </div>
                    <div class="ClassStatisticsItemRight">
                        <div class="ClassStatisticsItemTitle">
                            答题平均正确率
                        </div>
                        <div class="ClassStatisticsItemBottom">
                            <span class="ClassStatisticsItemNum">{{ dataInfo.avgQuestionRightRate }}</span>%
                        </div>
                    </div>
                </div>
                <div class="ClassStatisticsItem">
                    <div class="ClassStatisticsItemLeft">
                        <img src="../../assets/img/imgStaly1/study_stacontent_icon4.png" alt="">
                    </div>
                    <div class="ClassStatisticsItemRight">
                        <div class="ClassStatisticsItemTitle">
                            平均获得积分
                        </div>
                        <div class="ClassStatisticsItemBottom">
                            <span class="ClassStatisticsItemNum">{{ dataInfo.avgQuestionScore }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiStudyingTop,
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    export default {
        name: "ClassStatistics",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
        },
        watch: {
            classRoomId () {
                this.classRoom()
            }
        },   
        computed: {
            index () {
                const myDate = new Date()
                const hours = myDate.getHours()
				const minutes = myDate.getMinutes()
                const myTime = myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        return i
                    }
                    if (myTime < startTime) {
                        return i === 0 ? 0 : i - 1
                    }
                }
                return this.timeData.length === 0 ? 0 : this.timeData.length - 1
            }
        },
        data() {
            return {
                spinning: false,
                dataInfo: {},
                timeData: [
                    {
                        startTime: '00:00',
                        endTime: '00:00'
                    }
                ]
            }
        },
        methods: {
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.loadData()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            loadData() {
                bigScreenZixishiStudyingTop(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            classRoom() {
                const that = this
                this.time()
                setInterval(function(){
                    that.loadData()
                }, 10000)
            }
         },
        mounted() {
        }
    }
</script>

<style scoped>
.ClassStatistics {
    width: 100%;
    height: 100%;
    position: relative;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.ClassStatisticsBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    font-weight: normal;
    color: #a9caf9;
    width: 100%;
    height: 100%;
    padding: 0 4em;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}
.ClassStatisticsItem {
    display: flex;
    align-items: center;
    height: 100%;
}
.ClassStatisticsItemLeft {
    width: 3em;
    height: 3em;
    margin-right: 1em;
}
.ClassStatisticsItemLeft>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.ClassStatisticsItemBottom {
    color: #fdb75c;
}
.ClassStatisticsItemNum {
    font-size: 1.5em;
}
</style>