<template>
    <div class="DateElement">
        <div class="dataScreen_top_right">
            <span class="dataScreen_top_right_left">{{timeText}}</span>
            <div class="dataScreen_top_right_right">
                <span class="dataScreen_top_right_right_span1">{{dateText}}</span>
                <span class="dataScreen_top_right_right_span2">{{weekText}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBulletinBoardContent} from '@/api/other'
    // import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "DateElement",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            // vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                // 日期
                dateText: '',
                // 时间
                timeText: '',
                // 周几
                weekText: '',
            };
        },
        methods: {
            //  优秀表现 
            getDate() {
                    var myDate = new Date;
                    var year = myDate.getFullYear(); //获取当前年
                    var mon = myDate.getMonth() + 1; //获取当前月
                    var date = myDate.getDate(); //获取当前日
                    var h = myDate.getHours(); //获取当前小时数(0-23)
                    var m = myDate.getMinutes(); //获取当前分钟数(0-59)
                    var s = myDate.getSeconds(); //获取当前秒
                    var week = myDate.getDay();
                    var weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                    // 日期
                    if (mon.toString().length < 2) {
                        mon = '0' + mon
                    }
                    if (date.toString().length < 2) {
                        date = '0' + date
                    }
                    this.dateText = year + "-" + mon + "-" + date
                    // 时间
                    if (h.toString().length < 2) {
                        h = '0' + h
                    }
                    if (m.toString().length < 2) {
                        m = '0' + m
                    }
                    if (s.toString().length < 2) {
                        s = '0' + s
                    }
                    this.timeText = h + ':' + m + ':' + s
                    // 星期几
                    this.weekText = weeks[week]
                },
        },
        mounted() {
            this.getDate()
            const that = this
            setInterval(function (params) {
                    that.getDate()
            }, 1000)
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>