<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="ClassPeriod">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_topbtn_bg01.png" alt="">
            <div class="ClassPeriodBox">
                <span style="margin-right: 1em;">第{{ num[index] }}节课</span>
                {{ timeData[index].startTime }} ~   {{ timeData[index].endTime }}
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    export default {
        name: "ClassPeriod",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
        },
        watch: {
            classRoomId () {
                this.time()
            },
            myDate () {
                const hours = this.myDate.getHours()
				const minutes = this.myDate.getMinutes()
                const myTime = this.myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = this.myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = this.myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        this.index = i
                        return
                    }
                    if (myTime < startTime) {
                        this.index = i === 0 ? 0 : i - 1
                        return
                    }
                }
                this.index = this.timeData.length === 0 ? 0 : this.timeData.length - 1
                return
            }
        },   
        computed: {
        },
        data() {
            return {
                spinning: false,
                timeData: [
                    {
                        startTime: '00:00',
                        endTime: '00:00'
                    }
                ],
                num: {
                    0: '一',
                    1: '二',
                    2: '三',
                    3: '四',
                    4: '五',
                    5: '六',
                    6: '七',
                    7: '八',
                    8: '九',
                    9: '十',
                },
                myDate: '',
                index: 0
            }
        },
        methods: {
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
         },
        mounted() {
            const that = this
            setInterval(function(){
                that.myDate = new Date()
            }, 10000)
        }
    }
</script>

<style scoped>
.ClassPeriod {
    width: 100%;
    height: 100%;
    position: relative;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.ClassPeriodBox {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: normal;
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 0 0.5em;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}
</style>