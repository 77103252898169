<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="LearningOutcomeAll">
            <div class="LearningOutcomeAllTop">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_currenttitle_bg.png" alt="">
                <div class="LearningOutcomeAllTitle">
                    {{ record.title }}
                </div>
            </div>
            <div class="LearningOutcomeAllContent">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_currencon_bg.png" alt="">
                <div class="LearningOutcomeAllBox">
                    <div class="LearningOutcomeAllBoxTop">
                        <div class="LearningOutcomeAllItemName">
                            学生名称
                        </div>
                        <div class="LearningOutcomeAllItemCenter">
                            学习学科
                        </div>
                        <div class="LearningOutcomeAllItemCenter">
                            班级
                        </div>
                        <div class="textAnimation1">
                            正在学习
                        </div>
                    </div>
                    <div class="LearningOutcomeAllBoxCenter">
                        <vue-seamless-scroll v-if="dataInfo.length>=1" :data="dataInfo" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                                <li v-for="(item, index) in dataInfo" class="ul_scoll_li" :key="index">
                                    <div
                                        class="LearningOutcomeAllItem"
                                        :class="index % 2 === 0 ? 'LearningOutcomeAllItem1' : ''"
                                    >
                                        <div class="LearningOutcomeAllItemName">
                                            <img class="nameImg" v-if="item.headImg" :src="item.headImg" alt="">
                                            <img class="nameImg" v-else src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                            {{ item.stuName }}
                                        </div>
                                        <div class="LearningOutcomeAllItemCenter">
                                            <span>
                                                {{ item.discipline }}
                                            </span>
                                        </div>
                                        <div class="LearningOutcomeAllItemCenter">
                                            <span>
                                                {{ item.classRoomName }}
                                            </span>
                                        </div>
                                        <div
                                            class="textAnimation1"
                                            style="-webkit-animation: textAnimation1 1s linear infinite;"
                                        >
                                            {{ formatter(item.batchName) }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import {
        bigScreenZixishiOutSideLearningCurrent
    } from '@/api/other'
    export default {
        name: "LearningOutcomeAll",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        watch: {
        },     
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                dataInfo: [],
                spinning: false
            }
        },
        methods: {
            formatter(val) {
                if (val.length > 10) {
                    return val.substring(0, 5) + '...' + val.substring(val.length - 5, val.length)
                } else {
                    return val
                }
            },
            loadData() {
                bigScreenZixishiOutSideLearningCurrent(
                    {
                        subSchoolId: this.currentSubSchoolId,
                        useFakeData: this.record.useFakeData
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            classRoom() {
                const that = this
                this.loadData()
                setInterval(function(){
                    that.loadData()
                },10000)
            }
        },
        mounted() {
            this.classRoom()
        }
    }
</script>

<style scoped>
@import '../../assets/css/animation.css';
.LearningOutcomeAll {
    width: 100%;
    height: 100%;
    position: relative;
}
.LearningOutcomeAllTop {
    width: 100%;
    height: 8%;
    position: relative;
}
.LearningOutcomeAllTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
}
.LearningOutcomeAllContent {
    height: 92%;
    width: 100%;
    position: relative;
}
.LearningOutcomeAllBox {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding:  0.5em 1em;
}
.LearningOutcomeAllBoxTop {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3em;
    color: #94b2e5;
    font-size: 1em;
}
.LearningOutcomeAllBoxCenter {
    width: 100%;
    height: calc(100% - 6em);
}
.LearningOutcomeAllItem {
    height: 3em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    color: #fff;
}
.LearningOutcomeAllItem1 {
    border: 1px solid #1a59b2;
    background-color: #034293;
}
.LearningOutcomeAllItemName {
    display: flex;
    align-items: center;
    height: 100%;
    width: 20%;
    min-width: 10em;
    padding-left: 2em;
}
.LearningOutcomeAllItemCenter {
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(40% - 6.5em);
    white-space: nowrap;
    overflow: hidden;
}
.LearningOutcomeAllItemCenter>span{
    text-overflow: ellipsis;
    overflow: hidden;
}
.textAnimation1 {
    display: flex;
    align-items: center;
    height: 100%;
    width: 12em;
}
.nameImg {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    margin-right: 0.5em;
}
.bgImg {
    width: 100%;
    height: 100%;
}
</style>