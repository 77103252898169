<template>
  <div>
    <div class="bannerModelBox" v-if="imgType == 1 || imgType == 2 ||imgType == 3">
      <img src="../../assets/img/imgStaly1/center_frame_bg.png" class="bannerbg">
      <video class="bannerMp4" autoplay loop muted v-if="imgType == 3">
        <source :src="record.mp4" type="video/mp4" />
      </video>
      <img class="itemBoxComparison_img" :src="record.img" v-if="imgType == 1" />
      <img class="itemBoxComparison_img" :src="record.imgList[index]" v-if="imgType == 2">
    </div>
    <img
      style="width: 100%;height: 100%;"
      :src="record.img"
      v-if="imgType == 4"
      alt=""
    />
  </div>
</template>

<script>
  export default {
    name: "Title",
    props: {
      record: {
        type: Object
      },
      imgType: {
        type: Number,
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        index: 0
      }
    },
    methods: {
      lunbo() {
        this.index++
        if (this.index >= this.record.imgList.length) {
          this.index = 0
        }
      }
    },
    mounted() {
      if (this.imgType == 2 && this.record.imgList.length > 1) {
        setInterval(this.lunbo(), 2000);
      }
    }
  };

</script>

<style scoped>
  .bannerModelBox {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .bannerbg {
    width: 100%;
    height: 100%;
  }

  .bannerMp4 {
    width: calc(100% - 15%);
    height: calc(100% - 18%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .itemBoxComparison_img {
    width: calc(100% - 15%);
    height: calc(100% - 18%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
</style>