<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="LessonPreparation">
      <div class="LessonPreparation_Title">
        <TitlesImg :type="record.titleImgType" />
        <span class="LessonPreparation_Span">{{ record.title }} </span>
      </div>
      <div class="LessonPreparation_Box">
        <img src="../../assets/img/imgStaly1/beike_bg.png" class="LessonPreparation_Bg">
        <div class="LessonPreparation_Box_Center">
          <div class="LessonPreparation_Box_Center_Top">
            <div style="width: 40%;">
              <span class="LessonPreparation_Box_Center_Top_Span">完成率</span>
              <span class="LessonPreparation_Box_Center_Top_Span" style="margin-left:20%;">学科</span>
            </div>
            <span class="LessonPreparation_Box_Center_Top_Span">紫色：优秀&nbsp;&nbsp;&nbsp;绿色：优良&nbsp;&nbsp;&nbsp;黄色：合格</span>
          </div>
          <div class="dataScreen_content_right_bottom_bottom_content">
            <div class="dataScreen_content_right_bottom_bottom_content_div">
              <vue-seamless-scroll v-if="prepareLessonsList.length>=1" :data="prepareLessonsList" @ScrollEnd="ScrollEnd"
                class="seamless-warp" :class-option="defaultOption">
                <ul class="ul-scoll">
                  <li v-for="(item, index) in prepareLessonsList" class="ul_scoll_li" :key="index">
                    <div class="item dataScreen_content_right_bottom_bottom_content_box">
                      <div class="dataScreen_content_right_bottom_bottom_content_box_left">
                        <div class="dataScreen_content_right_bottom_bottom_content_box_left_box">
                          <img src="../../assets/img/imgStaly1/jiaoyanzu_bg.png"
                            class="dataScreen_content_right_bottom_bottom_content_box_left_img" alt="">
                          <span class="dataScreen_content_right_bottom_bottom_content_box_left_box_span">
                            <span style="font-size: 0.7em;">{{item.completePer}}%</span>
                          </span>
                        </div>
                        <span
                          class="dataScreen_content_right_bottom_bottom_content_box_left_span">{{item.disciplineName}}
                        </span>
                      </div>
                      <div class="dataScreen_content_right_bottom_bottom_content_box_right">
                        <div class="dataScreen_content_right_bottom_bottom_content_box_right_box"
                          v-for="(items, indexs) in item.everyUnitCheckPer" :key="indexs" :style="{'width': 100/item.everyUnitCheckPer.length + '%'}">
                          <span class="dataScreen_content_right_bottom_bottom_content_box_right_box_span"
                            style="background-color: #ed60db;" v-if="items=='100%'"></span>
                            <span class="dataScreen_content_right_bottom_bottom_content_box_right_box_span"
                            style="background-color: #00e98f;" v-else-if="items=='80%'"></span>
                          <span class="dataScreen_content_right_bottom_bottom_content_box_right_box_span"
                            style="background-color: #eee338;" v-else-if="items=='60%'"></span>
                          <span class="dataScreen_content_right_bottom_bottom_content_box_right_box_span" v-else></span>
                          <span class="dataScreen_content_right_bottom_bottom_content_box_right_box_span1"></span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  import {
    bigscreenTeacherMateria
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "LessonPreparation",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {
      vueSeamlessScroll,
      TitlesImg
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        spinning: false,
        prepareLessonsList: []
      };
    },
    methods: {
      loadData(sType) {
        this.spinning = true
        const that = this
        bigscreenTeacherMateria(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            that.prepareLessonsList = resData
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      ScrollEnd() {
        console.log("ScrollEnd")
      }
    },
    mounted() {
      this.loadData(0)
    }
  };

</script>

<style scoped>
.LessonPreparation {
  width: 100%;
  height: 100%;
}
.LessonPreparation_Title {
  position: relative;
  height: 15%;
}
.LessonPreparation_Img {
  width: 100%;
}
.LessonPreparation_Span {
  display: flex;
  align-items: center;
  position: absolute;
  color: #CCECF9;
  font-size: 1em;
  top: 0;
  bottom: 0;
  left: 5%;
  margin: auto;
  margin-left: 5%;
}
.LessonPreparation_Box {
  width: 100%;
  height: 85%;
  position: relative;
}
.LessonPreparation_Bg {
  height: 100%;
  width: 100%;
}
.LessonPreparation_Box_Center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px 0;
}
.LessonPreparation_Box_Center_Top {
  display: flex;
  background-color: #163BAA;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8em;
}
.LessonPreparation_Box_Center_Top_Span {
  color: #63D4FC;
  display: inline-block;
  padding: 10px 0;
}
</style>
