<template>
  <div class="AssessmentWeeksItem">
    <div class="muokuai45Top">
      <span class="mokuai45TopNav" :class="item.id==navId?'mokuai45navActived':''" v-for="item in navList"
        :key="item.id">{{item.name}}</span>
    </div>
    <div class="AssessmentWeeksItemBottom">
      <div class="AssessmentWeeksItemTitle">
        <span class="AssessmentWeeksItemTitleSpan">
          <span class="AssessmentWeeksItemTitleSpan2">班级</span>
        </span>
        <span class="AssessmentWeeksItemTitleSpan2">积分</span>
      </div>
      <div class="dataScreen_content_left_bottom_bottom_content">
        <vue-seamless-scroll v-if="logRecordList.length>=1" :data="logRecordList" @ScrollEnd="ScrollEnd"
          class="seamless-warp" :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in logRecordList" class="AssessmentWeeksItemLi" :key="index">
              <div class="item AssessmentWeeksItemBox">
                <span class="dataScreen_content_left_top_box1_bottom_container_box_left">
                  <span class="AssessmentWeeksItemBoxPosition">
                    <div class="AssessmentWeeksItemBoxBg" :style="{'background-color': item.Ranking == 1 ? '#d0514a' : item.Ranking == 2 ? '#a5a5a5' : item.Ranking == 3 ? '#cb8e12' : '#093db8'}"></div>
                    <span class="AssessmentWeeksItemBoxSpan"
                      style="margin-right:10px;">{{item.Ranking}}
                    </span>
                  </span>
                  <span>{{item.name}}</span>
                </span>
                <span class="dataScreen_content_left_top_box1_bottom_container_box_span">{{item.score}}</span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    bigScreenWeek
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "AssessmentItem",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        navList: [],
        navId: 1,
        num: 0,
        logRecordList: [],
      }
    },
    methods: {
      loadData() {
        const that = this
        bigScreenWeek({
          subSchoolId: this.currentSubSchoolId,
          sType: this.navId,
          gradeIds: this.record.gradeIds.join(','),
          useFakeData: this.record.useFakeData
        },
          (resp) => {
            const resData = resp.data
            this.logRecordList = resData
            let Ranking = 1
            for(let i = 0; i < this.logRecordList.length; i++){
              if (i == 0) {
                this.logRecordList[i].Ranking = Ranking
              } else {
                Ranking += 1
                if (this.logRecordList[i].score == this.logRecordList[i-1].score) {
                  this.logRecordList[i].Ranking = this.logRecordList[i-1].Ranking
                } else {
                  this.logRecordList[i].Ranking = Ranking
                }
              }
            }
            if (this.logRecordList.length < (this.record.limitMoveNum ? this.record.limitMoveNum : 6)) {
              setTimeout(function () {
                that.ScrollEnd()
              }, 3000)
            }
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
          }
        )
      },
      ScrollEnd() {
        console.log("ScrollEnd")
        this.num++
        if (this.num < this.record.assessmentIds.length) {
          this.navId = this.record.assessmentIds[this.num]
          this.loadData()
        } else {
          this.num = 0
          this.navId = this.record.assessmentIds[this.num]
          this.loadData()
        }
      }
    },
    mounted() {
      this.navId = this.record.assessmentIds[0]
      for (let i = 0; i < this.record.assessmentIds.length; i++) {
        this.navList.push({
          id: this.record.assessmentIds[i],
          name: this.record.assessmentNames[i]
        })
      }
      this.loadData()
    }
  };

</script>

<style scoped>
  .AssessmentWeeksItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
  }
  .AssessmentWeeksItemBottom {
    width: 100%;
    height: 90%;
  }
  .AssessmentWeeksItemTitle {
    background-image: url('../../assets/img/imgStaly1/table_header_bg02.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: flex-start;
    padding: 10px 20px;
    color: #53C5F7;
    margin-top: 10px;
  }

  .AssessmentWeeksItemTitleSpan {
    display: inline-block;
    width: 60%;
    margin-left: 10%;
  }
  .AssessmentWeeksItemTitleSpan2 {
    color: #fff;
  }
  .AssessmentWeeksItemLi {
    background-image: url('../../assets/img/imgStaly1/table_bg02.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .AssessmentWeeksItemBoxPosition {
    position: relative;
    width: 3em;
    margin-right: 1em;
  }
  .AssessmentWeeksItemBoxBg {
    width: 3em;
    height: 2em;
    transform: skewX(-20deg);
  }
  .AssessmentWeeksItemBoxSpan {
    position: absolute;
    width: 2.5em;
    height: 1.6em;
    left: 0;
    top: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.2em;
  }

  .muokuai45Top {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/imgStaly1/jiaoxue_toggle_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .mokuai45TopNav {
    height: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CCECF9;
    /* flex: 1; */
    padding: 0 1.5em;
  }

  .mokuai45navActived {
    background-image: url('../../assets/img/imgStaly1/toggle_select_btn01.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .AssessmentWeeksItemBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    padding: 0.7em 1em;
    color: #fff;
    margin-top: 0.7em;
  }

  .dataScreen_content_left_top_box1_bottom_container_box {
    justify-content: flex-start;
    padding: 10px 20px;
  }

  .ul_scoll_li:nth-child(2n) {
    background-color: #07369E;
  }
</style>