import { render, staticRenderFns } from "./ClassConvention2.vue?vue&type=template&id=4551f642&scoped=true&"
import script from "./ClassConvention2.vue?vue&type=script&lang=js&"
export * from "./ClassConvention2.vue?vue&type=script&lang=js&"
import style0 from "./ClassConvention2.vue?vue&type=style&index=0&id=4551f642&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4551f642",
  null
  
)

export default component.exports