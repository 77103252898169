<template>
    <div class="DemocraticAutonomy">
        <div class="dataScreen_content_top_left dataScreen_content_bottom_box2">
            <img src="../../assets/img/imgStaly1/rank_bg.png" class="dataScreen_content_top_left_img" alt="">
            <div class="dataScreen_content_top_left_title">
                <span class="dataScreen_content_top_right_span">{{record.title}}</span>
            </div>
            <div class="dataScreen_content_bottom_box2_content">
                <div class="dataScreen_content_bottom_box2_content_box" v-for="(item,index) in kaoheList" :key="index">
                    <div class="dataScreen_content_bottom_box2_content_box_left">
                        <img :src="item.img" alt="" class="dataScreen_content_bottom_box2_content_box_img">
                        <span class="dataScreen_content_bottom_box2_content_box_right_span">{{item.text}}</span>
                    </div>
                    <div class="dataScreen_content_bottom_box2_content_box_right">
                        <span class="dataScreen_content_bottom_box2_content_box_right_span1">在全校排第 <span
                                class="paiming">{{item.num}}</span>名</span>
                    </div>
                </div>
            </div>
            <div class="dataScreen_content_bottom_box2_content_box2">
                <div class="dataScreen_content_bottom_box2_content_box2_box">
                    <vue-seamless-scroll v-if="excellentShow.length>=1" :data="excellentShow" class="seamless-warp"
                        :class-option="defaultOption">
                        <ul class="ul-scoll">
                          <li v-for="(item, index) in excellentShow" class="ul_scoll_li" :key="index">
                            <div class="swiper-slide dataScreen_content_right_top_box_right_box_box">
                            <img src="../../assets/img/imgStaly1/excellent_show.png"
                                class="dataScreen_content_right_top_box_right_box_box_img" alt="">
                            <span class="dataScreen_content_right_top_box_right_box_box_left"></span>
                            <span class="dataScreen_content_right_top_box_right_box_box_span4"
                                style="width: calc(100% - 20px);">{{item}}</span>
                        </div>
                          </li>
                        </ul>
                      </vue-seamless-scroll>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getBulletinBoardContent, classBoardExcellentList
    } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "DemocraticAutonomy",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                kaoheList: [
                    {
                        id: 1,
                        text: '课堂评价',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859910934.png',
                        num: '-'
                    },
                    {
                        id: 2,
                        text: '日常习惯',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859868044.png',
                        num: '-'
                    },
                    {
                        id: 3,
                        text: '卫生表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859987638.png',
                        num: '-'
                    },
                    {
                        id: 4,
                        text: '宿舍表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859944339.png',
                        num: '-'
                    },
                    {
                        id: 5,
                        text: '三餐表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860025621.png',
                        num: '-'
                    },
                    {
                        id: 6,
                        text: '三操表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860062086.png',
                        num: '-'
                    },
                    {
                        id: 7,
                        text: '综合表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860113258.png',
                        num: '-'
                    }
                ],
                excellentShow: []
            };
        },
        methods: {
            getGoodKeshi() {
                const data = {}
                data.id = this.bclassId
                getBulletinBoardContent(data,
                    (resp) => {
                        const data = resp.data
                        this.kaoheList = [{
                            id: 1,
                            text: '课堂评价',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859910934.png',
                            num: data.rankList[0]
                        },
                        {
                            id: 2,
                            text: '日常习惯',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859868044.png',
                            num: data.rankList[1]
                        },
                        {
                            id: 3,
                            text: '卫生表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859987638.png',
                            num: data.rankList[2]
                        },
                        {
                            id: 4,
                            text: '宿舍表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859944339.png',
                            num: data.rankList[3]
                        },
                        {
                            id: 5,
                            text: '三餐表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860025621.png',
                            num: data.rankList[4]
                        },
                        {
                            id: 6,
                            text: '三操表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860062086.png',
                            num: data.rankList[5]
                        },
                        {
                            id: 7,
                            text: '综合表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860113258.png',
                            num: data.rankList[7]
                        }
                        ]
                        // that.zonghepaiming = data.rankList[6]
                        // this.bclassId = data.bclass.id
                        this.getExcellentList()
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
            //  优秀表现 
            getExcellentList() {
                const data = {}
                data.bclassId = this.bclassId
                classBoardExcellentList(data,
                    (resp) => {
                        const resData = resp.data
                        this.excellentShow = resData
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
        },
        mounted() {
            this.getGoodKeshi()
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>