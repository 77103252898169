<template>
    <a-spin :spinning="spinning" class="spinning_box">
      <div class="dataScreen_content_4box_top_right">
        <div class="dataScreen_content_4box_top_right_content2">
          <img src="../../assets/img/imgStaly1/deyu_contentbox.png" class="dataScreen_content_4box_top_right_content_img" alt="">
          <div class="dataScreen_content_right_top_box">
            <div class="dataScreen_content_right_top_box_left">
              <div class="dataScreen_content_right_top_box_left_box">
                <span class="dataScreen_content_right_top_box_left_box_span">{{warnType==1?'表扬':'警示'}}</span>
                <span class="dataScreen_content_right_top_box_left_box_span1">
                  <span style="color: #F6A832; font-size:1.5em; margin-right: 5px;display: inline-block;">{{WarnList.length}}</span>
                  <span>起</span>
                </span>
              </div>
              <img src="../../assets/img/imgStaly1/deyu_commend_img.png" class="dataScreen_content_right_top_box_left_img" v-if="warnType==1" alt="">
              <img src="../../assets/img/imgStaly1/deyu_caution_img.png" class="dataScreen_content_right_top_box_left_img" v-if="warnType==2" alt="">
            </div>
            <div class="dataScreen_content_right_top_box_right">
              <div class="dataScreen_content_right_top_box_right_box">
                <div class="dataScreen_content_box_bottom_content_box_content">
                  <vue-seamless-scroll v-if="WarnList.length>=1" :data="WarnList" @ScrollEnd="ScrollEnd" class="seamless-warp" :class-option="defaultOption">
                    <ul class="ul-scoll">
                      <li v-for="(item, index) in WarnList" class="ul_scoll_li" :key="index">
                        <div class="item dataScreen_content_left_top_box1_bottom_container_box">
                          <span class="dataScreen_content_right_top_box_right_box_box_left"></span>
                          <span class="dataScreen_content_right_top_box_right_box_box_span1"
                            style="width: calc(100% - 20px);">{{item.msg}}</span>
                        </div>
                      </li>
                    </ul>
                  </vue-seamless-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </template>
  
  <script>
    import {
      warningStu
    } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
      name: "EventReminder",
      props:{
          record: {
            type: Object
          },
          currentSubSchoolId: {
            type: String
          },
          warnType: {
            type: Number
          }
        },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
        warnType: function(e, n) {
          console.log(e, n);
        },
      },
      components: {
        vueSeamlessScroll
      },
      // 监听属性 类似于data概念      
      computed: {
        defaultOption() {
          return {
            step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
            limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
            hoverStop: false, // 是否开启鼠标悬停stop          
            direction: 1, // 0向下 1向上 2向左 3向右      
            openWatch: true, // 开启数据实时监控刷新dom                
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
            waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
          }
        }
      },
      data() {
        return {
          spinning: false,
          WarnList: []
        };
      },
      methods: {
        loadData(sType) {
          this.spinning = true
          warningStu({
              sType: this.warnType,
              subSchoolId: this.currentSubSchoolId,
              gradeIds: this.record.gradeIds.join(','),
              useFakeData: this.record.useFakeData?this.record.useFakeData:0
            },
            (resp) => {
              const resData = resp.data
              this.WarnList = resData
              this.spinning = false
            },
            (err) => {
              console.log('err', err)
              this.$message.error(err)
              this.spinning = false
            }
          )
        },
        ScrollEnd() {
          console.log("ScrollEnd")
        }
      },
      mounted() {
          this.loadData(0)
      }
    };
  
  </script>
  
  <style>
  
  .dataScreen_content_right_top_box_left_box{
  margin-top: 0;
  margin-bottom: 15px;
  }
  </style>
  