<template>
    <div class="StudyHeroList">
        <div class="dataScreen_content_top_right">
            <img src="../../assets/img/imgStaly1/notice_bg.png" class="dataScreen_content_top_right_img" alt="">
            <span class="dataScreen_content_top_right_span">{{record.title}}</span>
            <div class="dataScreen_content_top_right_top">
                <vue-seamless-scroll v-if=" bclassInfo.classNoticeList.length>=1" :data=" bclassInfo.classNoticeList" class="seamless-warp"
                    :class-option="defaultOption">
                    <ul class="ul-scoll">
                      <li v-for="(item, index) in  bclassInfo.classNoticeList" class="ul_scoll_li" :key="index">
                        <span class="notice_span">{{item.content}}</span>
                      </li>
                    </ul>
                  </vue-seamless-scroll>
            </div>
            <div class="dataScreen_content_top_right_bottom">
                <div class="dataScreen_content_top_right_bottom_left">
                    <div class="dataScreen_content_top_right_bottom_left_charts" :id="record.id"></div>
                </div>
                <div class="dataScreen_content_top_right_bottom_box">
                    <div class="dataScreen_content_top_right_bottom_box_top">
                        <span class="dataScreen_content_top_right_bottom_box_top_span"></span>
                        <span class="dataScreen_content_top_right_bottom_box_top_span2">应到人数</span>
                    </div>
                    <div class="dataScreen_content_top_right_bottom_box_bottom">
                        <span
                            class="dataScreen_content_top_right_bottom_box_bottom_span1">{{bclassInfo.stuNum}}</span>
                        <span class="dataScreen_content_top_right_bottom_box_bottom_span2">人</span>
                    </div>
                </div>
                <div
                    class="dataScreen_content_top_right_bottom_box dataScreen_content_top_right_bottom_box2">
                    <div class="dataScreen_content_top_right_bottom_box_top">
                        <span class="dataScreen_content_top_right_bottom_box_top_span"></span>
                        <span class="dataScreen_content_top_right_bottom_box_top_span2">实到人数</span>
                    </div>
                    <div class="dataScreen_content_top_right_bottom_box_bottom">
                        <span
                            class="dataScreen_content_top_right_bottom_box_bottom_span1">{{bclassInfo.stuNum
                            - bclassInfo.leaveNums}}</span>
                        <span class="dataScreen_content_top_right_bottom_box_bottom_span2">人</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBulletinBoardContent} from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
  const echarts = require('echarts/lib/echarts')
  import $ from 'jquery'
  require('echarts/lib/chart/bar')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
    export default {
        name: "NotificationAnnouncement",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                bclassInfo: {
                    classNoticeList: []
                }
            };
        },
        methods: {
            //  优秀表现 
            getGoodKeshi() {
                const data = {}
                data.id = this.bclassId
                getBulletinBoardContent(data,
                    (resp) => {
                        const data = resp.data
                        this.bclassInfo = data
                        this.getSynthesis(data.attendanceRate * 1)
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
            getSynthesis(all) {
                    var getfpkszb = [all]; //非贫困生占比
                    let synthesisBox = echarts.init($(`#${this.record.id}`)[0])
                    let options = {
                        backgroundColor: '#fff',

                        title: {
                            text: '出勤率',
                            textStyle: {
                                color: '#B1B1B1',
                                fontSize: 14
                            },
                            subtext: getfpkszb + '%',
                            subtextStyle: {
                                color: '#559BF4',
                                fontSize: 20,
                                fontWeight: 700
                            },
                            itemGap: 10,
                            left: 'center',
                            top: '36%'
                        },
                        angleAxis: {
                            max: 100,
                            clockwise: true, // 逆时针
                            // 隐藏刻度线
                            show: false
                        },
                        radiusAxis: {
                            type: 'category',
                            show: true,
                            axisLabel: {
                                show: false,
                            },
                            axisLine: {
                                show: false,

                            },
                            axisTick: {
                                show: false
                            },
                        },
                        polar: {
                            center: ['50%', '50%'],
                            radius: '170%' //图形大小
                        },
                        grid: {
                            top: '0px',
                            left: '0px',
                            right: '0px',
                            bottom: '0px'
                        },
                        series: [{
                            stack: '测试',
                            type: 'bar',
                            data: getfpkszb,
                            showBackground: true,
                            backgroundStyle: {
                                color: '#EFF2F5',
                                borderColor: '#EFF2F5',
                                borderWidth: 3
                            },
                            coordinateSystem: 'polar',
                            roundCap: true,
                            barWidth: 7,
                            silent: true,
                            itemStyle: {
                                normal: {
                                    opacity: 1,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#52A3FF'
                                    }, {
                                        offset: 1,
                                        color: '#2D6AFA'
                                    }]),
                                    borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                        [{
                                            offset: 0,
                                            color: '#52A3FF'
                                        }, {
                                            offset: 1,
                                            color: '#2D6AFA'
                                        }]),
                                    borderWidth: 3
                                }
                            },
                        },]
                    };
                    synthesisBox.setOption(options)
                },
        },
        mounted() {
            this.getGoodKeshi()
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>