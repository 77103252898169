<template>
    <div class="StudyHeroList">
        <div class="dataScreen_content_bottom_box dataScreen_content_bottom_box9">
            <span class="dataScreen_content_bottom_box9_title">{{record.title}}</span>
            <div class="dataScreen_content_bottom_box9_bottom">
                <div class="dataScreen_content_bottom_box9_bottom_box">
                    <div class="dataScreen_content_bottom_box9_bottom_box_title">
                        <span class="dataScreen_content_bottom_box9_bottom_box_title_span">自主学习英雄榜</span>
                    </div>
                    <div class="dataScreen_content_bottom_box9_bottom_box_bottom">
                        <vue-seamless-scroll v-if="zishuList.length>=1" :data="zishuList" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                              <li v-for="(item, index) in zishuList" class="ul_scoll_li" :key="index">
                                  <div class="dataScreen_content_bottom_box9_li_box" >
                                    <span class="dataScreen_content_bottom_box9_bottom_box_bottom_span" v-for="(items, indexs) in item" :key="indexs">{{items}}</span>
                                  </div>
                              </li>
                            </ul>
                          </vue-seamless-scroll>
                    </div>
                </div>
                <div class="dataScreen_content_bottom_box9_bottom_box">
                    <div class="dataScreen_content_bottom_box9_bottom_box_title">
                        <span class="dataScreen_content_bottom_box9_bottom_box_title_span">攻坚克难英雄榜</span>
                    </div>
                    <div class="dataScreen_content_bottom_box9_bottom_box_bottom">
                        <vue-seamless-scroll v-if="gongjianList.length>=1" :data="gongjianList" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                              <li v-for="(item, index) in gongjianList" class="ul_scoll_li" :key="index">
                                  <div class="dataScreen_content_bottom_box9_li_box" >
                                    <span class="dataScreen_content_bottom_box9_bottom_box_bottom_span" v-for="(items, indexs) in item" :key="indexs">{{items}}</span>
                                  </div>
                              </li>
                            </ul>
                          </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {classBoardHeros} from '@/api/other'
    // import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "StudyHeroList2",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            // vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                // zishuList: ['张强', '李四', '网三', '李强', '六四', '张忠', '张强1', '李四1', '网三1', '李强1', '六四1', '张忠1'],
                zishuList: [],
                gongjianList: []
            };
        },
        methods: {
            //  优秀表现 
            getExcellentList(heroType) {
                const data = {}
                data.bclassId = this.bclassId
                data.heroType = heroType
                classBoardHeros(data,
                    (resp) => {
                        const resData = resp.data
                        console.log('英雄榜', resp)
                            if (heroType == 1) {
                                this.gongjianList = this.splitArrayIntoChunks(resData, 2)
                            } else if (heroType == 0) {
                                this.zishuList = this.splitArrayIntoChunks(resData, 2)
                            }
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
            splitArrayIntoChunks(array, chunkSize) {
    var result = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}
        },
        mounted() {
            this.getExcellentList(0)
            this.getExcellentList(1)
            console.log(this.bclassId)
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
 
</style>