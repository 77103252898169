<template>
  <div class="BannerModelBclass">
    <a-carousel class="Slideshow_box_Box" :autoplay="autoplay" :dots="dots">
      <img class="Slideshow_box_carousel_img" v-for="(item, index) in imgList" :key="index" :src="item.content" alt="">
    </a-carousel>
  </div>
</template>

<script>
import {getBulletinBoardContent} from '@/api/other'
  export default {
    name: "BannerModelBclass",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      },
      bclassId: {}
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        autoplay: true,
        dots: false,
        imgList: []
      }
    },
    methods: {
      getGoodKeshi() {
                const data = {}
                data.id = this.bclassId
                getBulletinBoardContent(data,
                    (resp) => {
                        this.imgList = resp.data.classAlbumList
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
    },
    mounted() {
      this.getGoodKeshi()
    }
  };

</script>

<style scoped>
  .Slideshow_box_Box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .Slideshow_box_carousel {}

  .Slideshow_box_carousel_img {
    width: 100%;
    height: 100%;
  }
  
</style>
<style>
  .BannerModelBclass .slick-slider, .BannerModelBclass .slick-list, .BannerModelBclass .slick-track, .BannerModelBclass .slick-slide div{
    height: 100%;
  }
</style>