<template>
  <div class="dataScreenBox">
    <span class="dataScreenSpan">{{dateText}}</span>
    <span class="dataScreenSpan" style="margin:0 15px;">{{timeText}}</span>
    <span class="dataScreenSpan">{{weekText}}</span>
  </div>
</template>

<script>
  export default {
    name: "Footer",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        // 日期
        dateText: '',
        // 时间
        timeText: '',
        // 周几
        weekText: '',
      };
    },
    methods: {
      getDate() {
        var myDate = new Date()
        var year = myDate.getFullYear() // 获取当前年
        var mon = myDate.getMonth() + 1 // 获取当前月
        var date = myDate.getDate() // 获取当前日
        var h = myDate.getHours() // 获取当前小时数(0-23)
        var m = myDate.getMinutes() // 获取当前分钟数(0-59)
        var s = myDate.getSeconds() // 获取当前秒
        var week = myDate.getDay()
        var weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
        // 日期
        if (mon.toString().length < 2) {
          mon = '0' + mon
        }
        if (date.toString().length < 2) {
          date = '0' + date
        }
        this.dateText = year + '-' + mon + '-' + date
        // 时间
        if (h.toString().length < 2) {
          h = '0' + h
        }
        if (m.toString().length < 2) {
          m = '0' + m
        }
        if (s.toString().length < 2) {
          s = '0' + s
        }
        this.timeText = h + ':' + m + ':' + s
        // 星期几
        this.weekText = weeks[week]
      }
    },
    mounted() {
      const that = this
      setInterval(function () {
        that.getDate()
      }, 1000)
    }
  }
</script>

<style scoped>
  .dataScreenBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .dataScreenSpan {
    color: #CCECF9;
    font-size: 2.5em;
  }
</style>