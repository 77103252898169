  <template>
    <div>
      <div class="itemBoxComparison">
        <div class="top">
          <span class="titleTop">{{ record.title }}</span>
        </div>
        <video class="mokuaiMp4" autoplay loop muted v-if="record.name=='mokuai1'">
          <source :src="record.mp4" type="video/mp4">
        </video>
        <img :src="record.img" class="mokuaiMp4" alt="" v-if="record.name=='mokuai25' || record.name=='mokuai31' || record.name=='mokuai33' || record.name=='mokuai53' || record.name=='mokuai66' || record.name=='mokuai67' || record.name=='mokuai68' || record.name=='mokuai99'">
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: "Title",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      data() {
        return {
          spinning: false
        };
      },
      methods: {
      },
      mounted() {
      }
    };
  
  </script>
  
  <style scoped>
    .top {
      height: 80%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
    .titleTop {
      font-size: 2.5em;
      width: 100%;
      text-align: center;
      color: #fff;
      letter-spacing: 8px;
      background-image:-webkit-linear-gradient(top, #fff,#fff,#8beef8);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      font-weight: 700;
      -webkit-transform: skew(-10deg); /* for Chrome||Safari */
      -ms-transform: skew(-10deg); /* for IE */
      -moz-transform:skew(-10deg);/* for Firefox */
      -o-transform:skew(-10deg);/* for Opera */
    }
  </style>
  