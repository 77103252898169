<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="GainPointsAll">
            <div class="GainPointsAllTop">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_statitle_bg1.png" alt="">
                <div class="GainPointsAllTitle">
                    {{ record.title }}
                </div>
            </div>
            <div class="GainPointsAllCenter">
                <img class="bgImg" src="../../assets/img/imgStaly1/beike_bg.png" alt="">
                <div class="GainPointsAllScroll">
                    <div class="GainPointsAllBoxTop">
                        <div
                            v-for="(item, index) in top"
                            :key="index"
                            class="GainPointsAllItem GainPointsAllItemAvatar"
                        >
                            <div class="GainPointsAllItemLeft">
                                <div class="avatar">
                                    <img class="bgImg" style="border-radius: 50%;" src="../../assets/img/imgStaly1/study_content_rankavatar.png" alt="">
                                    <img v-if="item.headImg" class="nameImg" :src="item.headImg" alt="">
                                    <img v-else class="nameImg" src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                    <img v-if="index === 0" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank01.png" alt="">
                                    <img v-if="index === 1" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank02.png" alt="">
                                    <img v-if="index === 2" class="rankImg" src="../../assets/img/imgStaly1/study_content_rank03.png" alt="">
                                </div>
                                {{ item.stuName }}
                                {{ item.roomName }}
                            </div>
                            <div class="GainPointsAllItemRight">
                                <div class="GainPointsAllItemRightLeft">
                                    <div
                                        class="icon iconAvatar"
                                    >
                                    </div>
                                    获得积分：
                                </div>
                                <div class="GainPointsAllItemRightRight">
                                    <span class="number">{{ item.score }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="GainPointsAllBoxBottom">
                        <vue-seamless-scroll v-if="dataInfo.length>=1" :data="dataInfo" @ScrollEnd="ScrollEnd" :class-option="defaultOption" class="seamless-warp" style="margin-top: 0;">
                            <div
                                v-for="(item, index) in dataInfo"
                                :key="index"
                                class="GainPointsAllItem"
                            >
                                <div class="GainPointsAllItemLeft">
                                    <div class="avatar">
                                        <img class="bgImg" style="border-radius: 50%;" src="../../assets/img/imgStaly1/study_content_rankavatar.png" alt="">
                                        <img v-if="item.headImg" class="nameImg" :src="item.headImg" alt="">
                                        <img v-else class="nameImg" src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                    </div>
                                    {{ item.stuName }}
                                    {{ item.roomName }}
                                </div>
                                <div class="GainPointsAllItemRight">
                                    <div class="GainPointsAllItemRightLeft">
                                        <div
                                            class="icon"
                                        >
                                        </div>
                                        获得积分：
                                    </div>
                                    <div class="GainPointsAllItemRightRight">
                                        <span class="number">{{ item.score }}</span>
                                    </div>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import {
        bigScreenZixishiOutSideLearningIntegral
    } from '@/api/other'
    export default {
        name: "GainPointsAll",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        computed: {
            defaultOption() {
                return {
                step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum - 3 : 6, // 开始无缝滚动的数据量 this.dataList.length             
                hoverStop: false, // 是否开启鼠标悬停stop          
                direction: 1, // 0向下 1向上 2向左 3向右      
                openWatch: true, // 开启数据实时监控刷新dom                
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        watch: {
        },
        data() {
            return {
                dataInfo: [],
                top: [],
                spinning: false
            }
        },
        methods: {
            loadData() {
                this.top = []
                this.dataInfo = []
                bigScreenZixishiOutSideLearningIntegral(
                    {
                        subSchoolId: this.currentSubSchoolId,
                        useFakeData: this.record.useFakeData,
                    },
                    (resp) => {
                        const resData = resp.data
                        for (let i = 0; i < resData.length; i++) {
                            if (i < 3) {
                                this.top.push(resData[i])
                            } else {
                                this.dataInfo.push(resData[i])
                            }
                        }
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            ScrollEnd() {
                console.log("ScrollEnd")
            }
        },
        mounted() {
            const that = this
            this.loadData()
        }
    }
</script>

<style scoped>
.GainPointsAll {
    width: 100%;
    height: 90%;
}
.GainPointsAllTop {
    position: relative;
    width: 100%;
    height: 10%;
  }
.GainPointsAllTitle {
display: flex;
align-items: center;
justify-content: center;
position: absolute;
left: 10%;
top: 0;
bottom: 0;
margin: auto;
color: #fff;
font-size: 1.2em;
font-weight: 700;
}
.GainPointsAllCenter {
    position: relative;
    width: 100%;
    height: 100%;
}
.GainPointsAllScroll {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 1.5em;
}
.GainPointsAllBoxTop {
    width: 100%;
}
.GainPointsAllBoxBottom {
    width: 100%;
    height: calc(100% - 10.5em);
    overflow: hidden;
}
.GainPointsAllItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3em;
    border: 1px solid #05a3d1;
    background-color: #08489a;
    border-radius: 1.5em;
    padding-right: 1em;
    margin-bottom: 0.5em;
    font-size: 0.8em;
    color: #fff;
}
.GainPointsAllItemAvatar {
    border: 1px solid #7a7061;
    background-color: #163d66;
}
.GainPointsAllItemLeft {
    display: flex;
    align-items: center;
    height: 100%;
}
.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 2.7em;
    width: 2.7em;
    border-radius: 50%;
    margin-left: 0.1em;
    margin-right: 0.5em;
}
.nameImg {
    height: 2.1em;
    width: 2.1em;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.rankImg {
    height: 1em;
    width: 1em;
    position: absolute;
    left: -0.1em;
    top: -0.1em;
    margin: auto;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.GainPointsAllItemRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.GainPointsAllItemRightLeft {
    display: flex;
    align-items: center;
    justify-content: right;
    flex: 1;
}
.icon {
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #0da3f5;
    margin-right: 0.5em;
}
.iconAvatar {
    background-color: #fdb75c;
}
.GainPointsAllItemRightRight {
    min-width: 3.1em;
    text-align: right;
}
.number {
    font-size: 1.2em;
}
</style>