<template>
    <div class="StudyHeroList">
        <div class="dataScreen_content_bottom_box dataScreen_content_bottom_box3">
            <span class="dataScreen_content_bottom_box_title3">{{record.title}}</span>
            <div class="dataScreen_content_bottom_box3_bottom">
                <div class="dataScreen_content_bottom_box3_bottom_box">
                    <img src="../../assets/img/imgStaly1/list_bg1.png" class="dataScreen_content_bottom_box3_bottom_box_img"
                        alt="">
                    <span class="dataScreen_content_bottom_box3_bottom_box_title">自主学习英雄榜</span>
                    <div class="dataScreen_content_bottom_box3_bottom_box_content">
                        <vue-seamless-scroll v-if="zishuList.length>=1" :data="zishuList" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                              <li v-for="(item, index) in zishuList" class="ul_scoll_li" :key="index">
                                <span class="swiper-slide dataScreen_content_bottom_box3_bottom_box_content_box">
                                    <span
                                        class="dataScreen_content_bottom_box3_bottom_box_content_span">{{item}}</span>
                                </span>
                              </li>
                            </ul>
                          </vue-seamless-scroll>
                    </div>
                </div>
                <div
                    class="dataScreen_content_bottom_box3_bottom_box dataScreen_content_bottom_box3_bottom_box2">
                    <img src="../../assets/img/imgStaly1/list_bg2.png" class="dataScreen_content_bottom_box3_bottom_box_img"  alt="">
                    <span class="dataScreen_content_bottom_box3_bottom_box_title">攻坚克难英雄榜</span>
                    <div class="dataScreen_content_bottom_box3_bottom_box_content">
                        <vue-seamless-scroll v-if="gongjianList.length>=1" :data="gongjianList" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                              <li v-for="(item, index) in gongjianList" class="ul_scoll_li" :key="index">
                                <span class="swiper-slide dataScreen_content_bottom_box3_bottom_box_content_box">
                                    <span
                                        class="dataScreen_content_bottom_box3_bottom_box_content_span">{{item}}</span>
                                </span>
                              </li>
                            </ul>
                          </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {classBoardHeros} from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "StudyHeroList",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                zishuList: [],
                gongjianList: []
            };
        },
        methods: {
            //  优秀表现 
            getExcellentList(heroType) {
                const data = {}
                data.bclassId = this.bclassId
                data.heroType = heroType
                classBoardHeros(data,
                    (resp) => {
                        const resData = resp.data
                        console.log('英雄榜', resp)
                            if (heroType == 1) {
                                this.gongjianList = resData
                            } else if (heroType == 0) {
                                this.zishuList = resData
                            }
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
        },
        mounted() {
            this.getExcellentList(0)
            this.getExcellentList(1)
            console.log(this.bclassId)
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>