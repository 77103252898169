<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="dataScreen_content_3box_bottom">
      <div class="OvercomeDifficulties_Title">
        <TitlesImg :type="record.titleImgType" />
        <span class="OvercomeDifficulties_Span1">{{ record.title }}
          <span class="OvercomeDifficulties_Span2">(考查学生对重难点试题攻坚克难的成绩，同时也考查老师指导学生“歼灭学习重难点”的效果)</span>
        </span>
      </div>
      <span class="dataScreen_content_3box_bottom_span">得分率=学生得到的分值总和/人数/过关升级分值总和</span>
      <div class="dataScreen_content_3box_bottom_navBox">
        <span class="dataScreen_content_3box_bottom_navBox_span"
          :class="subjectId==item?'dataScreen_content_3box_bottom_navBox_span_actved':''"
          v-for="(item, index) in subjectList" :key="index">{{item}}</span>
      </div>
      <div class="dataScreen_content_3box_bottom_content">
        <div class="dataScreen_content_3box_bottom_content_bigBox">
          <div class="dataScreen_content_3box_bottom_content_box" :style="{'width': (100/record.gradeIds.length) - 1 + '%'}" v-for="(item, index) in difficultPointsList"
            :key="index">
            <div class="dataScreen_content_3box_bottom_content_box_left">
              <img src="../../assets/img/imgStaly1/gaofen_grade_bg.png"
                class="dataScreen_content_3box_bottom_content_box_left_img" alt="">
              <span class="dataScreen_content_3box_bottom_content_box_left_span">{{item.name}}</span>
            </div>
            <div class="dataScreen_content_3box_bottom_content_box_right">
              <div class="dataScreen_content_3box_bottom_content_box_right_box" :id="`${record.id}${index}`"
                :ref="`${record.id}${index}`">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  import {
    bigscreenStudyAssessmentV2
  } from '@/api/other'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/pie')
  require('echarts/lib/chart/line')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  export default {
    name: "OvercomeDifficulties",
    components: { TitlesImg },
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    data() {
      return {
        spinning: false,
        subjectList: [],
        subjectId: '',
        xuekeId: '',
        index: 0,
        difficultPointsList: []
      };
    },
    methods: {
      loadData() {
        this.spinning = true
        const that = this
        bigscreenStudyAssessmentV2({
            disciplineIds: this.xuekeId,
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            let perList = []
            for (let i = 0; i < resData[0].items.length; i++) {
              const e = resData[0].items[i];
              const obj = {}
              obj.name = e.gradeName
              obj.list = []
              for (let j = 0; j < e.bclassScoreAccuracys.length; j++) {
                const el = e.bclassScoreAccuracys[j];
                const obj1 = {}
                obj1.name = el.bclassName
                obj1.value = el.avgAccuracy
                obj.list.push(obj1)
              }
              perList.push(obj)
            }
            that.difficultPointsList = perList
            const colors = ['#d444c2', '#ecca3a', '#01aeff']
            that.$nextTick(() => {
              setTimeout(function () {
                for(let i = 0; i < perList.length; i++) {
                  that.drawLine(`${that.record.id}${i}`, perList[i].list, colors[i < 3 ? i : i % 3])
                }
              }, 500)

            })
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      drawLine(div, data, colors) {
        let echartsBox1 = echarts.init(document.getElementById(div))
        const xData = [];
        data.forEach(({
          name,
          value
        }) => {
          xData.push(name)
        })

        const options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: 10,
            right: 10,
            bottom: '3%',
            containLabel: true
          },
          // color: colors,
          xAxis: [{
            type: 'category',
            data: xData,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#0C399D',
              }
            },
            axisLabel: {
              margin: this.record.fontSize,
              textStyle: {
                fontSize: this.record.fontSize,
                color: '#CCECF9',
              }
            },
          }],
          yAxis: [{
            type: 'value',
            min:0,  //取0为最小刻度
            max: 100, //取100为最大刻度
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                fontSize: this.record.fontSize,
                color: '#CCECF9',
              },
              formatter: `{value}%`,
            },
            splitLine: {
              lineStyle: {
                color: '#0C399D'
              }
            },
          }],
          series: [
            {
              type:'bar',
              barWidth: this.record.fontSize/2,
              data:data,
              itemStyle: {
              normal: {
                  color: colors
                  }
              }
            }
          ]
        };
        echartsBox1.setOption(options)
      },
    },
    mounted() {
      this.subjectList = this.record.xuekeNames
      this.subjectId = this.record.xuekeNames[0]
      this.xuekeId = this.record.xuekeIds[0]
      this.loadData()
      if (this.record.xuekeNames.length>1) {
        setInterval(()=>{
          this.index++
          if(this.index >= this.record.xuekeNames.length){
            this.index = 0
          }
          this.subjectId = this.record.xuekeNames[this.index]
          this.xuekeId = this.record.xuekeIds[this.index]
          this.loadData()
        }, 10000)
      }
    }
  };

</script>

<style scoped>
.dataScreen_content_3box_bottom{
    height: 100%;
    width: 100%;
}
.OvercomeDifficulties_Title {
  position: relative;
  width: 100%;
  height: 20%;
  overflow: hidden;
}
.OvercomeDifficulties_Img {
  width: 100%;
}
.OvercomeDifficulties_Span1 {
  display: flex;
  align-items: center;
  position: absolute;
  color: #CCECF9;
  font-size: 1em;
  top: 0;
  bottom: 0;
  left: 5%;
  margin: auto;
}
.OvercomeDifficulties_Span2 {
  font-size: 0.7em;
  color: #CCECF9;
}
</style>
