<template>
  <div>
    <div class="muokuaiTop">
      <span class="topNav" :class="item.id==navId?'navActived':''" v-for="item in navList"
        :key="item.id">{{item.name}}</span>
    </div>
    <div class="dataScreen_content_left_bottom_bottom">
      <div class="dataScreen_content_left_bottom_bottom_title">
        <span class="dataScreen_content_left_bottom_bottom_title_span">
          <span class="dataScreen_content_left_bottom_bottom_title_span2">班级</span>
        </span>
        <span class="dataScreen_content_left_bottom_bottom_title_span2">积分</span>
      </div>
      <div class="dataScreen_content_left_bottom_bottom_content">
        <vue-seamless-scroll v-if="logRecordList.length>=1" :data="logRecordList" @ScrollEnd="ScrollEnd"
          class="seamless-warp" :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in logRecordList" class="ul_scoll_li" :key="index">
              <div class="item dataScreen_content_left_top_box1_bottom_container_box">
                <span class="dataScreen_content_left_top_box1_bottom_container_box_left">
                  <img src="https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/top1.png" class="dataScreen_content_left_top_box1_bottom_container_box_left_img" alt=""
                    v-if="item.Ranking == 1">
                  <img src="https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/top2.png" class="dataScreen_content_left_top_box1_bottom_container_box_left_img" alt=""
                    v-else-if="item.Ranking == 2">
                  <img src="https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/top3.png" class="dataScreen_content_left_top_box1_bottom_container_box_left_img" alt=""
                    v-else-if="item.Ranking == 3">
                  <span class="dataScreen_content_left_top_box1_bottom_container_box_left_span"
                    style="margin-right:10px;" v-else>{{item.Ranking}}
                  </span>
                  <span>{{item.typeText}}-{{item.name}}</span>
                </span>
                <span class="dataScreen_content_left_top_box1_bottom_container_box_span">{{item.score}}</span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    bigscreenLeftV2
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "AssessmentItem",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        navList: [],
        navId: 1,
        num: 0,
        logRecordList: [],
      }
    },
    methods: {
      loadData() {
        const that = this
        bigscreenLeftV2({
          subSchoolId: this.currentSubSchoolId,
          sType: this.navId,
          gradeIds: this.record.gradeIds.join(','),
          useFakeData: this.record.useFakeData
        },
          (resp) => {
            const resData = resp.data
            this.logRecordList = resData
            let Ranking = 1
            for(let i = 0; i < this.logRecordList.length; i++){
              if (i == 0) {
                this.logRecordList[i].Ranking = Ranking
              } else {
                Ranking += 1
                if (this.logRecordList[i].score == this.logRecordList[i-1].score) {
                  this.logRecordList[i].Ranking = this.logRecordList[i-1].Ranking
                } else {
                  this.logRecordList[i].Ranking = Ranking
                }
              }
            }
            if (this.logRecordList.length < (this.record.limitMoveNum ? this.record.limitMoveNum : 6)) {
              setTimeout(function () {
                that.ScrollEnd()
              }, 3000)
            }
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
          }
        )
      },
      ScrollEnd() {
        console.log("ScrollEnd")
        this.num++
        if (this.num < this.record.assessmentIds.length) {
          this.navId = this.record.assessmentIds[this.num]
          this.loadData()
        } else {
          this.num = 0
          this.navId = this.record.assessmentIds[this.num]
          this.loadData()
        }
      }
    },
    mounted() {
      this.navId = this.record.assessmentIds[0]
      for (let i = 0; i < this.record.assessmentIds.length; i++) {
        this.navList.push({
          id: this.record.assessmentIds[i],
          name: this.record.assessmentNames[i]
        })
      }
      this.loadData()
    }
  };

</script>

<style scoped>
  .muokuaiTop {
    display: flex;
    align-items: center;
    border: 1px solid #09A3D9;
    height: 15%;
  }

  .topNav {
    height: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: #CCECF9;
    flex: 1;
  }

  .navActived {
    box-shadow: inset 1px 1px 20px 16px #064e9e;
    border: 1px solid #24FFFF;
  }

  .dataScreen_content_left_top_box1_bottom_container_box {
    justify-content: flex-start;
    padding: 10px 20px;
  }

  .ul_scoll_li:nth-child(2n) {
    background-color: #07369E;
  }

  .dataScreen_content_left_bottom_bottom_title {
    justify-content: flex-start;
    padding: 10px 20px;
    background-color: #07369E;
    color: #53C5F7;
    margin-top: 10px;
  }

  .dataScreen_content_left_top_box1_bottom_container_box_left_span {
    margin: 0;
  }

  .dataScreen_content_left_top_box1_bottom_container_box_left_img {
    margin: 0;
  }
</style>