  <template>
    <div>
      <div class="itemBoxComparison">
        <div class="top" style="width: 100%;height: 100%;">
          <TitlesImg :type="record.titleImgType" />
          <div class="titlePosition" style="margin-left: 2em;">
            <span class="title">{{ record.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
    export default {
      name: "Titles",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      components: { TitlesImg },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      data() {
        return {
          spinning: false
        };
      },
      methods: {
      },
      mounted() {
        console.log(this.record)
      }
    };
  
  </script>
  
  <style scoped>
    .top {
      height: 80%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
  </style>
  