<template>
  <div>
    <div class="wrap">
      <div class="cont">
        <div class="txt" v-for="(item, index) in list" :key="index">{{ item }}；</div>
      </div>
    </div>
  </div>
</template>

<script>
   import {
    padUnlawful
  } from '@/api/other'
  export default {
    name: "Texts",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        list: []
      };
    },
    methods: {
      getPadUnlawful () {
        padUnlawful(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
            this.$nextTick(() => {
              let containerWidth = document.querySelector('.wrap').offsetWidth 
              let boxWidth = document.querySelector('.wrap .cont').offsetWidth 
              let duration = (boxWidth * 2 + containerWidth) / 150 + 's'
              document.querySelector('.wrap .cont').style.cssText = 'animation-duration:' + duration
            })
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
          }
        )
      }
    },
    mounted() {
      this.getPadUnlawful()
    }
  };

</script>

<style scoped>
  .txt {
    white-space:nowrap;
  }
  .wrap {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.cont {
  position: absolute;
  display: flex;
  color: #fff;
  white-space: nowrap;
  animation: marquee 5s linear infinite;
}

/* 如果是 PC 端，可能还需要一个鼠标移入时动画暂停的功能 */
/* .marquee-container:hover .marquee-box {
  animation-play-state: paused;
} */

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
</style>