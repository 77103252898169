<template>
    <a-spin :spinning="spinning" class="spinning_box">
      <div class="CampusCulture">
<div class="CampusCulture_content">
  <vue-seamless-scroll v-if="dataList.length>=1" :data="dataList"  class="seamless-warp" :class-option="defaultOption">
    <ul class="ul-scoll">
      <li v-for="(item, index) in dataList" class="AssessmentWeeksItemLi" :key="index">
        <div class="CampusCulture_content_box">
          <span class="CampusCulture_content_box_span" v-html="item.title"></span>
          <span class="CampusCulture_content_box_span2" v-html="item.info"></span>
      </div>
      </li>
    </ul>
  </vue-seamless-scroll>
</div>
      </div>
    </a-spin>
  </template>
  
  <script>
    import { subSchoolMotto } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
      name: "CampusPopulationOverview",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length            
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
      data() {
        return {
          spinning: false,
          dataList: []
        };
      },
      methods: {
        loadData() {
        this.spinning = true
        const that = this
        subSchoolMotto({
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData ?this.record.useFakeData:0
          },
          (resp) => {
            const resData = resp.data
            console.log(resData)
            this.dataList = resData
          //  that.teacherCount = resData.teacherCount
          //  that.stuCount = resData.stuCount
          //  that.employeeCount = resData.employeeCount
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
    
      },
      mounted() {
          console.log(this.record)
          this.loadData()
      }
    };
  
  </script>
  
  <style scoped>
 .CampusCulture{

 }
 .CampusCulture_content{

 }
 .CampusCulture_content_box{
background-image: url('../../assets/img/imgStaly1/culture_bg.png');
background-position: center;
background-size: 100% 100%;
background-repeat: no-repeat;
padding: 15px 20px;
color: #ccffff;
margin-bottom: 2%;
 }
 .CampusCulture_content_box_span{
     display: block;
     padding-left: 15px;
     font-size: 0.5em;
 }
 .CampusCulture_content_box_span2{
    display: block;
    margin-top: 5px;
    font-size: 0.5em;
 }
  </style>
  