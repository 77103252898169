<template>
    <div class="ClassCulture">
        <div class="dataScreen_content_bottom_box dataScreen_content_bottom_box4">
            <span class="dataScreen_content_bottom_box_title dataScreen_content_bottom_box_title2">{{record.title}}</span>
            <div class="dataScreen_content_top_left_bottom">
                <div class="dataScreen_content_top_left_bottom_box">
                    <span class="dataScreen_content_top_left_bottom_box_left"> <span>目标</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.classGoal}}</span>
                </div>
                <div class="dataScreen_content_top_left_bottom_box">
                    <span class="dataScreen_content_top_left_bottom_box_left"><span>口号</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.slogan}}</span>
                </div>
                <div class="dataScreen_content_top_left_bottom_box">
                    <span class="dataScreen_content_top_left_bottom_box_left"><span>学风</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.bclassStudyStyle}}</span>
                </div>
                <div class="dataScreen_content_top_left_bottom_box">
                    <span
                        class="dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"><span>班训</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.classMotto}}</span>
                </div>
                <div class="dataScreen_content_top_left_bottom_box">
                    <span
                        class="dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"><span>班风</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.bclassAtmosphere}}</span>
                </div>
                <div class="dataScreen_content_top_left_bottom_box">
                    <span
                        class="dataScreen_content_top_left_bottom_box_left bgc_FA7F2C"><span>誓词</span></span>
                    <span
                        class="dataScreen_content_top_left_bottom_box_right">{{bclassInfo.bclass.classPledge}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBulletinBoardContent} from '@/api/other'
    // import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "ClassCulture",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            // vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                bclassInfo: {
                    bclass: {
                        classGoal: ''
                    }
                }
            };
        },
        methods: {
            //  优秀表现 
            getGoodKeshi() {
                const data = {}
                data.id = this.bclassId
                getBulletinBoardContent(data,
                    (resp) => {
                        const data = resp.data
                        this.bclassInfo = data
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            },
        },
        mounted() {
            this.getGoodKeshi()
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>