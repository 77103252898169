<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="dataScreen_content_2box_bottom_top">
      <div class="dataScreen_content_2box_bottom_top_box">
        <span class="dataScreen_content_2box_bottom_top_span">学科电子学习资料上传比率（比率 = 学科学习资料实传单元个数 / 学期应传单元个数）</span>
        <div class="dataScreen_title2_box">
          <span class="dataScreen_title2_box_span" :class="gradeId==item.id?'dataScreen_title2_box_span_actived':''"
            v-for="(item, index) in gradeList" :key="index">{{item.name}}</span>
        </div>
      </div>
      <div class="dataScreen_content_2box_bottom_top_content">
        <div class="dataScreen_content_2box_bottom_top_content_box" v-for="(item,index) in pieList" :key="index">
          <div class="dataScreen_content_2box_bottom_top_content_box_echarts" :id="'pieBox'+index"
            :ref="`chart_${index}`"></div>
          <span class="dataScreen_content_2box_bottom_top_content_box_span">{{item.discriplineName}}
            <span class="dataScreen_content_2box_bottom_top_content_box_bottom_span">已传：</span>
          </span>
          <div class="dataScreen_content_2box_bottom_top_content_box_bottom">
            <span class="dataScreen_content_2box_bottom_top_content_box_bottom_span">
              <span style="font-size: 1em;">{{item.uploaded}}</span>
              <span style="font-size: 0.8em;">/ {{item.needUpload}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import {
    bigscreenTeacherMateriaPies
  } from '@/api/other'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/pie')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  export default {
    name: "MaterialUpload",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {},
    data() {
      return {
        spinning: false,
        pieList: [],
        gradeList: [],
        gradeId: undefined,
        index: 0,
        chartArr: []
      };
    },
    methods: {
      getPies() {
        const that = this
        this.spinning = true
        bigscreenTeacherMateriaPies(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeId: this.gradeId,
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            console.log(resp)
            const resData = resp.data
            let peiDataList = []
            for (let i = 0; i < resData.length; i++) {
              const e = resData[i];
              e.unUploaded = e.needUpload - e.uploaded
              const obj = [{
                value: e.uploaded,
                name: '',
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: '#00FA90' // 0% 处的颜色
                      }, {
                        offset: 1,
                        color: '#09E0D3' // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                }
              }, {
                name: '',
                value: e.unUploaded,
                itemStyle: {
                  normal: {
                    color: '#2546C3',
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                }
              }]
              const bigObj = {}
              bigObj.list = obj
              bigObj.text = e.per + '%'
              bigObj.title = e.discriplineName
              peiDataList.push(bigObj)
            }
            console.log(peiDataList)
            that.pieList = resData
            that.$nextTick(() => {
              setTimeout(function () {
                that.drawPie(peiDataList)
              }, 500)

            })

            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      drawPie(dataList) {
        dataList.forEach((item, index) => {
          const chart = echarts.init(this.$refs[`chart_${index}`][0])
          // 将实例保存到chartArr中，用于联动，如果不需要联动，这里就不需要了
          this.chartArr.push(chart)
          chart.setOption({
            title: {
              text: item.text,
              x: 'center',
              y: 'center',
              textStyle: {
                color: '#FFFEFE',
                fontSize: this.record.fontSize
              }
            },
            series: [{
              name: 'pie',
              type: 'pie',
              radius: ['50%', '80%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                position: 'inside'
              },
              data: item.list
            }]
          })
        })
        // 图表实例实现联动
        echarts.connect(this.chartArr)
      }
    },
    mounted() {
      for(let i = 0; i < this.record.gradeIds.length; i++) {
        this.gradeList.push({
          id: this.record.gradeIds[i],
          name: this.record.gradeNames[i],
        })
      }
      this.gradeId = this.record.gradeIds[0]
      this.getPies()
      if (this.record.gradeIds.length>1) {
        setInterval(()=>{
          this.index++
          if(this.index >= this.record.gradeIds.length){
            this.index = 0
          }
          this.gradeId = this.record.gradeIds[this.index]
          this.getPies()
        }, 4000)
      }
    }
  };

</script>

<style scoped>

</style>
