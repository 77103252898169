<template>
    <div class="DemocraticAutonomy">
        <div class="dataScreen_content_bottom_box8">
            <div class="dataScreen_content_bottom_box8_title">
                <span class="dataScreen_content_top_right_span">{{record.title}}</span>
            </div>
            <div class="dataScreen_content_bottom_box8_midden">
                <img src="../../assets/img/imgStaly1/rice_icon.png" class="dataScreen_content_bottom_box8_midden_img" alt="">
                <span class="dataScreen_content_bottom_box8_midden_span">综合表现：第 <span class="paiming">{{allNum}}</span>名</span>
            </div>
            <div class="dataScreen_content_bottom_box8_content">
                <div class="dataScreen_content_bottom_box2_content_box" v-for="(item,index) in kaoheList" :key="index">
                    <div class="dataScreen_content_bottom_box2_content_box_left">
                        <img :src="item.img" alt="" class="dataScreen_content_bottom_box2_content_box_img">
                        <span class="dataScreen_content_bottom_box2_content_box_right_span">{{item.text}}</span>
                    </div>
                    <div class="dataScreen_content_bottom_box2_content_box_right">
                        <span class="dataScreen_content_bottom_box2_content_box_right_span1">在全校排第 <span
                                class="paiming">{{item.num}}</span>名</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getBulletinBoardContent, classBoardExcellentList
    } from '@/api/other'
    export default {
        name: "DemocraticAutonomy",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        data() {
            return {
                kaoheList: [
                    {
                        id: 1,
                        text: '课堂评价',
                        img:'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859910934.png',
                        num: '-'
                    },
                    {
                        id: 2,
                        text: '日常习惯',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859868044.png',
                        num: '-'
                    },
                    {
                        id: 3,
                        text: '卫生表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859987638.png',
                        num: '-'
                    },
                    {
                        id: 4,
                        text: '宿舍表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859944339.png',
                        num: '-'
                    },
                    {
                        id: 5,
                        text: '三餐表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860025621.png',
                        num: '-'
                    },
                    {
                        id: 6,
                        text: '三操表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860062086.png',
                        num: '-'
                    },
                    {
                        id: 7,
                        text: '综合表现',
                        img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860113258.png',
                        num: '-'
                    }
                ],
                allNum: '-'
            };
        },
        methods: {
            getGoodKeshi() {
                const data = {}
                data.id = this.bclassId
                getBulletinBoardContent(data,
                    (resp) => {
                        const data = resp.data
                        this.kaoheList = [{
                            id: 1,
                            text: '课堂评价',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859910934.png',
                            num: data.rankList[0]
                        },
                        {
                            id: 2,
                            text: '日常习惯',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859868044.png',
                            num: data.rankList[1]
                        },
                        {
                            id: 3,
                            text: '卫生表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859987638.png',
                            num: data.rankList[2]
                        },
                        {
                            id: 4,
                            text: '宿舍表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706859944339.png',
                            num: data.rankList[3]
                        },
                        {
                            id: 5,
                            text: '三餐表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860025621.png',
                            num: data.rankList[4]
                        },
                        {
                            id: 6,
                            text: '三操表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860062086.png',
                            num: data.rankList[5]
                        },
                        {
                            id: 7,
                            text: '综合表现',
                            img: 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1706860113258.png',
                            num: data.rankList[7]
                        }
                        ]
                        // that.zonghepaiming = data.rankList[6]
                        // this.bclassId = data.bclass.id
                        this.allNum = data.rankList[7]
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            }
        },
        mounted() {
            this.getGoodKeshi()
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
</style>