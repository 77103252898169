<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="GainPointsClass">
            <div class="GainPointsClassTop">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_statitle_bg2.png" alt="">
                <div class="GainPointsClassTitle">
                    {{ record.title }}
                </div>
                <div class="GainPointsClassTab">
                    <div v-for="(item, index) in timeTypeList" :key="index"
                        :class="item.id === timeType ? 'GainPointsClassActive' : ''">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="GainPointsClassCenter">
                <div class="GainPointsClassEcharts" :id="record.id">
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiOutSideLearningRightLine
    } from '@/api/other'
    const echarts = require('echarts/lib/echarts')
    import $ from 'jquery'
    require('echarts/lib/chart/bar')
    require('echarts/lib/component/tooltip')
    require('echarts/lib/component/legend')
    require('echarts/lib/component/title')
    let timeout
    export default {
        name: "GainPointsClass",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
        },
        watch: {
        },
        computed: {
        },
        data() {
            return {
                dataInfo: [],
                spinning: false,
                timeTypeList: [
                    {
                        name: '本日',
                        id: 0
                    },
                    {
                        name: '昨日',
                        id: 1
                    }
                ],
                timeType: 0,
                maxNum: 6
            }
        },
        methods: {
            loadData() {
                const that = this
                bigScreenZixishiOutSideLearningRightLine(
                    {
                        subSchoolId: this.currentSubSchoolId,
                        timeType: this.timeType,
                        useFakeData: this.record.useFakeData,
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                        that.pie()
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            pie() {
                const that = this
                const xData = []
                const yData = []
                for (let i = 0; i < this.dataInfo.length; i++) {
                    xData.push(this.dataInfo[i].name)
                    yData.push(this.dataInfo[i].count)
                }
                let echarts1 = echarts.init($(`#${this.record.id}`)[0])
                let option = {
                    grid: {
                        left: '5%',
                        right: '5%',
                        top: '5%',
                        bottom: '15%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#7ebaf2',
                            },
                        },
                        axisTick: {
                            show: false,
                            length: 9,
                            alignWithLabel: true,
                            lineStyle: {
                                color: '#7DFFFD',
                            },
                        },
                        axisLabel: {
                            fontSize: this.record.fontSize * 0.7,
                            interval: 0
                        },
                    },
                    dataZoom: [
                        //滑动条
                        {
                            xAxisIndex: 0, //这里是从X轴的0刻度开始
                            show: false, //是否显示滑动条，不影响使用
                            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                            startValue: -1, // 从头开始。
                            endValue: that.maxNum, // 一次性展示6个
                            realtime: false, //是否实时更新
                            filterMode: "filter"
                        }
                    ],
                    yAxis: {
                        type: 'value',
                        min: 0,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#7ebaf2',
                            },
                        },
                        splitLine: {
                            show: false,
                        },

                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            fontSize: this.record.fontSize * 0.7,
                        },
                        boundaryGap: ['20%', '20%'],
                    },
                    series: [
                        {
                            type: 'bar',
                            data: yData,
                            barWidth: this.record.fontSize,
                            label: {
                                show: true,
                                textStyle: {
                                    color: "#9fbbea"
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 1, 0, 0, [{//只要修改前四个参数就ok
                                            offset: 0,
                                            color: '#0454cd'
                                        }, //柱图渐变色
                                        {
                                            offset: 1,
                                            color: '#009fe3'
                                        }
                                    ]
                                    ),
                                }
                            }
                        }
                    ]
                }
                if (xData.length > that.maxNum) {
                    echarts1.setOption(option)
                    timeout = setInterval(() => {
                        // 每次向后滚动一个，切换。
                        console.log(option.dataZoom[0])
                        if (option.dataZoom[0].endValue == xData.length) {
                            if (that.timeType === 0) {
                                that.timeType = 1
                            } else {
                                that.timeType = 0
                            }
                            that.loadData()
                            clearTimeout(timeout)
                        } else {
                            option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
                            option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
                        }
                        echarts1.setOption(option);
                    }, 2000);
                } else {
                    echarts1.setOption(option)
                    setTimeout(() => {
                        if (that.timeType === 0) {
                            that.timeType = 1
                        } else {
                            that.timeType = 0
                        }
                        that.loadData()
                    },10000)
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
    .GainPointsClass {
        width: 100%;
        height: 90%;
    }

    .GainPointsClassTop {
        position: relative;
        width: 100%;
        height: 10%;
    }

    .GainPointsClassTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 5%;
        top: 0;
        bottom: 0;
        margin: auto;
        color: #fff;
        font-size: 1.2em;
        font-weight: 700;
    }

    .GainPointsClassTab {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5%;
        top: 0;
        bottom: 0;
        margin: auto;
        color: #fff;
        font-size: 1em;
    }

    .GainPointsClassTab>div {
        padding: 0 1em;
        color: #6bd6f7;
    }

    .GainPointsClassActive {
        background-image: url('../../assets/img/imgStaly1/study_statitle_toggle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #c2f2ff !important;
    }

    .GainPointsClassCenter {
        position: relative;
        width: 100%;
        height: 90%;
    }

    .bgImg {
        width: 100%;
        height: 100%;
    }

    .GainPointsClassEcharts {
        width: 100%;
        height: 100%;
    }
</style>