<template>
  <div class="itemBoxComparison">
    <img v-for="(item, index) in weather.weatherImg" :key="index" class="weatherImg" :src="item" alt="">
    <span class="weatherSpan">{{ weather.area }}</span>
    <span class="weatherSpan">{{ weather.temperature }}</span>
    <span class="weatherSpan">{{ weather.weather }}</span>
  </div>
</template>

<script>
  import { bigscreenWarningReport } from '@/api/other'
  import { axios } from "@/utils/request";
  export default {
    name: "Footer",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        weather: {
          area: "",
          temperature: "",
          weather: "",
          weatherImg: "",
        },
        ip: "",
      };
    },
    methods: {
      async getIpinfo() {
        const that = this
        try {
          const response = await axios.get("https://ipinfo.io/json");
          this.ip = response.ip;
          that.getBigscreenWarningReport();
          setInterval(function () {
            that.getBigscreenWarningReport();
          }, 600000);
        } catch (error) {
          console.error(error);
        }
      },
      getBigscreenWarningReport() {
        bigscreenWarningReport(
          {
            ip: this.ip,
          },
          (resp) => {
            this.weather = resp.data;
          },
          (err) => {
            console.log("err", err);
          }
        );
      },
    },
    mounted() {
      this.getIpinfo()
    },
  };

</script>

<style scoped>
  .weatherImg {
    width: 3em;
  }

  .weatherSpan {
    font-size: 1em;
    color: #fff;
  }
</style>