<template>
  <div class="Unlawful2" :style="{'width': record.width + 'px','height': record.height + 'px'}">
    <img class="bgImg" src="https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1703055309564.png" alt="">
    <span class="Unlawful2_title">{{ record.title }}</span>
    <div class="Unlawful2_content">
      <vue-seamless-scroll v-if="list.length>=1" :data="list" @ScrollEnd="ScrollEnd" class="seamless-warp" :class-option="defaultOption">
        <ul class="ul-scoll">
          <li v-for="(item, index) in list" class="ul_scoll_li" :key="index">
            <div class="Unlawful2_content_box">
              <div class="Unlawful2_content_box_left">
                <span>{{item.bclassName}}</span>
              </div>
              <div class="Unlawful2_content_box_right">
                <span class="Unlawful2_content_box_right_span" v-for="(items, indexs) in item.stuNames" :key="indexs">{{items}}</span>
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
   import { padgroupByClassUnlawful } from '@/api/other'
  // import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "Unlawful2",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    data() {
      return {
        list: [ ]
      };
    },
    methods: {
      getPadUnlawful () {
        padgroupByClassUnlawful(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
          }
        )
      },
      ScrollEnd() {
          console.log("ScrollEnd")
        }
    },
    mounted() {
      console.log(this.record)
      this.getPadUnlawful()
    }
  };

</script>

<style scoped>
.Unlawful2{
  width: 100%;
  height: 100%;
}
.bgImg {
  width: 100%;
  height: 100%;
}
.Unlawful2_title{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14%;
  width: 100%;
  font-size: 1em;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.Unlawful2_content{
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  margin: auto;
  width: 100%;
  height: 84%;
  padding: 1.5em;
  overflow: hidden;
}
.Unlawful2_content_box{
  width: 100%;
  background-color: #123cbb;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.Unlawful2_content_box_left{
  color: #ddfafc;
  padding: 0 0 0 10px;
  font-size: 0.9em;
  margin-right: 0.5em;
}
.Unlawful2_content_box_right{
  border:1px solid #0fcdf2;
  flex: 1;
  padding: 10px 10px 3px;
}
.Unlawful2_content_box_right_span{
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  color: #ddfafc;
  font-size: 0.8em;
}
</style>