<template>
    <a-spin :spinning="spinning" class="spinning_box">
<div class="EvaluationSummary">
    <vue-seamless-scroll v-if="dataList.length>=1" :data="dataList" @ScrollEnd="ScrollEnd" class="seamless-warp" :class-option="defaultOption">
        <ul class="ul-scoll">
          <li v-for="(item, index) in dataList" :key="index" class="ul_scoll_li">
    <div class="EvaluationSummary_box" >
        <div class="EvaluationSummary_box_top">
            <span>{{item.teacherName}}</span>
        </div>
        <div class="EvaluationSummary_box_bottom">
            <div class="EvaluationSummary_box_bottom_box">
                <span class="EvaluationSummary_box_bottom_box_span">课堂日志</span>
                <span class="EvaluationSummary_box_bottom_box_span2" style="color: #33ffff;">{{item.ketangrizhiCount}}</span>
            </div>
            <div class="EvaluationSummary_box_bottom_box">
                <span class="EvaluationSummary_box_bottom_box_span">班主任评价</span>
                <span class="EvaluationSummary_box_bottom_box_span2" style="color: #33ffff;">{{item.banzhurenCount}}</span>
            </div>
            <div class="EvaluationSummary_box_bottom_box">
                <span class="EvaluationSummary_box_bottom_box_span">评价合计</span>
                <span class="EvaluationSummary_box_bottom_box_span2" style="color: #ff9933;">{{item.sum}}</span>
            </div>
        </div>
    </div>
</li>
</ul>
</vue-seamless-scroll>
</div>
    </a-spin>
  </template>
  
  <script>
    import {
        assessmentUploadCount
    } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
      name: "EvaluationSummary",
      props:{
          record: {
            type: Object
          },
          currentSubSchoolId: {
            type: String
          },
        },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      components: {
        vueSeamlessScroll
      },
      // 监听属性 类似于data概念      
      computed: {
        defaultOption() {
          return {
            step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
            limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
            hoverStop: false, // 是否开启鼠标悬停stop          
            direction: 1, // 0向下 1向上 2向左 3向右      
            openWatch: true, // 开启数据实时监控刷新dom                
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
            waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
          }
        }
      },
      data() {
        return {
          spinning: false,
          dataList: []
        };
      },
      methods: {
        loadData(sType) {
          this.spinning = true
          assessmentUploadCount({
              subSchoolId: this.currentSubSchoolId,
              useFakeData: this.record.useFakeData?this.record.useFakeData:0
            },
            (resp) => {
              const resData = resp.data
              this.dataList = resData
              this.spinning = false
            },
            (err) => {
              console.log('err', err)
              this.$message.error(err)
              this.spinning = false
            }
          )
        },
        ScrollEnd() {
          console.log("ScrollEnd")
        }
      },
      mounted() {
          this.loadData(0)
      }
    };
  
  </script>
  
  <style scoped>
.EvaluationSummary{

}
.EvaluationSummary_box{
    background-image: url('../../assets/img/imgStaly1/kaohe_img.png');
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 20px 0 20px 15%;
}
.EvaluationSummary_box_top{
font-size: 1em;
color: #fff;
margin-top: 10px;
}
.EvaluationSummary_box_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.EvaluationSummary_box_bottom_box{
display: flex;
align-items: center;
justify-content: flex-start;
}
.EvaluationSummary_box_bottom_box_span{
color: #ccffff;
font-size: 0.9em;
}
.EvaluationSummary_box_bottom_box_span2{
display: inline-block;
margin-left: 7px;
font-size: 1.5em;
font-weight: 700;
}
  </style>
  