<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="NumberExercisesTendency">
            <div class="NumberExercisesTendencyTop">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_statitle_bg2.png" alt="">
                <div class="NumberExercisesTendencyTitle">
                    {{ record.title }}
                </div>
                <div class="NumberExercisesTendencyTab">
                    <div
                        v-for="(item, index) in timeTypeList"
                        :key="index"
                        :class="item.id === timeType ? 'NumberExercisesTendencyActive' : ''"
                    >
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="NumberExercisesTendencyCenter">
                <div  class="NumberExercisesTendencyEcharts" :id="record.id">
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiOutSideLearningCountLine
    } from '@/api/other'
    const echarts = require('echarts/lib/echarts')
    import $ from 'jquery'
    require("echarts/lib/chart/line")
    export default {
        name: "NumberExercisesTendency",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            }
        },
        components: {
        },
        watch: {
        },  
        computed: {
        },
        data() {
            return {
                dataInfo: [],
                spinning: false,
                timeTypeList: [
                    {
                        name: '本周',
                        id: 0
                    },
                    {
                        name: '本月',
                        id: 1
                    }
                ],
                timeType: 0
            }
        },
        methods: {
            loadData() {
                bigScreenZixishiOutSideLearningCountLine(
                    {
                        subSchoolId: this.currentSubSchoolId,
                        timeType: this.timeType,
                        useFakeData: this.record.useFakeData,
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                        this.line()
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            line() {
                const xData = []
                const yData = []
                for (let i = 0; i < this.dataInfo.length; i++) {
                    xData.push(this.dataInfo[i].day)
                    yData.push(this.dataInfo[i].num)
                }
                let echarts1 = echarts.init($(`#${this.record.id}`)[0])
                let option = {
                    xAxis: {
                        type: 'category',
                        data: xData,
                        boundaryGap: false,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#c2f2ff",
                            },
                            lineStyle:{
                                color:'#22477b',
                            },
                            fontSize: this.record.fontSize * 0.7
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#22477b'],
                                width: 1
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#c2f2ff",
                            },
                            lineStyle:{
                                color:'#22477b',
                            },
                            fontSize: this.record.fontSize * 0.7
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#22477b'],
                                width: 1
                            }
                        }
                    },
                    series: [
                        {
                            data: yData,
                            type: 'line',
                            itemStyle: {
                                normal: {
                                    color: '#54f2fc', //改变折线点的颜色
                                    lineStyle: {
                                        color: '#70b2e6' //改变折线颜色
                                    }
                                }
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'rgba(24, 80, 151, 1)' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: 'rgba(24, 80, 151, 0.3)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            }
                        }
                    ]
                }
                echarts1.setOption(option)
            },
            classRoom() {
                const that = this
                this.loadData()
                setInterval(function(){
                    if (that.timeType === 0) {
                        that.timeType = 1
                    } else {
                        that.timeType = 0
                    }
                    that.loadData()
                }, 10000)
            }
        },
        mounted() {
            this.classRoom()
        }
    }
</script>

<style scoped>
.NumberExercisesTendency {
    width: 100%;
    height: 90%;
}
.NumberExercisesTendencyTop {
    position: relative;
    width: 100%;
    height: 10%;
  }
.NumberExercisesTendencyTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5%;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
}
.NumberExercisesTendencyTab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5%;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 1em;
}
.NumberExercisesTendencyTab>div {
    padding: 0 1em;
    color: #6bd6f7;
}
.NumberExercisesTendencyActive {
    background-image: url('../../assets/img/imgStaly1/study_statitle_toggle.png');
    background-repeat: no-repeat;
	background-size: 100% 100%;
    color: #c2f2ff !important;
}
.NumberExercisesTendencyCenter {
    position: relative;
    width: 100%;
    height: 90%;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.NumberExercisesTendencyEcharts {
    width: 100%;
    height: 100%;
}
</style>