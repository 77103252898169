<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="ProjectLearningProgress">
      <div class="ProjectLearningProgress_top">
        <span
          v-for="(item, index) in xuekeList"
          :key="index"
          class="ProjectLearningProgress_top_span"
          :class="
            xuekeId == item.id ? 'ProjectLearningProgress_top_span_actived' : ''
          "
          >{{ item.name }}</span
        >
      </div>
      <div class="ProjectLearningProgress_content">
        <vue-seamless-scroll
          v-if="dataList.length >= 1"
          :data="dataList"
          @ScrollEnd="ScrollEnd"
          class="seamless-warp ProjectLearningProgress_content_li"
          :class-option="defaultOption"
        >
          <ul class="ul-scoll">
            <li
              v-for="(item, index) in dataList"
              :key="index"
              class="ul_scoll_li"
            >
              <div class="ProjectLearningProgress_content_box">
                <div class="ProjectLearningProgress_content_box_title">
                  <span>{{ item.examPaperName }}</span>
                </div>
                <div class="ProjectLearningProgress_content_box_bottom">
                  <div
                    class="ProjectLearningProgress_content_box_bottom_left"
                    :id="item.examPaperId + 'left'"
                    :class="item.examPaperId + 'left'"
                  >
                  </div>
                  <div class="ProjectLearningProgress_content_box_bottom_right">
                    <div
                      class="ProjectLearningProgress_content_box_bottom_right_title"
                    >
                      <span
                        class="ProjectLearningProgress_content_box_bottom_right_title_span"
                      ></span>
                      <span
                        class="ProjectLearningProgress_content_box_bottom_right_title_span1"
                        >各班级得分率</span
                      >
                    </div>
                    <div
                      class="ProjectLearningProgress_content_box_bottom_right_bottom"
                      :id="item.examPaperId + 'right'"
                      :class="item.examPaperId + 'right'"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { batchStudy } from "@/api/other"
import vueSeamlessScroll from "vue-seamless-scroll"
const echarts = require('echarts/lib/echarts')
require("echarts/lib/chart/bar")
require("echarts/lib/chart/gauge")
require("echarts/lib/component/tooltip")
require("echarts/lib/component/legend")
require("echarts/lib/component/title")
require("echarts/lib/component/polar")
require("echarts/lib/component/grid")
export default {
  name: "ProjectLearningProgress",
  props: {
    record: {
      type: Object,
    },
    currentSubSchoolId: {
      type: String,
    },
  },
  watch: {
    currentSubSchoolId: function (e, n) {
      console.log(e, n);
    },
  },
  components: {
    vueSeamlessScroll,
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption() {
      return {
        step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
        limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length   
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      spinning: false,
      dataList: [],
      maxNum: 6,
      xuekeList: [],
      xuekeId: "",
      xuekeIndex: 0,
    };
  },
  methods: {
    loadData(sType) {
      this.spinning = true;
      // [16,17,18,19,20,21]
      const that = this;
      this.dataList = []
      batchStudy(
        {
          disciplineId: this.xuekeId,
          subSchoolId: this.currentSubSchoolId,
          gradeIds: this.record.gradeIds.join(","),
          useFakeData: this.record.useFakeData ? this.record.useFakeData : 0,
        },
        (resp) => {
          const resData = resp.data
          this.dataList = resData
          this.spinning = false
          that.$nextTick(() => {
            for (let i = 0; i < resData.length; i++) {
              const e = resData[i]
              const nameList = []
              const scorePerList = []
              for (let j = 0; j < e.items.length; j++) {
                const el = e.items[j];
                nameList.push(el.className)
                scorePerList.push(el.scorePer)
              }
              setTimeout(function (params) {
                that.drawSemicircle(e.examPaperId + "left", e.stuScore)
                that.drawHistogram(
                  e.examPaperId + "right",
                  nameList,
                  scorePerList
                );
              },500)
            }
          });
        },
        (err) => {
          console.log("err", err)
          this.$message.error(err)
          this.spinning = false
        }
      );
    },
    drawSemicircle(div, data) {
      // let echartsBox1 = echarts.init(document.getElementById(div));
      console.log(data)
      const that = this
      let echartsBox1 = echarts.init(document.getElementsByClassName(div)[0])
      let echartsBox2 = echarts.init(document.getElementsByClassName(div)[1])
      const value = data
      const options = {
        title: [
          {
            text: "得分率",
            y: "70%",
            x: "center",
            textStyle: {
              fontWeight: "bold",
              fontSize: that.record.fontSize * 0.7,
              color: "#99cccc"
            }
          }
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false
          }
        },
        barMaxWidth: 20, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category"
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "70%"],
          radius: "250%"
        },
      /*   grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }, */
        series: [
          {
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: value,
                itemStyle: {
                 /*  color: new echarts.graphic.LinearGradient(
                    1,
                    0,
                    0,
                    0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                    [
                      { offset: 1, color: "#0099ff" },
                      { offset: 0, color: "#33ccff " },
                    ]
                  ), */
                  color: "#0099ff"
                },
              },
            ],
            barGap: "-100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角
            z: 3, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: "#003366",
                  borderWidth: 0
                }
              }
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1
          },
          //仪表盘
          {
            type: "gauge",
            startAngle: 225, //起始角度，同极坐标
            endAngle: -45, //终止角度，同极坐标
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitLabel: {
              show: false
            },
            pointer: {
              // 分隔线
              show: false, //是否显示指针
              shadowColor: "auto", //默认透明
              shadowBlur: 5,
              length: "50%",
              width: "2"
            },
            itemStyle: {
              color: "#1598FF",
              borderColor: "#1598FF",
              borderWidth: 3
            },
            detail: {
              formatter: function (params) {
                return value + "%"
              },
              color: "#33ffff",
              fontSize: that.record.fontSize,
              offsetCenter: [0, 0]
            },
            title: {
              show: false,
            },
            data: [
              {
                value: value,
              },
            ],
          },
        ],
      };
      echartsBox1.setOption(options)
      echartsBox2.setOption(options)
    },
    drawHistogram(div, nameList, scorePerList) {
      const that = this;
      let echartsBox1 = echarts.init(document.getElementsByClassName(div)[0])
      let echartsBox2 = echarts.init(document.getElementsByClassName(div)[1])
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "20%",
          top: "15%"
        },
        xAxis: {
          type: "category",
          data: nameList,
          axisLine: {
            lineStyle: {
              color: "white"
            }
          },
          axisLabel: {
            // interval: 0,
            // rotate: 40,
            textStyle: {
              fontFamily: "Microsoft YaHei",
              fontSize: that.record.fontSize * 0.7
            }
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              color: "white"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.3)"
            }
          },
          axisLabel: {
            textStyle: {
              fontFamily: "Microsoft YaHei",
              fontSize: that.record.fontSize * 0.7
            }
          }
        },
        dataZoom: [
          //滑动条
          {
            xAxisIndex: 0, //这里是从X轴的0刻度开始
            show: false, //是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: -1, // 从头开始。
            endValue: that.maxNum, // 一次性展示6个
            realtime: false, //是否实时更新
            filterMode: "filter"
          }
        ],
        series: [
          {
            name: "得分率",
            type: "bar",
            // barWidth: "10%",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#0033cc"
                  },
                  {
                    offset: 1,
                    color: "#0099ff"
                  }
                ]),
                barBorderRadius: [12, 12, 0, 0],
                label: {
                  formatter: "{c}" + "%",
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: that.record.fontSize *0.7,
                    color: "#fff"
                  }
                }
              },
            },
            data: scorePerList,
          }
        ],
      };
      if (nameList.length > that.maxNum) {
        setInterval(function () {
          // 每次向后滚动一个，最后一个从头开始。
          console.log(options.dataZoom[0])
          if (options.dataZoom[0].endValue == nameList.length) {
            options.dataZoom[0].endValue = that.maxNum
            options.dataZoom[0].startValue = 0
          } else {
            options.dataZoom[0].endValue = options.dataZoom[0].endValue + 1
            options.dataZoom[0].startValue = options.dataZoom[0].startValue + 1
          }
          echartsBox1.setOption(options)
          echartsBox2.setOption(options)
        }, 2000);
      } else {
        echartsBox1.setOption(options)
        echartsBox2.setOption(options)
      }
      //   echartsBox1.setOption(options);
    },

    ScrollEnd() {
      console.log("ScrollEnd");
      this.xuekeIndex = this.xuekeIndex + 1;
      for (let i = 0; i < this.xuekeList.length; i++) {
        const e = this.xuekeList[i];
        if (i === this.xuekeIndex) {
          this.xuekeId = e.id;
          this.loadData();
        }
      }
    },
  },
  mounted() {
    this.xuekeList = [];
    for (let i = 0; i < this.record.xuekeNames.length; i++) {
      const e = this.record.xuekeNames[i];
      const obj = {};
      if (i === 0) {
        this.xuekeId = this.record.xuekeIds[i];
        this.xuekeIndex = i;
        this.loadData();
      }
      obj.id = this.record.xuekeIds[i];
      obj.name = e;
      this.xuekeList.push(obj);
    }
  },
};
</script>

<style scoped>
.ul_scoll_li {
  width: 100%;
  height: 48%;
}

.ul-scoll {
  width: 100%;
  height: 100%;
}

.ProjectLearningProgress_content_li {
  width: 100%;
  height: 100%;
}

.ProjectLearningProgress {
  width: 100%;
  height: 100%;
}

.ProjectLearningProgress_top {
  background-image: url("../../assets/img/imgStaly1/toggle_btn_bg02.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 6px;
  width: 100%;
  height: 10%;
  display: flex;
}

.ProjectLearningProgress_top_span {
  color: #9999cc;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
}

.ProjectLearningProgress_top_span_actived {
  color: #fff;
  background-image: url("../../assets/img/imgStaly1/toggle_select_btn02.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ProjectLearningProgress_content {
  width: 100%;
  height: 87%;
}

.ProjectLearningProgress_content_box {
  background-color: #003399;
  padding: 20px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.ProjectLearningProgress_content_box_title {
  display: flex;
  font-size: 0.9em;
  color: #fff;
  margin-bottom: 10px;
}

.ProjectLearningProgress_content_box_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 71%;
}

.ProjectLearningProgress_content_box_bottom_left {
  width: 30%;
  /* height: 100%; */
  height: 200px;
}

.ProjectLearningProgress_content_box_bottom_right {
  width: 70%;
  height: 100%;
}

.ProjectLearningProgress_content_box_bottom_right_title {
  display: flex;
  align-items: center;
}

.ProjectLearningProgress_content_box_bottom_right_title_span {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: #0099ff;
  margin-right: 10px;
}

.ProjectLearningProgress_content_box_bottom_right_title_span1 {
  font-size: 0.7em;
  color: #fff;
}

.ProjectLearningProgress_content_box_bottom_right_bottom {
  width: 100%;
  /* height: 77%; */
  height: 200px;
}
</style>
<style>
/*  .ProjectLearningProgress_content_li>div, .ProjectLearningProgress_content_li>div>div{
        width: 100% !important;
        height: 100% !important;
    } */
</style>
