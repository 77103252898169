<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="dataScreen_content_box_left">
      <div class="dataScreen_title">
        <TitlesImg :type="record.titleImgType" />
        <!-- <TitlesImg :type="8" /> -->
        <div class="titlePosition" style="margin-left: 2em;">
          {{ record.title }}
        </div>
      </div>
      <div class="dataScreen_content_left_top_box1_top">
        <img src="../../assets/img/imgStaly1/deyu_paiming_pillar.png" class="DyComprehensiveAppraisal2_img" alt="">
        <span class="DyComprehensiveAppraisal2_box DyComprehensiveAppraisal2_box1"> 
          <img src="../../assets//img/imgStaly1/silver_medal01.png" alt="">
          <span class="DyComprehensiveAppraisal2_box_span">{{deyuTopThree[0].name}}</span>
         </span>
         <span class="DyComprehensiveAppraisal2_box DyComprehensiveAppraisal2_box2"> 
          <img src="../../assets//img/imgStaly1/gold_medal01.png" alt="">
          <span class="DyComprehensiveAppraisal2_box_span">{{deyuTopThree[1].name}}</span>
         </span>
         <span class="DyComprehensiveAppraisal2_box DyComprehensiveAppraisal2_box3"> 
          <img src="../../assets//img/imgStaly1/bronze_medal01.png" alt="">
          <span class="DyComprehensiveAppraisal2_box_span">{{deyuTopThree[2].name}}</span>
         </span>
      </div>
      <div class="dataScreen_content_left_top_box1_bottom">
        <div class="item dataScreen_content_left_top_box1_bottom_container_box dataScreen_content_left_top_box1_bottom_container_box2">
          <span class="dataScreen_content_left_top_box1_bottom_container_box_left">
            <span style="color: #53c5f7;">排名</span>
          </span>
          <span class="dataScreen_content_left_top_box1_bottom_container_box_span" style="color: #53c5f7;">积分</span>
        </div>
        <vue-seamless-scroll v-if="deyuList.length>=1" :data="deyuList" class="seamless-warp"
          :class-option="defaultOption">
          <ul class="ul-scoll">
            <li v-for="(item, index) in deyuList" class="ul_scoll_li" :key="index">
              <div class="item dataScreen_content_left_top_box1_bottom_container_box">
                <span class="dataScreen_content_left_top_box1_bottom_container_box_left">
                  <span class="DyComprehensiveAppraisal2_order">{{item.Ranking}}</span>
                  <span>{{item.name}}</span>
                </span>
                <span class="dataScreen_content_left_top_box1_bottom_container_box_span">{{item.score}}</span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  import {
    bigscreenLeftV2
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "DyComprehensiveAppraisal",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      },
    },
    components: {
      vueSeamlessScroll,
      TitlesImg
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length               
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        spinning: false,
        deyuTopThree: [{
            id: 1,
            name: "-",
          },
          {
            id: 2,
            name: "-",
          },
          {
            id: 3,
            name: "-",
          },
        ],
        deyuList: []
      };
    },
    methods: {
      loadData() {
        this.spinning = true
        bigscreenLeftV2({
            subSchoolId: this.currentSubSchoolId,
            gradeIds: this.record.gradeIds.join(','),
            sType: 0,
            useFakeData: this.record.useFakeData?this.record.useFakeData:0
          },
          (resp) => {
            const resData = resp.data
            this.deyuList = resData
            this.deyuTopThree = []
            this.deyuTopThree.push(resData[1]?resData[1]:{id: 2, name: "-"})
            this.deyuTopThree.push(resData[0]?resData[0]:{id: 1, name: "-"})
            this.deyuTopThree.push(resData[2]?resData[2]:{id: 3, name: "-"})
            let Ranking = 1
            for (let i = 0; i < this.deyuList.length; i++) {
              if (i == 0) {
                this.deyuList[i].Ranking = Ranking
              } else {
                Ranking += 1
                if (this.deyuList[i].score == this.deyuList[i-1].score) {
                  this.deyuList[i].Ranking = this.deyuList[i-1].Ranking - 1
                } else {
                  this.deyuList[i].Ranking = Ranking
                }
              }
            }
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      }

    },
    mounted() {
      this.loadData()
    }
  };

</script>

<style scoped>
  .DyComprehensiveAppraisal2_img{
    width: 55%;
    margin-top: 60px;
  }
  .DyComprehensiveAppraisal2_box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
  }
  .DyComprehensiveAppraisal2_box1{
    top: 35px;
    left:20px;
  }
  .DyComprehensiveAppraisal2_box2{
    top: 5px;
    left:130px;
  }
  .DyComprehensiveAppraisal2_box3{
    top: 58px;
    left: 243px;
  }
  .DyComprehensiveAppraisal2_box_span
  {

  }
  .DyComprehensiveAppraisal2_order{
    display: inline-block;
    margin-right: 10px;
  }
  .dataScreen_content_left_top_box1_bottom_container_box {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  justify-content: flex-start;
  font-size: 1em;
  /* height: auto !important; */
}
.dataScreen_content_left_top_box1_bottom_container_box2{
  background-color: #08237d;
  padding: 10px 15px;
}

.ul_scoll_li {
  color: #fff;
  padding: 5px 0px;
}

.ul_scoll_li:nth-child(2n) {
  background-color: #08237d;
}
</style>
