<template>
  <div class="MonthExel">
    <img class="bgImg" src="../../assets/img/imgStaly1/exam_show_bg.png" alt="" />
    <div class="MonthExelTitle">
      {{ record.title }}
    </div>
    <div class="Exel">
      <div class="ExelLineTitle">
        <div class="ExeBox">
          <div class="ExelOnes">
            <div v-for="(items, indexs) in list[0]" :key="indexs" class="ExelOne" :style="{'width':width +'%'}">
              <div>
                {{ items }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-seamless-scroll v-if="list.length>=1" :data="list" style="height: 90%;overflow: hidden;"
        :class-option="defaultOption">
        <div v-for="(item, index) in list" :key="index" class="ExelLine">
          <div class="ExeBox" v-if="index != 0">
            <div class="ExelOnesBg">

            </div>
            <div class="ExelOnes">
              <div v-for="(items, indexs) in item" :key="indexs" class="ExelOne" :style="{'width':width +'%'}">
                <div>
                  {{ items }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "MonthExel2",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        list: [],
        width: ''
      };
    },
    methods: {
    },
    mounted() {
      this.list = []
      for (let i = 0; i < this.record.exel.length; i++) {
        let data = this.record.exel[i].split(',')
        this.width = 100 / data.length
        this.list.push(data)
      }
    }
  };

</script>

<style scoped>
  .MonthExel {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .MonthExelTitle {
    position: absolute;
    width: 100%;
    height: 9%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
  }

  .bgImg {
    width: 100%;
    height: 100%;
  }

  .Exel {
    position: absolute;
    top: 9%;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: 80%;
  }

  .ExelLineTitle {
    display: flex;
    margin-bottom: 1%;
    height: 10%;
    width: 100%;
    background-color: #002fba;
  }

  .ExelLine {
    display: flex;
    margin-bottom: 1%;
    height: 2em;
    width: 100%;
  }

  .ExeBox {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .ExeBg {
    width: 100%;
    height: 100%;
  }

  .ExelOnesBg {
    width: 100%;
    height: 2em;
  }

  .ExelLine:nth-child(2n) {
    background-color: #022797;
  }

  .ExelOnes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .ExelOne {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #91ccee;
    font-size: 0.7em;
  }
</style>