<template>
  <div>
    <div class="Screen" :style="{'width': width + 'px','height': height + 'px','background-image': `url(${bg})`}">
      <div v-for="(item, index) in dataList" :key="index" class="screenList">
        <LearningProgress v-if="item.name == 'mokuai11'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <DyComprehensiveAppraisal v-if="item.name == 'mokuai17'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <DyComprehensiveAppraisal2 v-if="item.name == 'mokuai34'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <DyComprehensiveAppraisalWeeks v-if="item.name == 'mokuai47'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AssessmentItem v-if="item.name == 'mokuai18'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AssessmentItem2 v-if="item.name == 'mokuai35'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AssessmentItem3 v-if="item.name == 'mokuai41'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AssessmentWeeksItem v-if="item.name == 'mokuai45'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder v-if="item.name == 'mokuai19'" :currentSubSchoolId="subSchoolId" :warnType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder v-if="item.name == 'mokuai20'" :currentSubSchoolId="subSchoolId" :warnType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder2 v-if="item.name == 'mokuai27'" :currentSubSchoolId="subSchoolId" :warnType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder2 v-if="item.name == 'mokuai28'" :currentSubSchoolId="subSchoolId" :warnType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder3 v-if="item.name == 'mokuai38'" :currentSubSchoolId="subSchoolId" :warnType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder3 v-if="item.name == 'mokuai39'" :currentSubSchoolId="subSchoolId" :warnType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder4 v-if="item.name == 'mokuai36'" :currentSubSchoolId="subSchoolId" :warnType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder4 v-if="item.name == 'mokuai37'" :currentSubSchoolId="subSchoolId" :warnType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder5 v-if="item.name == 'mokuai43'" :currentSubSchoolId="subSchoolId" :warnType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EventReminder5 v-if="item.name == 'mokuai44'" :currentSubSchoolId="subSchoolId" :warnType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <StyleStudyRanking v-if="item.name == 'mokuai21'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <CentreBanner v-if="item.name == 'mokuai10'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai1'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width':'100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai25'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai31'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai33'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai53'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai66'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai67'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai68'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Title v-if="item.name == 'mokuai99'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Footer v-if="item.name == 'mokuai2'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Footer v-if="item.name == 'mokuai26'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Footer v-if="item.name == 'mokuai69'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Footer v-if="item.name == 'mokuai100'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': '100%','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Time v-if="item.name == 'mokuai5'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <weather v-if="item.name == 'mokuai3'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BannerModel v-if="item.name == 'mokuai7'" :currentSubSchoolId="subSchoolId" :imgType="2" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BannerModel v-if="item.name == 'mokuai8'" :currentSubSchoolId="subSchoolId" :imgType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BannerModel v-if="item.name == 'mokuai9'" :currentSubSchoolId="subSchoolId" :imgType="3" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BannerModel v-if="item.name == 'mokuai30'" :currentSubSchoolId="subSchoolId" :imgType="4" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <LessonPreparation v-if="item.name == 'mokuai22'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <LessonPreparation2 v-if="item.name == 'mokuai51'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <TeacherCorrection v-if="item.name == 'mokuai23'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <TeacherCorrection2 v-if="item.name == 'mokuai60'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <MaterialUpload v-if="item.name == 'mokuai12'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AutonomousHeroesList v-if="item.name == 'mokuai13'" :currentSubSchoolId="subSchoolId" heroType="0" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AutonomousHeroesList v-if="item.name == 'mokuai16'" :currentSubSchoolId="subSchoolId" heroType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AutonomousHeroesList2 v-if="item.name == 'mokuai40'" :currentSubSchoolId="subSchoolId" heroType="1" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <AutonomousHeroesList2 v-if="item.name == 'mokuai57'" :currentSubSchoolId="subSchoolId" heroType="0" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <OvercomeDifficulties v-if="item.name == 'mokuai14'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Titles v-if="item.name == 'mokuai29'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Exel v-if="item.name == 'mokuai32'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Achievement v-if="item.name == 'mokuai42'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EvaluationImplementation  v-if="item.name == 'mokuai46'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EvaluationImplementationKetang  v-if="item.name == 'mokuai70'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <EvaluationSummary  v-if="item.name == 'mokuai48'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <MonthExel v-if="item.name == 'mokuai50'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <MonthExel v-if="item.name == 'mokuai59'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <MonthExel2 v-if="item.name == 'mokuai58'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <HomeWork v-if="item.name == 'mokuai52'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ProjectLearningProgress v-if="item.name == 'mokuai55'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassHourExel v-if="item.name == 'mokuai54'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <UploadQuestions v-if="item.name == 'mokuai56'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <CampusPopulationOverview v-if="item.name == 'mokuai61'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <CampusCulture v-if="item.name == 'mokuai64'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassHour v-if="item.name == 'mokuai62'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <MonthHomeWork v-if="item.name == 'mokuai63'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Texts v-if="item.name == 'mokuai65'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Unlawful v-if="item.name == 'mokuai71'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ErrorCorrectionLeaderboard v-if="item.name == 'mokuai72'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <Unlawful2 v-if="item.name == 'mokuai73'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/> 
        <LearningOutcome v-if="item.name == 'mokuai91'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <NumberExercises v-if="item.name == 'mokuai92'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <GainPoints v-if="item.name == 'mokuai93'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassRoom @classRoom="classRoom" v-if="item.name == 'mokuai94'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassPeriod v-if="item.name == 'mokuai95'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassStatistics v-if="item.name == 'mokuai96'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <QuestionsTypeRatio v-if="item.name == 'mokuai97'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <QuestionsRightRatio v-if="item.name == 'mokuai98'" :classRoomId="classRoomId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <LearningOutcomeAll v-if="item.name == 'mokuai101'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <NumberExercisesClass v-if="item.name == 'mokuai102'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <GainPointsAll v-if="item.name == 'mokuai103'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <NumberExercisesTendency v-if="item.name == 'mokuai104'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <GainPointsClass v-if="item.name == 'mokuai105'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <CalssProblemStatistics v-if="item.name == 'mokuai106'" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <!-- 电子班牌 -->
        <DemocraticAutonomy v-if="item.name == 'mokuai74'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BannerModelBclass v-if="item.name == 'mokuai75'" :bclassId="bclassId" :imgType="4" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <StudyHeroList v-if="item.name == 'mokuai76'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassCulture v-if="item.name == 'mokuai77'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassConvention v-if="item.name == 'mokuai78'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <NotificationAnnouncement v-if="item.name == 'mokuai79'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <DateElement v-if="item.name == 'mokuai80'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <BclassName v-if="item.name == 'mokuai81'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <SchoolName v-if="item.name == 'mokuai82'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassCulture2 v-if="item.name == 'mokuai83'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassConvention2 v-if="item.name == 'mokuai84'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <ClassAttendance v-if="item.name == 'mokuai85'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <NotificationAnnouncement2 v-if="item.name == 'mokuai86'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <DemocraticAutonomy2 v-if="item.name == 'mokuai87'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <StudyHeroList2 v-if="item.name == 'mokuai88'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <PraiseColumn v-if="item.name == 'mokuai89'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
        <CriticismColumn v-if="item.name == 'mokuai90'" :bclassId="bclassId" :currentSubSchoolId="subSchoolId" :style="{'font-size': item.fontSize+'px','width': item.width+'px','height': item.height+'px','top': item.top+'px','left': item.left+'px'}" :record="item" :class="item.name"/>
      </div>
        
      <!-- <CampusPopulationOverview style="width: 351px;height: 168px;margin: 100px;font-size: 32px;"/> -->
      <!-- <ProjectLearningProgress style="width: 1210px;height: 565px;margin: 100px;font-size: 32px;"/> -->
    </div>
    <!-- <div class="masking">
    </div> -->
  </div>
</template>

<script>
  // import $ from "jquery";
  
  import LearningProgress from '@/components/CustomModule/LearningProgress'
  import DyComprehensiveAppraisal from '@/components/CustomModule/DyComprehensiveAppraisal'
  import DyComprehensiveAppraisal2 from '@/components/CustomModule/DyComprehensiveAppraisal2'
  import DyComprehensiveAppraisalWeeks from '@/components/CustomModule/DyComprehensiveAppraisalWeeks'
  import AssessmentItem from '@/components/CustomModule/AssessmentItem'
  import AssessmentItem2 from '@/components/CustomModule/AssessmentItem2'
  import AssessmentItem3 from '@/components/CustomModule/AssessmentItem3'
  import AssessmentWeeksItem from '@/components/CustomModule/AssessmentWeeksItem'
  import EventReminder from '@/components/CustomModule/EventReminder'
  import EventReminder2 from '@/components/CustomModule/EventReminder2'
  import EventReminder3 from '@/components/CustomModule/EventReminder3'
  import Titles from '@/components/CustomModule/Titles'
  import EventReminder4 from '@/components/CustomModule/EventReminder4'
  import EventReminder5 from '@/components/CustomModule/EventReminder5'
  import StyleStudyRanking from '@/components/CustomModule/StyleStudyRanking'
  import CentreBanner from '@/components/CustomModule/CentreBanner'
  import Title from '@/components/CustomModule/Title'
  import Footer from '@/components/CustomModule/Footer'
  import Time from '@/components/CustomModule/Time'
  import weather from '@/components/CustomModule/weather'
  import BannerModel from '@/components/CustomModule/BannerModel'
  import LessonPreparation from '@/components/CustomModule/LessonPreparation'
  import LessonPreparation2 from '@/components/CustomModule/LessonPreparation2'
  import TeacherCorrection from '@/components/CustomModule/TeacherCorrection'
  import TeacherCorrection2 from '@/components/CustomModule/TeacherCorrection2'
  import MaterialUpload from '@/components/CustomModule/MaterialUpload'
  import AutonomousHeroesList from '@/components/CustomModule/AutonomousHeroesList'
  import AutonomousHeroesList2 from '@/components/CustomModule/AutonomousHeroesList2'
  import OvercomeDifficulties from '@/components/CustomModule/OvercomeDifficulties'
  import Exel from '@/components/CustomModule/Exel'
  import EvaluationImplementation from '@/components/CustomModule/EvaluationImplementation'
  import EvaluationImplementationKetang from '@/components/CustomModule/EvaluationImplementationKetang'
  import Achievement from '@/components/CustomModule/Achievement'
  import EvaluationSummary from '@/components/CustomModule/EvaluationSummary'
  import MonthExel from '@/components/CustomModule/MonthExel'
  import MonthExel2 from '@/components/CustomModule/MonthExel2'
  import HomeWork from '@/components/CustomModule/HomeWork'
  import ProjectLearningProgress from '@/components/CustomModule/ProjectLearningProgress'
  import ClassHourExel from '@/components/CustomModule/ClassHourExel'
  import UploadQuestions from '@/components/CustomModule/UploadQuestions'
  import CampusPopulationOverview from '@/components/CustomModule/CampusPopulationOverview'
  import CampusCulture from '@/components/CustomModule/CampusCulture'
  import ClassHour from '@/components/CustomModule/ClassHour'
  import MonthHomeWork from '@/components/CustomModule/MonthHomeWork'
  import Texts from '@/components/CustomModule/Texts'
  import Unlawful from '@/components/CustomModule/Unlawful'
  import ErrorCorrectionLeaderboard from '@/components/CustomModule/ErrorCorrectionLeaderboard'
  import Unlawful2 from '@/components/CustomModule/Unlawful2'
  import DemocraticAutonomy from '@/components/CustomModule/DemocraticAutonomy'
  import StudyHeroList from '@/components/CustomModule/StudyHeroList'
  import ClassCulture from '@/components/CustomModule/ClassCulture'
  import ClassConvention from '@/components/CustomModule/ClassConvention'
  import NotificationAnnouncement from '@/components/CustomModule/NotificationAnnouncement'
  import DateElement from '@/components/CustomModule/DateElement'
  import BclassName from '@/components/CustomModule/BclassName'
  import SchoolName from '@/components/CustomModule/SchoolName'
  import BannerModelBclass from '@/components/CustomModule/BannerModelBclass'
  import ClassCulture2 from '@/components/CustomModule/ClassCulture2'
  import ClassConvention2 from '@/components/CustomModule/ClassConvention2'
  import ClassAttendance from '@/components/CustomModule/ClassAttendance'
  import NotificationAnnouncement2 from '@/components/CustomModule/NotificationAnnouncement2'
  import DemocraticAutonomy2 from '@/components/CustomModule/DemocraticAutonomy2'
  import StudyHeroList2 from '@/components/CustomModule/StudyHeroList2'
  import PraiseColumn from '@/components/CustomModule/PraiseColumn'
  import CriticismColumn from '@/components/CustomModule/CriticismColumn'
  import LearningOutcome from '@/components/CustomModule/LearningOutcome'
  import NumberExercises from '@/components/CustomModule/NumberExercises'
  import GainPoints from '@/components/CustomModule/GainPoints'
  import ClassRoom from '@/components/CustomModule/ClassRoom'
  import ClassPeriod from '@/components/CustomModule/ClassPeriod'
  import ClassStatistics from '@/components/CustomModule/ClassStatistics'
  import QuestionsTypeRatio from '@/components/CustomModule/QuestionsTypeRatio'
  import QuestionsRightRatio from '@/components/CustomModule/QuestionsRightRatio'
  import LearningOutcomeAll from '@/components/CustomModule/LearningOutcomeAll'
  import NumberExercisesClass from '@/components/CustomModule/NumberExercisesClass'
  import GainPointsAll from '@/components/CustomModule/GainPointsAll'
  import NumberExercisesTendency from '@/components/CustomModule/NumberExercisesTendency'
  import GainPointsClass from '@/components/CustomModule/GainPointsClass'
  import CalssProblemStatistics from '@/components/CustomModule/CalssProblemStatistics'
  
  // import Slideshow from '@/components/CustomModule/Slideshow'
  export default {
    name: "PreviewBigScreet",
    components: {
      LearningProgress,
      DyComprehensiveAppraisal,
      DyComprehensiveAppraisal2,
      DyComprehensiveAppraisalWeeks,
      AssessmentItem,
      AssessmentItem2,
      AssessmentItem3,
      AssessmentWeeksItem,
      EventReminder,
      EventReminder2,
      EventReminder3,
      EventReminder4,
      EventReminder5,
      StyleStudyRanking,
      CentreBanner,
      Title,
      Footer,
      Time,
      weather,
      BannerModel,
      LessonPreparation,
      LessonPreparation2,
      TeacherCorrection,
      TeacherCorrection2,
      MaterialUpload,
      AutonomousHeroesList,
      AutonomousHeroesList2,
      OvercomeDifficulties,
      Titles,
      Exel,
      EvaluationImplementation,
      EvaluationImplementationKetang,
      Achievement,
      EvaluationSummary,
      MonthExel,
      MonthExel2,
      HomeWork,
      ProjectLearningProgress,
      ClassHourExel,
      UploadQuestions,
      CampusPopulationOverview,
      CampusCulture,
      ClassHour,
      MonthHomeWork,
      Texts,
      Unlawful,
      ErrorCorrectionLeaderboard,
      Unlawful2,
      DemocraticAutonomy,
      StudyHeroList,
      ClassCulture,
      ClassConvention,
      NotificationAnnouncement,
      DateElement,
      BclassName,
      SchoolName,
      BannerModelBclass,
      ClassCulture2,
      ClassConvention2,
      ClassAttendance,
      NotificationAnnouncement2,
      DemocraticAutonomy2,
      StudyHeroList2,
      PraiseColumn,
      CriticismColumn,
      LearningOutcome,
      NumberExercises,
      GainPoints,
      ClassRoom,
      ClassPeriod,
      ClassStatistics,
      QuestionsTypeRatio,
      QuestionsRightRatio,
      LearningOutcomeAll,
      NumberExercisesClass,
      GainPointsAll,
      NumberExercisesTendency,
      GainPointsClass,
      CalssProblemStatistics
      // Slideshow
    },
    data() {
      return {
        width: '',
        height: '',
        bg: '',
        subSchoolId: '',
        dataList: [],
        bclassId: '',
        classRoomId: 0
      };
    },
    methods: {
      classRoom(val) {
        this.classRoomId = val
        this.$nextTick()
      }
    },
    mounted() {
      this.dataList = JSON.parse(localStorage.getItem('dataList'))
      let dataList = JSON.parse(localStorage.getItem('dataList'))
      this.bclassId = localStorage.getItem('bclassId')
      console.log(localStorage.getItem('bclassId'))
      for (let i = 0; i < dataList.length; i++) {
        dataList[i].assessmentIds = []
        dataList[i].assessmentNames = []
        dataList[i].xuekeIds = []
        dataList[i].xuekeNames = []
        dataList[i].gradeIds = []
        dataList[i].gradeNames = []
        dataList[i].exel = []
      }
      console.log('dataList===>', dataList)
      this.width = localStorage.getItem('width')
      this.height = localStorage.getItem('height')
      this.bg = localStorage.getItem('bg')
      this.subSchoolId = this.$route.query.currentSubSchoolId
    },
  };

</script>

<style>
@import '../assets/css/previewIndex.css';
.Screen {
  position: relative;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.screenList>div{
  position: absolute;
}
.masking {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.mokuaiMp4 {
  width: 100%;
}
.itemBoxComparison {
  position: relative;
  width: 100%;
  height: 100%;
}
.title {
  font-size: 1em;
  font-weight: 700;
  color: #fff;
}
.titlePosition {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2em;
  margin: auto;
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
}
</style>
