<template>
  <div class="MonthExel">
    <img class="bgImg" :src="record.name == 'mokuai50' ? require('../../assets/img/imgStaly1/exam_show_bg.png') : require('../../assets/img/imgStaly1/exam_show_bg01.png')" alt="" />
    <div class="MonthExelTitle" :style="{'height': record.name == 'mokuai50' ? '9%' : '7.8%'}">
      {{ record.title }}
    </div>
    <div class="Exel">
      <div class="ExelLineTitle">
        <div class="ExeBox">
          <div class="ExelOnes">
            <div v-for="(items, indexs) in list[0]" :key="indexs" class="ExelOne" :style="{'width':width +'%'}">
              <div>
                {{ items }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-seamless-scroll v-if="list.length>=1" :data="list" style="height: 90%;overflow: hidden;"
        :class-option="defaultOption">
        <div v-for="(item, index) in list" :key="index" class="ExelLine">
          <div class="ExeBox" v-if="index != 0">
            <div class="ExelOnesBg">

            </div>
            <div class="ExelOnes">
              <div v-for="(items, indexs) in item" :key="indexs" class="ExelOne" :style="{'width':width +'%'}">
                <div>
                  {{ items }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
    <div class="ratio">
      <div class="ratioTitle">
        综合{{ ratioList[0] }}
      </div>
      <div class="ratioBox">
        <div class="ratioBoxLeft">
          <img src="../../assets/img/imgStaly1/jiaoxue_rate_bg.png" style="max-width: 100%;max-height: 100%;" alt="">
          <a-progress class="ratioBoxLeftProgress" :width="record.width * 0.15" :strokeWidth="record.fontSize/8" stroke-linecap="square"
            strokeColor="#1cf1ff" :percent="num" type="circle" />
          <div class="ratioBoxLeftProgressTitle">
            {{ ratioList[0] }}
          </div>
        </div>
        <div class="ratioBoxRight">
          <div style="width: 100%;height: 100%;" :id="record.id"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/pie')
  require('echarts/lib/chart/line')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  import {
    Progress
  } from 'ant-design-vue';
  export default {
    name: "MonthExel",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
      vueSeamlessScroll,
      'AProgress': Progress,
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        list: [],
        ratioList: [],
        width: '',
        num: 0
      };
    },
    methods: {
      drawLine(div, data) {
        let echartsBox1 = echarts.init(document.getElementById(div))
        const xData = [];
        const yData = [];
        data.forEach((item, index) => {
          xData.push(item.name)
          yData.push(item.value)
        })

        const options = {
          grid: {
            left: 10,
            right: 10,
            bottom: '3%',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            data: xData,
            axisLabel: {
              margin: this.record.fontSize*0.7,
              textStyle: {
                fontSize: this.record.fontSize*0.7,
                color: '#CCECF9',
              }
            },
          }],
          yAxis: [{
            type: 'value',
            min: 0,  //取0为最小刻度
            max: 100, //取100为最大刻度
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#0C399D',
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: this.record.fontSize*0.7,
                color: '#CCECF9',
              },
              formatter: `{value}%`,
            },
            splitLine: {
              lineStyle: {
                color: '#0C399D'
              }
            },
          }],
          series: [
            {
              type: 'line',
              data: yData,
              itemStyle: {
                normal: {
                  color: '#1cf1ff', //改变折线点的颜色
                  lineStyle: {
                    color: '#1091c4' //改变折线颜色
                  }
                }
              },
              areaStyle: {}
            }
          ]
        };
        echartsBox1.setOption(options)
      },
    },
    mounted() {
      this.list = []
      this.ratioList = []
      let parlist = []
      let num = 0
      for (let i = 0; i < this.record.exel.length; i++) {
        let data = this.record.exel[i].split(',')
        this.ratioList.push(data[data.length - 1])
        this.width = 100 / data.length
        this.list.push(data)
        if (i != 0) {
          parlist.push({
            name: data[1],
            value: data[data.length - 1].replace('%', ''),
          })
          num += data[data.length - 1].replace('%', '')*1
        }
      }
      if (parlist.length != 0) {
        this.num = (num/parlist.length).toFixed(0)
      } else {
        this.num = 0
      }
      this.drawLine(`${this.record.id}`, parlist)
    }
  };

</script>

<style scoped>
  .MonthExel {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .MonthExelTitle {
    position: absolute;
    width: 100%;
    height: 9%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
  }

  .bgImg {
    width: 100%;
    height: 100%;
  }

  .ratio {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5%;
    margin: auto;
    width: 85%;
    height: 25%;
  }

  .ratioTitle {
    font-size: 1em;
    font-weight: 700;
    color: #fff;
    height: 1em;
  }

  .ratioBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ratioBoxLeft {
    height: 90%;
    width: 30%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ratioBoxLeftProgress {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
  }

  .ratioBoxLeftProgress /deep/.ant-progress-text {
    color: #fff;
    font-size: 1.3em;
    font-weight: 700;
  }

  .ratioBoxLeftProgressTitle {
    position: absolute;
    left: 0;
    right: 0;
    top: 3em;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    color: #fff;
  }

  .ratioBoxRight {
    height: 120%;
    width: 70%;
  }

  .ratioBox {
    width: 100%;
    height: calc(100% - 1em);
    display: flex;
    justify-content: space-between;
  }

  .Exel {
    position: absolute;
    top: 9%;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: 60%;
  }

  .ExelLineTitle {
    display: flex;
    margin-bottom: 1%;
    height: 10%;
    width: 100%;
    background-color: #002fba;
  }

  .ExelLine {
    display: flex;
    margin-bottom: 1%;
    height: 2em;
    width: 100%;
  }

  .ExeBox {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .ExeBg {
    width: 100%;
    height: 100%;
  }

  .ExelOnesBg {
    width: 100%;
    height: 2em;
  }

  .ExelLine:nth-child(2n) {
    background-color: #022797;
  }

  .ExelOnes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .ExelOne {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #91ccee;
    font-size: 0.7em;
  }
</style>