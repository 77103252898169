  <template>
    <div class="ClassHourExel">
      <vue-seamless-scroll v-if="list.length" :data="list" class="ClassHourExelScroll"
        :class-option="defaultOption">
        <div v-for="(item, index) in list" :key="index" class="ClassHourExelLine">
          <div class="ClassHourExelBox" v-if="index != 0" >
            <img class="ClassHourExelBg" src="../../assets/img/imgStaly1/table_bg03.png" alt="">
            <div class="ClassHourExelOnes">
              <div class="ClassHourExelOne" :style="{'width':'30%'}">
                <div>
                  {{ item.teacherName }}
                </div>
              </div>
              <div class="ClassHourExelOne" :style="{'width':'30%'}">
                <div>
                  上课课时<span style="font-size: 1.2em;color: #d49e5a;margin-left: 0.2em;">{{ item.allCount }}</span>
                </div>
              </div>
              <div class="ClassHourExelOne" :style="{'width':'30%'}">
                <div>
                  规范课时<span style="font-size: 1.2em;color: #d49e5a;margin-left: 0.2em;">{{ item.goodCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </template>
  
  <script>
     import {
      bigScreenTeacherGoodKeshi
    } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
      name: "ClassHourExel",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      components: {
        vueSeamlessScroll
      },
      // 监听属性 类似于data概念      
      computed: {
        defaultOption() {
          return {
            step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
            limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
            hoverStop: false, // 是否开启鼠标悬停stop          
            direction: 1, // 0向下 1向上 2向左 3向右      
            openWatch: true, // 开启数据实时监控刷新dom                
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
            waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
          }
        }
      },
      data() {
        return {
          list: []
        };
      },
      methods: {
        getGoodKeshi () {
          bigScreenTeacherGoodKeshi(
            {
              subSchoolId: this.currentSubSchoolId,
              useFakeData: this.record.useFakeData
            },
            (resp) => {
              const resData = resp.data
              this.list = resData
            },
            (err) => {
              this.$message.error(err)
            }
          )
        }
      },
      mounted() {
        this.getGoodKeshi()
      }
    }
  </script>
  
  <style scoped>
    .ClassHourExel {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/img/imgStaly1/class_keshi_bg.png');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ClassHourExelScroll {
      height: 90%;
      width: 90%;
      overflow: hidden;
    }
    .ClassHourExelLine {
      width: 100%;
    }
    .ClassHourExelBox {
      position: relative;
      width: 100%;
      height: 3em;
      margin: 1em 0;
    }
    .ClassHourExelBg {
      width: 100%;
      height: 100%;
    }
    .ClassHourExelOnes {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: flex;
    }
    .ClassHourExelOne {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b3b8d5;
      font-size: 0.8em;
      font-weight: 700;
    }
  </style>
  