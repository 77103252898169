<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="dataScreen_content_3box_top">
      <img src="../../assets/img/imgStaly1/study_heroes_bg01.png" class="dataScreen_content_3box_top_img" alt="" v-if="heroType==0">
      <div :style="{'top': record.name=='mokuai13' ? '4%' : '3%'}" style="position: absolute;top: 4%;right: 0;left: 0;margin: auto;font-size: 1em;color: #fff;font-weight: 700;display: flex;justify-content: center;">
        {{ record.title }}
      </div>
      <img src="../../assets/img/imgStaly1/heroes_score_bg01.png" class="dataScreen_content_3box_top_img" alt="" v-if="heroType==1">
      <div class="dataScreen_content_3box_top_content">
        <div class="dataScreen_content_3box_top_content_left">
          <div class="dataScreen_content_3box_top_content_left_box">
            <span class="dataScreen_content_3box_top_content_left_span"
            :class="studySubjectText==item?'dataScreen_content_3box_top_content_left_span_actived':''"
            v-for="(item, index) in studySubject" :key="index">{{item}}</span>
          </div>
        </div>
        <div class="dataScreen_content_3box_top_content_right">
          <div class="dataScreen_content_3box_top_content_right_box" :style="{'width': (100/record.gradeIds.length) - 1 + '%'}" v-for="(item, index) in studyList" :key="index">
            <img src="../../assets/img/imgStaly1/heroes_study_grade_bg.png"
              class="dataScreen_content_3box_top_content_right_box_img" alt="">
            <div class="dataScreen_content_3box_top_content_right_box_title">
              <span>{{item.gradeName}}</span>
            </div>
            <div class="dataScreen_content_3box_top_content_right_box_content">
              <vue-seamless-scroll v-if="item.list.length>=1" :data="item.list" @ScrollEnd="ScrollEnd(item)"
                class="seamless-warp" :class-option="defaultOption">
                <ul class="ul-scoll">
                  <li v-for="(items, indexs) in item.list" class="ul_scoll_li" :key="indexs">
                    <div class="dataScreen_content_right_top_box_right_box_box">
                      <span class="dataScreen_content_3box_top_content_right_box_content_left"
                        :class="4>items.oderNum?'dataScreen_content_3box_top_content_right_box_content_left3':''">{{items.oderNum}}</span>
                      <span class="dataScreen_content_right_top_box_right_box_box_span1">{{items.text}}</span>
                      <!-- style="width: calc(100% - 20px);" -->
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import {
    bigscreenStudyHeros
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "AutonomousHeroesList",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      },
      heroType: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length           
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        spinning: false,
        studySubject: [],
        studyList: [],
        studySubjectText: '',
        index: 0
      };
    },
    methods: {
      loadData(xuekeId) {
        this.spinning = true
        const that = this
        bigscreenStudyHeros({
            heroType: this.heroType,
            disciplineIds: xuekeId,
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
              that.studyList = []
              // that.studySubject = []
              // const gaoyiList = []
              // const gaoerList = []
              // const gaosanList = []
              for (let i = 0; i < resData.length; i++) {
                const e = resData[i]
                for (let j = 0; j < e.gradeStus.length; j++) {
                  const el = e.gradeStus[j];
                  let list = []
                  for (let k = 0; k < el.stuNames.length; k++) {
                    const ele = el.stuNames[k];
                    const obj = {}
                    obj.text = ele
                    obj.subjectText = e.disciplineName
                    obj.oderNum = k + 1
                    list.push(obj)
                    // if (j == 0) {
                    //   gaoyiList.push(obj)
                    // } else if (j == 1) {
                    //   gaoerList.push(obj)
                    // } else if (j == 2) {
                    //   gaosanList.push(obj)
                    // }
                  }
                  that.studyList.push({
                      id: j,
                      gradeName: el.gradeName,
                      list: list
                    })
                }
              }
              // that.studyList.push({
              //   gradeName: '高一榜',
              //   list: gaoyiList
              // })
              // that.studyList.push({
              //   gradeName: '高二榜',
              //   list: gaoerList
              // })
              // that.studyList.push({
              //   gradeName: '高三榜',
              //   list: gaosanList
              // })
              // if (that.studyList.length > 10) {
              /*     that.$nextTick(() => {
                    that.swiperFun($('#studyBox0'), 8, 0, 3)
                    that.swiperFun($('#studyBox1'), 8, 0, 3)
                    that.swiperFun($('#studyBox2'), 8, 0, 3)
                  }) */
              // }
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      ScrollEnd(item) {
        if (item.id == 0) {
          if (this.record.xuekeNames.length>1) {
            this.index++
              if(this.index >= this.record.xuekeNames.length){
                this.index = 0
              }
              this.studySubjectText = this.record.xuekeNames[this.index]
              this.loadData(this.record.xuekeIds[this.index])
          }
        }
      }
    },
    mounted() {
      this.studySubject = this.record.xuekeNames
      this.studySubjectText = this.record.xuekeNames[0]
      this.loadData(this.record.xuekeIds[0])
    }
  };

</script>

<style scoped>
.ul_scoll_li {
  display: flex;
  justify-content: center;
}
</style>
