<template>
    <div class="PraiseColumn">
        <div class="PraiseColumn_content">
            <vue-seamless-scroll v-if=" dataInfo.length>=1" :data="dataInfo" class="seamless-warp"
                :class-option="defaultOption">
                <ul class="ul-scoll">
                    <li v-for="(item, index) in dataInfo" class="ul_scoll_li" :key="index">
                        <div class="PraiseColumn_box">
                            <span class="PraiseColumn_box_left"></span>
                            <span class="PraiseColumn_box_right">{{item.content}}</span>
                        </div>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>

    </div>
</template>

<script>
    import { getPraiseInfo } from '@/api/other'
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        name: "CriticismColumn",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            bclassId: {
                type: String
            }
        },
        components: {
            vueSeamlessScroll
        },
        // 监听属性 类似于data概念      
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            }
        },
        data() {
            return {
                dataInfo: []
            };
        },
        methods: {
            loaData() {
                getPraiseInfo(
                    {
                        id: this.bclassId,
                        type: 1
                    },
                    (resp) => {
                        const data = resp.data
                        this.dataInfo = data
                    },
                    (err) => {
                        this.$message.error(err)
                    }
                )
            }
        },
        mounted() {
            this.loaData()
        }
    }
</script>

<style scoped>
    @import '../../assets/css/electronicClassBoard.css';
    .PraiseColumn {
        background-image: url('../../assets/img/imgStaly1/criticismgroup_bg.png');
    }
</style>