<template>
  <div class="StudyHeroList">
    <div class=" dataScreen_content_bottom_box7">
      <div class="dataScreen_content_bottom_box5_title">
        <img src="../../assets/img/imgStaly1/title_bg2.png" class="dataScreen_content_bottom_box5_title_img" alt="">
        <span class="dataScreen_content_bottom_box5_title_span">{{record.title}}</span>
      </div>
      <div class="dataScreen_content_bottom_box8_bottom">
        <div class="dataScreen_content_bottom_box8_bottom_box">
          <div class="dataScreen_content_bottom_box8_bottom_box_top">
            <img src="../../assets/img/imgStaly1/notice_icon.png"
              class="dataScreen_content_bottom_box8_bottom_box_top_img" alt="">
            <span class="dataScreen_content_bottom_box8_bottom_box_top_span">学校通知</span>
          </div>
          <div class="dataScreen_content_bottom_box8_bottom_box_bottom">
            <vue-seamless-scroll v-if="schoolNoticeList.length>=1" :data="schoolNoticeList"
              class="seamless-warp" :class-option="defaultOption">
              <ul class="ul-scoll">
                <li v-for="(item, index) in schoolNoticeList" class="ul_scoll_li" :key="index">
                  <div class="leave_box">
                    <span class="leaveSpan"></span>
                    <span class="notice_span" style="width: calc(100% - 15px);display: inline-block;">{{item.content}}</span>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="dataScreen_content_bottom_box8_bottom_box">
          <div class="dataScreen_content_bottom_box8_bottom_box_top">
            <img src="../../assets/img/imgStaly1/notice_icon.png"
              class="dataScreen_content_bottom_box8_bottom_box_top_img" alt="">
            <span class="dataScreen_content_bottom_box8_bottom_box_top_span">班级通知</span>
          </div>
          <div class="dataScreen_content_bottom_box8_bottom_box_bottom">
            <vue-seamless-scroll v-if=" bclassNoticeList.length>=1" :data="bclassNoticeList"
              class="seamless-warp" :class-option="defaultOption">
              <ul class="ul-scoll">
                <li v-for="(item, index) in bclassNoticeList" class="ul_scoll_li" :key="index">
                  <div class="leave_box">
                    <span class="leaveSpan"></span>
                    <span class="notice_span" style="width: calc(100% - 15px);display: inline-block;">{{item.content}}</span>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getBulletinBoardContent,
    getGgByPageForWeb,
    getGgSubByPageForWeb
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "NotificationAnnouncement2",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      },
      bclassId: {
        type: String
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum :
          6, // 开始无缝滚动的数据量 this.dataList.length              
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        schoolNoticeList: [],
        bclassNoticeList: []
      };
    },
    methods: {
      //  优秀表现 
      getGoodKeshi() {
        getGgSubByPageForWeb(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const data = resp.data
            this.schoolNoticeList = data
          },
          (err) => {
            this.$message.error(err)
          }
        )
      },
      getNotice() {
        getGgByPageForWeb(
          {
            bclassId: this.bclassId
          },
          (resp) => {
            const data = resp.data
            this.bclassNoticeList = data
          },
          (err) => {
            this.$message.error(err)
          }
        )
      }
    },
    mounted() {
      this.getGoodKeshi()
      this.getNotice()
    }
  }

</script>

<style scoped>
  @import '../../assets/css/electronicClassBoard.css';

  .dataScreen_content_bottom_box8_bottom {
    height: 100%;
  }

  .dataScreen_content_bottom_box8_bottom_box {
    margin-bottom: 15px;
    height: calc(50% - 15px);
  }
  .dataScreen_content_bottom_box8_bottom_box_bottom{
  height: calc(100% - 30px);
  overflow: hidden;
}
  .dataScreen_content_bottom_box8_bottom_box:last-child {
    margin-bottom: 0;
  }

  .dataScreen_content_bottom_box8_bottom_box_top {
    background-color: #FFF4E3;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .dataScreen_content_bottom_box8_bottom_box_top_img {
    width: 20px;
    margin-right: 5px;
  }

  .dataScreen_content_bottom_box8_bottom_box_top_span {
    font-size: 0.9em;
    color: #333;
  }

</style>
