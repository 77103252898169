<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="UploadQuestions">
      <div class="UploadQuestionsTop">
        <div class="UploadQuestionsTopCenter">
          <div>
            <div><span style="font-size: 1.2em;font-weight: 700;">{{ allCount }}</span> <span
                style="font-size: 0.8em;">道</span></div>
            <div style="font-size: 0.7em;">上传试题</div>
          </div>
        </div>
        <div :id="record.id" style="width: 100%;height: 100%;"></div>
      </div>
      <div class="UploadQuestionsBottom">
        <vue-seamless-scroll v-if="list.length>=1" :data="list" style="height: 100%;width: 90%;overflow: hidden;"
          :class-option="defaultOption">
          <div v-for="(item, index) in list" :key="index" class="UploadQuestionsLine">
            <div class="UploadQuestionsBox">
              <img class="UploadQuestionsBg" src="../../assets/img/imgStaly1/ranking_bg.png" alt="">
              <div class="UploadQuestionsOnes">
                <div class="UploadQuestionsTitle">
                  <div class="UploadQuestionsIndex">
                    <img v-if="index == 0" class="UploadQuestionsBg" src="../../assets/img/imgStaly1/ranking_no1.png" alt="">
                    <img v-else-if="index == 1" class="UploadQuestionsBg" src="../../assets/img/imgStaly1/ranking_no2.png" alt="">
                    <img v-else-if="index == 2" class="UploadQuestionsBg" src="../../assets/img/imgStaly1/ranking_no3.png" alt="">
                    <img v-else class="UploadQuestionsBg" src="../../assets/img/imgStaly1/ranking_no4.png" alt="">
                    <div class="UploadQuestionsNo">NO.{{ index + 1 }}</div>
                  </div>
                  <div class="UploadQuestionsOnesItem">
                    {{ item.teacherName }}
                  </div>
                </div>
                <div style="width: 10em;" class="UploadQuestionsOnesItem">
                  主观题<span class="UploadQuestionsOnesItemSpan">{{ item.zhuguanCount }}</span>道
                </div>
                <div style="width: 10em;" class="UploadQuestionsOnesItem">
                  客观题<span class="UploadQuestionsOnesItemSpan">{{ item.keguanCount }}</span>道
                </div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/pie')
  require('echarts/lib/chart/line')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  import {
    bigScreenUploadQuestion
  } from '@/api/other'
  export default {
    name: "UploadQuestions",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
      // vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length           
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        spinning: false,
        list: [],
        zhuguanCount: 0,
        keguanCount: 0,
        allCount: 0
      };
    },
    methods: {
      loadData(sType) {
        this.spinning = true
        bigScreenUploadQuestion(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
            this.zhuguanCount = 0
            this.keguanCount = 0
            for (let i = 0; i < resData.length; i++) {
              this.zhuguanCount += resData[i].zhuguanCount * 1
              this.keguanCount += resData[i].keguanCount * 1
            }
            this.allCount = this.zhuguanCount + this.keguanCount
            let options = {
              tooltip: {
                trigger: 'item'
              },
              series: [
                {
                  type: 'pie',
                  radius: ['50%', '70%'],
                  label: {
                    show: true,
                    lineHeight: this.record.fontSize,
                    formatter: (arg) => {
                      console.log(arg)
                      return '{name|' + arg.name + '}\n\{value|' + arg.value + '道}  {allCount|' + (this.allCount ? (arg.value/this.allCount*100).toFixed(0) + '%' : '-') +'}'
                    },
                    rich: { // 改变标示文字的颜色
                      "name": {
                        color: "#00baff",
                        fontSize:  this.record.fontSize - 10
                      },
                      "value": {
                        color: "#fff",
                        align: 'left',
                        fontSize:  this.record.fontSize- 10
                      },
                      "allCount": {
                        color: "#fff",
                        align: 'left',
                        fontSize: this.record.fontSize- 10
                      }
                    }
                  },
                  data: [
                    {
                      value: this.keguanCount,
                      name: '客观题',
                      itemStyle: {
                        color: '#ffb950'
                      }
                    },
                    {
                      value: this.zhuguanCount,
                      name: '主观题',
                      itemStyle: { color: '#12afff' }
                    }
                  ]
                }
              ]
            }
            let echartsBox1 = echarts.init(document.getElementById(this.record.id))
            echartsBox1.setOption(options)
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
    },
    mounted() {
      this.loadData()
    }
  };

</script>

<style scoped>
  .UploadQuestions {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/img/imgStaly1/shitiupload_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .UploadQuestionsTop {
    position: relative;
    width: 100%;
    height: 35%;
  }

  .UploadQuestionsTopCenter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .UploadQuestionsBottom {
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
  }

  .UploadQuestionsLine {
    width: 100%;
  }
  .UploadQuestionsBox {
    position: relative;
    width: 100%;
    height: 2em;
    margin: 1em 0;
  }
  .UploadQuestionsBg {
    width: 100%;
    height: 100%;
  }
  .UploadQuestionsOnes {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .UploadQuestionsTitle {
    height: 100%;
    width: 10em;
    display: flex;
    align-items: center;
  }
  .UploadQuestionsIndex {
    position: relative;
    height: 100%;
    width: 4.5em;
    margin: 0 0.8em;
  }
  .UploadQuestionsNo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-size: 0.8em;
    font-weight: 700;
    font-style: italic;
    color: #fff;
  }
  .UploadQuestionsOnesItem {
    font-size: 0.7em;
    color: #eff0f8;
    display: flex;
  }
  .UploadQuestionsOnesItemSpan {
    color: #e4a85c;
  }
</style>