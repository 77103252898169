<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="ClassHour">
      <div class="ClassHourTitle">
        <div>
          教师姓名
        </div>
        <div>
          应备课时
        </div>
        <div>
          实备课时
        </div>
      </div>
      <vue-seamless-scroll v-if="list.length>=1" :data="list" :class-option="defaultOption" class="seamless-warp">
        <div v-for="(item, index) in list" :key="index" class="ClassHourBox">
          <div>
            {{ item.teacherName }}
          </div>
          <div>
            {{ item.needCount }}
          </div>
          <div>
            {{ item.actualCount }}
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </a-spin>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import {
    bigScreenTeacherMateriaKeshi
  } from '@/api/other'
  export default {
    name: "ClassHour",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        list: [],
        spinning: false
      };
    },
    methods: {
      getTeacherMateriaKeshi() {
        bigScreenTeacherMateriaKeshi(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      }
    },
    mounted() {
      this.getTeacherMateriaKeshi()
    }
  };

</script>

<style scoped>
  .ClassHour {
    width: 100%;
    height: 100%;
  }
  .ClassHourTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: url('../../assets/img/imgStaly1/table_head_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #79bde2;
  }
  .ClassHourTitle>div {
    display: flex;
    justify-content: center;
    width: 33%;
    font-size: 0.8em;
    padding: 0.8em;
  }
  .ClassHourBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: url('../../assets/img/imgStaly1/table_content_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    margin-top: 1em;
  }
  .ClassHourBox>div {
    display: flex;
    justify-content: center;
    width: 33%;
    font-size: 0.8em;
    padding: 0.8em;
  }
</style>