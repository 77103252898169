<template>
    <a-spin :spinning="spinning" class="spinning_box">
      <div class="CampusPopulationOverview">
          <div class="CampusPopulationOverview_content">
              <div class="CampusPopulationOverview_content_box">
                  <span class="CampusPopulationOverview_content_box_span">
                      <span class="CampusPopulationOverview_content_box_span_span">{{stuCount}}</span>
                      <span style="font-size: 0.5em;">人</span>
                  </span>
                  <img src="../../assets/img/imgStaly1/student_icon.png" class="CampusPopulationOverview_content_box_img" alt="">
                  <span class="CampusPopulationOverview_content_box_span2">学生</span>
              </div>
              <div class="CampusPopulationOverview_content_box">
                <span class="CampusPopulationOverview_content_box_span">
                    <span class="CampusPopulationOverview_content_box_span_span">{{teacherCount}}</span>
                    <span style="font-size: 0.5em;">人</span>
                </span>
                <img src="../../assets/img/imgStaly1/teacher_icon.png" class="CampusPopulationOverview_content_box_img" alt="">
                <span class="CampusPopulationOverview_content_box_span2">教师</span>
            </div>
            <div class="CampusPopulationOverview_content_box">
                <span class="CampusPopulationOverview_content_box_span">
                    <span class="CampusPopulationOverview_content_box_span_span">{{employeeCount}}</span>
                    <span style="font-size: 0.5em;">人</span>
                </span>
                <img src="../../assets/img/imgStaly1/staff_icon.png" class="CampusPopulationOverview_content_box_img" alt="">
                <span class="CampusPopulationOverview_content_box_span2">职工</span>
            </div>
          </div>
      </div>
    </a-spin>
  </template>
  
  <script>
    import { subSchoolPeopleCount } from '@/api/other'
    export default {
      name: "CampusPopulationOverview",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      data() {
        return {
          spinning: false,
          teacherCount: '-',
          employeeCount: '-',
          stuCount: '-'
        };
      },
      methods: {
        loadData() {
        this.spinning = true
        const that = this
        subSchoolPeopleCount({
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData ?this.record.useFakeData:0
          },
          (resp) => {
            const resData = resp.data
           that.teacherCount = resData.teacherCount
           that.stuCount = resData.stuCount
           that.employeeCount = resData.employeeCount
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
    
      },
      mounted() {
          console.log(this.record)
          this.loadData()
      }
    };
  
  </script>
  
  <style scoped>
  .CampusPopulationOverview{
      width: 100%;
  }
  .CampusPopulationOverview_content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .CampusPopulationOverview_content_box{
      width: 32%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .CampusPopulationOverview_content_box_span{
      color: #ccffff;
      display: flex;
      align-items: center;
  }
  .CampusPopulationOverview_content_box_span_span{
      display: inline-block;
      margin-right: 5px;
      font-size: 0.8em;
  }
  .CampusPopulationOverview_content_box_span2{
    color: #ccffff;
    font-size: 0.5em;
    display: inline-block;
  }
  .CampusPopulationOverview_content_box_img{
      width: 70%;
      margin: 10px 0;
  }
  </style>
  