<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="dataScreen_content_2box_top">
            <video class="dataScreen_content_2box_top_img" autoplay loop muted>
              <source :src="record.mp4" type="video/mp4">
            </video>
          </div>
    </a-spin>
  </template>
  
  <script>
    import {} from '@/api/other'
    export default {
      name: "CentreBanner",
      props:{
        record: {
          type: Object
        },
        currentSubSchoolId: {
          type: String
        }
      },
      watch: {
        currentSubSchoolId: function (e, n) {
          console.log(e, n);
        },
      },
      data() {
        return {
          spinning: false
        };
      },
      methods: {
      },
      mounted() {
      }
    };
  
  </script>
  
  <style scoped>
  
  </style>
  