<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="NumberExercisesClass">
      <div class="NumberExercisesClassTop">
        <img class="bgImg" src="../../assets/img/imgStaly1/study_statitle_bg1.png" alt="">
        <div class="NumberExercisesClassTitle">
          {{ record.title }}
        </div>
      </div>
      <div class="NumberExercisesClassBox">
        <div class="NumberExercisesClassBoxTop">
          <div class="NumberExercisesClassClass">
            班级
          </div>
          <div class="NumberExercisesClassNum">
            做题数量
          </div>
        </div>
        <div class="NumberExercisesClassScroll">
          <vue-seamless-scroll v-if="list.length>=1" :data="list" @ScrollEnd="ScrollEnd" :class-option="defaultOption" class="seamless-warp" style="margin-top: 0;">
            <div v-for="(item, index) in list" :key="index" class="NumberExercisesClassItem">
              <div class="NumberExercisesClassClass">
                <div
                  class="NumberExercisesClassIcon"
                  :class="index === 0 ? 'NumberExercisesClassIcon0' : index === 1 ? 'NumberExercisesClassIcon1' : index === 2 ? 'NumberExercisesClassIcon2' : ''"
                >
                  {{ index + 1 }}
                </div>
                {{ item.name }}
              </div>
              <div class="NumberExercisesClassNum">
                {{ item.count }}
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import {
    bigScreenZixishiOutSideLearningCount
  } from '@/api/other'
  export default {
    name: "NumberExercisesClass",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length             
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
        }
      }
    },
    data() {
      return {
        list: [],
        spinning: false
      };
    },
    methods: {
      loadData() {
        bigScreenZixishiOutSideLearningCount(
          {
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      ScrollEnd() {
        console.log("ScrollEnd")
      }
    },
    mounted() {
      this.loadData()
    }
  };

</script>

<style scoped>
  .NumberExercisesClass {
    width: 100%;
    height: 100%;
  }
  .NumberExercisesClassTop {
    position: relative;
    width: 100%;
    height: 10%;
  }
  .NumberExercisesClassTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 1.2em;
    font-weight: 700;
  }
  .NumberExercisesClassBox {
    width: 100%;
    height: 90%;
  }
  .NumberExercisesClassBoxTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: url('../../assets/img/imgStaly1/table_head_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #79bde2;
  }
  .NumberExercisesClassScroll {
    width: 100%;
    height: calc(100% - 2.4em);
  }
  .NumberExercisesClassBoxTop>div {
    font-size: 0.8em;
    padding: 0.8em;
  }
  .NumberExercisesClassClass {
    display: flex;
    justify-content: left;
  }
  .NumberExercisesClassNum {
    display: flex;
    justify-content: right;
  }
  .NumberExercisesClassIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: #044bb5;
    margin-right: 0.8em;
  }
  .NumberExercisesClassIcon0 {
    background-color: #ffb950;
    color: #041966;
  }
  .NumberExercisesClassIcon1 {
    background-color: #6ba6fe;
    color: #041966;
  }
  .NumberExercisesClassIcon2 {
    background-color: #ee8436;
    color: #041966;
  }
  .NumberExercisesClassItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-image: url('../../assets/img/imgStaly1/table_content_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    margin-top: 1em;
  }
  .NumberExercisesClassItem>div {
    font-size: 0.8em;
    padding: 0.8em 1.5em;
  }
  .bgImg {
    width: 100%;
    height: 100%;
  }
</style>