<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="MonthHomeWork">
      <div class="MonthHomeWorkLeft">
        <img src="../../assets/img/imgStaly1/homework_icon.png" style="max-width: 90%;max-height: 90%;" alt="">
      </div>
      <div class="MonthHomeWorkRight">
        <div class="MonthHomeWorkRightBox">
          <div>
            <div>
              布置作业数：<span>{{ list.homework }}</span>份
            </div>
            <div>
              作业批改率：<span>{{ list.approvePer }}</span>%
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import {
    bigScreenTeacherHomework
  } from '@/api/other'
  export default {
    name: "MonthHomeWork",
    props: {
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    components: {
    },
    data() {
      return {
        list: {},
        spinning: false
      };
    },
    methods: {
      getTeacherHomework() {
        bigScreenTeacherHomework(
          {
            disciplineIds: this.record.xuekeIds.join(','),
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            this.list = resData
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      }
    },
    mounted() {
      this.getTeacherHomework()
    }
  };

</script>

<style scoped>
  .MonthHomeWork {
    width: 100%;
    height: 100%;
    background-color: #041869;
    border: 1px solid #133d95;
    border-radius: 0.3em;
    display: flex;
    justify-content: space-between;
  }
  .MonthHomeWorkLeft {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MonthHomeWorkRight {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MonthHomeWorkRightBox {
    width: 80%;
    height: 80%;
    background-image: url('../../assets/img/imgStaly1/homework_content_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MonthHomeWorkRightBox>div {
    width: 60%;
  }
  .MonthHomeWorkRightBox>div>div {
    width: 100%;
    font-size: 0.8em;
    color: #fff;
  }
  .MonthHomeWorkRightBox>div>div>span {
    font-size: 2em;
    font-weight: 700;
  }
</style>