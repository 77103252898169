<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="QuestionsTypeRatio">
            <img class="bgImg" src="../../assets/img/imgStaly1/study_content_smallbg.png" alt="">
            <div class="QuestionsTypeRatioTitle">
                {{ record.title }}
            </div>
            <div class="QuestionsTypeRatioCenter">
                <div class="QuestionsTypeRatioBox">
                    <div class="QuestionsTypeRatioEcharts" :id="record.id"></div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {
        bigScreenZixishiStudyingQuestionType,
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    const echarts = require('echarts/lib/echarts')
    import $ from 'jquery'
    require("echarts/lib/chart/pie")
    export default {
        name: "QuestionsTypeRatio",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
        }, 
        watch: {
            classRoomId () {
                this.classRoom()
            }
        }, 
        computed: {
            index () {
                const myDate = new Date()
                const hours = myDate.getHours()
				const minutes = myDate.getMinutes()
                const myTime = myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        return i
                    }
                    if (myTime < startTime) {
                        return i === 0 ? 0 : i - 1
                    }
                }
                return this.timeData.length === 0 ? 0 : this.timeData.length - 1
            }
        },
        data() {
            return {
                dataInfo: [],
                timeData: [],
                colorArr: [
                    '#b282ec',
                    '#4ec595',
                    '#4878db',
                    '#4095d2',
                    '#d0b17f',
                    '#4f84ff',
                    '#04bfb3',
                    '#f6bd16'
                ],
                spinning: false
            }
        },
        methods: {
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.loadData()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            loadData() {
                this.spinning = true
                bigScreenZixishiStudyingQuestionType(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                        this.pie()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            pie() {
                const data = []
                for (let i = 0; i < this.dataInfo.length; i++) {
                    data.push({
                        name: this.dataInfo[i].name,
                        value: this.dataInfo[i].count,
                        itemStyle: { color: this.colorArr[i % 8] }
                    })
                }
                let echarts1 = echarts.init($(`#${this.record.id}`)[0])
                let option = {
                    series: [
                        {
                            type: 'pie',
                            radius: [20, 50],
                            label: {
                                normal: {
                                    formatter: function(params){ 
                                        if (params.name === '单项选择题') {
                                            return '单选题'
                                        } else if (params.name === '多项选择题') {
                                            return '多选题'
                                        } else {
                                            return params.name
                                        }
                                    }
                                }
                            },
                            data: data
                        }
                    ]
                }
                echarts1.setOption(option)
            },
            classRoom() {
                const that = this
                this.time()
                setInterval(function(){
                    that.loadData()
                }, 10000)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.QuestionsTypeRatio {
    width: 100%;
    height: 100%;
    position: relative;
}
.QuestionsTypeRatioTitle {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    font-size: 1.2em;
    padding-left: 1em;
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}
.QuestionsTypeRatioCenter {
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8%;
    padding-top: 0;
}
.QuestionsTypeRatioBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.bgImg {
    width: 100%;
    height: 100%;
}
.QuestionsTypeRatioEcharts {
    width: 100%;
    height: 100%;
}
</style>