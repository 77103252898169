<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="LearningProgress">
      <div class="LearningProgress_Title">
        <TitlesImg :type="record.titleImgType" />
        <span class="LearningProgress_Span1">{{ record.title }}
          <span class="LearningProgress_Span2">（考查“学生的自主学习能力”，同时也考查学科老师培养学生“学会学习”这一核心素养的成果）</span>
        </span>
      </div>
      <div class="LearningProgress_Box">
        <div class="LearningProgress_top">
          <div class="LearningProgress_Flex">
            <div class="LearningProgress_Flex">
              <div class="LearningProgress_GradeList">
                <span class="LearningProgress_GradeList_span" :class="gradeId==item.id?'LearningProgress_GradeList_span_actived':''"
                  v-for="(item, index) in gradeList" :key="index">{{item.name}}</span>
              </div>
            </div>
            <div>
              <span class="LearningProgress_Hint_span">比率=学生所得分值总和/人数/册别下所有单元的分值总和</span>
            </div>
          </div>
          <div class="LearningProgress_DimensionsList" style="display: flex;">
            <div class="LearningProgress_DimensionsList_Div" v-for="(item, index) in dimensionsList"
              :key="index">
              <span class="LearningProgress_DimensionsList_Span1"
                :style="{'background-color':item.color}"></span>
              <span class="LearningProgress_DimensionsList_Span2">{{item.text}}</span>
            </div>
          </div>
        </div>
        <div :id="record.id" style="width: 100%;height: 100%;">
          <div class="LearningProgress_Echarts"></div>
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
  import {
    bigscreenStudyProgressV2
  } from '@/api/other'
  import $ from 'jquery'
  const echarts = require('echarts/lib/echarts')
  require('echarts/lib/chart/bar')
  require('echarts/lib/component/tooltip')
  require('echarts/lib/component/legend')
  require('echarts/lib/component/title')
  export default {
    name: "LearningProgress",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
    },
    components: { TitlesImg },
    data() {
      return {
        spinning: false,
        dimensionsList: [],
        gradeList: [],
        gradeId: undefined,
        index: 0,
        maxNum: 5,
        dom: ''
      };
    },
    methods: {
      loadData() {
        const that = this
        that.spinning = true
        bigscreenStudyProgressV2({
          disciplineIds: this.record.xuekeIds.join(','),
          gradeId: this.gradeId,
          subSchoolId: this.currentSubSchoolId,
          useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
            const dimensions = ['product']
            const source = []
            const color = ['#0AECB4', '#00B4FF', '#F6A832', '#F47777']
            that.dimensionsList = []
            const series = []
            for (let i = 0; i < resData.bclassNames.length; i++) {
              const e = resData.bclassNames[i]
              const obj = {}
              obj.text = e
              obj.color = color[i % 4]
              dimensions.push(e)
              that.dimensionsList.push(obj)
              series.push(
                {
                  type: 'bar',
                  color: [color[i % 4]],
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        position: 'top',
                        textStyle: {
                          color: '#0AECB4',
                          fontSize: this.record.fontSize/2,
                        },
                      },
                    },
                  },
                }
              )
            }
            for (let j = 0; j < resData.bars.length; j++) {
              const el = resData.bars[j]
              const obj = {}
              obj['product'] = el.disciplineName
              for (let k = 0; k < dimensions.length; k++) {
                const ele = dimensions[k];
                if (k !== 0) {
                  obj[ele] = el.pers[k - 1]
                }
                // source
              }
              source.push(obj)
            }
            let options = {
              legend: {
                textStyle: {
                  color: "#fff"
                },
                x: '100%'
              },
              tooltip: {},
              dataset: {
                // dimensions: dimensions,
                source: source
              },

              grid: {
                show: false,
                top: '20%', // 一下数值可为百分比也可为具体像素值
                right: '0%',
                bottom: '25%',
                left: '10%'
              },
              xAxis: {
                type: 'category',
                axisLabel: { //x轴文字的配置
                  show: true,
                  textStyle: {
                    color: "#fff",
                    fontSize: this.record.fontSize,
                  }
                },
                axisLine: { //x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "#fff",
                    width: 1,
                    type: "solid"
                  }
                },
              },
              dataZoom: [
                //滑动条
                {
                  xAxisIndex: 0, //这里是从X轴的0刻度开始
                  show: false, //是否显示滑动条，不影响使用
                  type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                  startValue: -1, // 从头开始。
                  endValue: that.maxNum, // 一次性展示6个
                  realtime: false, //是否实时更新
                  filterMode: "filter"
                }
              ],
              yAxis: {
                axisLabel: { //x轴文字的配置
                  show: true,
                  textStyle: {
                    color: "#fff",
                    fontSize: this.record.fontSize,
                  },
                  formatter: `{value} %`,
                },
                axisLine: { //x轴线的颜色以及宽度
                  show: true,
                  lineStyle: {
                    color: "#fff",
                    width: 1,
                    type: "solid"
                  },
                },
              },
              // Declare several bar series, each will be mapped
              // to a column of dataset.source by default.
              series: series
            };
            let echartsBox1 = echarts.init($(`#${this.record.id}`)[0].firstChild)
            echartsBox1.setOption(options)
            if (source.length > that.maxNum) {
              var refreshIntervalId = setInterval(function () {
                // 每次向后滚动一个，最后一个从头开始。
                if (options.dataZoom[0].endValue == source.length) {
                  if (that.record.gradeIds.length>1) {
                    // document.getElementById(that.record.id).innerHTML = ''
                    that.Loop()
                    clearInterval(refreshIntervalId)
                  } else {
                    options.dataZoom[0].endValue = that.maxNum
                    options.dataZoom[0].startValue = 0
                    echartsBox1.setOption(options)
                  }
                } else {
                  options.dataZoom[0].endValue = options.dataZoom[0].endValue + 1
                  options.dataZoom[0].startValue = options.dataZoom[0].startValue + 1
                  echartsBox1.setOption(options)
                }
              }, 2000)
            } else {
              echartsBox1.setOption(options)
              if (that.record.gradeIds.length>1) {
                setTimeout(()=>{
                  that.Loop()
                }, 10000)
              }
            }
            
            that.spinning = false
          },
          (err) => {
            console.log('err', err)
            that.spinning = false
            this.$message.error(err)
          }
        )
      },
      Loop() {
        document.getElementById(this.record.id).innerHTML = this.dom
        this.index++
        if(this.index >= this.record.gradeIds.length){
          this.index = 0
        }
        this.gradeId = this.record.gradeIds[this.index]
        this.loadData()
      },
      ScrollEnd() {
        console.log("ScrollEnd")
      }
    },
    mounted() {
      this.dom = document.getElementById(this.record.id).innerHTML
      for(let i = 0; i < this.record.gradeIds.length; i++) {
        this.gradeList.push({
          id: this.record.gradeIds[i],
          name: this.record.gradeNames[i],
        })
      }
      this.gradeId = this.record.gradeIds[0]
      this.loadData()
    }
  };

</script>

<style scoped>
.LearningProgress {
  width: 100%;
  height: 100%;
}
.LearningProgress_Title {
  position: relative;
  width: 100%;
  height: 10%;
  overflow: hidden;
}
.LearningProgress_Img {
  width: 100%;
}
.LearningProgress_Span1 {
  display: flex;
  align-items: center;
  position: absolute;
  color: #CCECF9;
  font-size: 1em;
  top: 0;
  bottom: 0;
  left: 5%;
  margin: auto;
}
.LearningProgress_Span2 {
  font-size: 0.7em;
  color: #CCECF9;
}
.LearningProgress_Box {
  position: relative;
  height: 90%;
  width: 100%;
  margin-top: 10px;
}
.LearningProgress_Echarts {
  width: 100%;
  height: 100%;
}
.LearningProgress_top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.LearningProgress_Flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LearningProgress_GradeList_span {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid inherit;
  color: #53C5F7;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8em;
}

.LearningProgress_Hint_span {
  font-size: 0.7em;
  color: #63D4FC;
}

.LearningProgress_GradeList_span_actived {
  border: 1px solid #53C5F7;
  color: #fff;
}
.LearningProgress_DimensionsList {
  display: flex;
  flex-wrap: wrap;
}
.LearningProgress_DimensionsList_Div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.LearningProgress_DimensionsList_Span1 {
  width: 1em;
  height: 0.7em;
  border-radius: 3px;
  display: inline-block;
}
.LearningProgress_DimensionsList_Span2 {
  font-size: 0.7em;
  color: #fff;
  margin-left: 5px;
}
</style>
