<template>
  <a-spin :spinning="spinning" class="spinning_box">
    <div class="EvaluationImplementation_box">
      <div class="EvaluationImplementation_box_top">
        <div class="EvaluationImplementation_box_top_left">
          <img
            class="EvaluationImplementation_box_top_left_img"
            src="../../assets/img/imgStaly1/kaohe_bg.png"
            alt=""
          />
          <span class="EvaluationImplementation_box_top_left_span">{{
            contentName
          }}</span>
          <div class="EvaluationImplementation_box_top_left_box">
            <div class="EvaluationImplementation_box_top_left_box_div">
              <span class="EvaluationImplementation_box_top_left_box_div_span"
                >应评次数</span
              >
              <span
                class="EvaluationImplementation_box_top_left_box_div_span2"
                style="color: #33ffff"
                >{{ shouldEvaluated }}</span
              >
            </div>
            <div class="EvaluationImplementation_box_top_left_box_div">
              <span class="EvaluationImplementation_box_top_left_box_div_span"
                >实评次数</span
              >
              <span
                class="EvaluationImplementation_box_top_left_box_div_span2"
                style="color: #ff9933"
                >{{ realEvaluation }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="EvaluationImplementation_box_top_right"
          :id="record.id"
        ></div>
      </div>
      <div class="EvaluationImplementation_box_bottom">
        <vue-seamless-scroll
          v-if="dataList.length >= 1"
          :data="dataList"
          @ScrollEnd="ScrollEnd"
          class="seamless-warp"
          :class-option="defaultOption"
        >
          <ul class="ul-scoll">
            <li
              v-for="(item, index) in dataList"
              class="ul_scoll_li"
              :key="index"
            >
              <div class="EvaluationImplementation_box_bottom_box">
                <span class="EvaluationImplementation_box_bottom_box_span">{{
                  item.bclassName
                }}</span>
                <span class="EvaluationImplementation_box_bottom_box_span"
                  >应评次数
                  <span
                    style="
                      font-size: 1.4em;
                      color: #33ffff;
                      font-weight: 700;
                      display: inline-block;
                      margin-left: 5px;
                    "
                    >{{ item.shouldEvaluated }}</span
                  >
                </span>
                <span class="EvaluationImplementation_box_bottom_box_span"
                  >实评次数
                  <span
                    style="
                      font-size: 1.4em;
                      color: #ffcc66;
                      font-weight: 700;
                      display: inline-block;
                      margin-left: 5px;
                    "
                    >{{ item.realEvaluation }}</span
                  >
                </span>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { assessmentLuoshi } from "@/api/other";
import vueSeamlessScroll from "vue-seamless-scroll";
const echarts = require('echarts/lib/echarts')
import "echarts-liquidfill";
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/title");
export default {
  name: "EvaluationImplementation",
  props: {
    record: {
      type: Object,
    },
    currentSubSchoolId: {
      type: String,
    },
    warnType: {
      type: Number,
    },
  },
  watch: {
    currentSubSchoolId: function (e, n) {
      console.log(e, n);
    },
    warnType: function (e, n) {
      console.log(e, n);
    },
  },
  components: {
    vueSeamlessScroll,
  },
  // 监听属性 类似于data概念
  computed: {
    defaultOption() {
      return {
        step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length   
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      spinning: false,
      dataList: [],
      contentName: "",
      shouldEvaluated: 0,
      realEvaluation: 0,
    };
  },
  methods: {
    loadData(sType) {
      this.spinning = true;
      assessmentLuoshi(
        {
          assessmentContentId: this.record.assessmentIds,
          subSchoolId: this.currentSubSchoolId,
          gradeIds: this.record.gradeIds.join(","),
          useFakeData: this.record.useFakeData ? this.record.useFakeData : 0,
        },
        (resp) => {
          const resData = resp.data;
          this.contentName = resData.contentName;
          this.dataList = resData.items;
          this.shouldEvaluated = 0;
          this.realEvaluation = 0;
          for (let i = 0; i < resData.items.length; i++) {
            const e = resData.items[i];
            this.shouldEvaluated = this.shouldEvaluated + e.shouldEvaluated;
            this.realEvaluation = this.realEvaluation + e.realEvaluation;
          }
          this.spinning = false;
          this.drawPie();
        },
        (err) => {
          console.log("err", err);
          this.$message.error(err);
          this.spinning = false;
        }
      );
    },
    drawPie() {
      const that = this;
      const difference = that.shouldEvaluated - this.realEvaluation;
      const differencePer = (
        (this.realEvaluation / that.shouldEvaluated) *
        100
      ).toFixed(2);
      let myEcharts = echarts.init(document.getElementById(that.record.id));
      const options = {
        title: [],
        grid: {
          top: "0%", // 一下数值可为百分比也可为具体像素值
          right: "0%",
          bottom: "0%",
          left: "0%",
        },
        series: [
          {
            type: "liquidFill",
            radius: "90%",
            //水波
            color: ["#0767fe", "#1648cf"],
            data: [1, this.realEvaluation / that.shouldEvaluated],
            center: ["50%", "50%"],
            label: {
              normal: {
                formatter: `完成率\n\n${differencePer}%`,
                // formatter: function (params) {
                //   console.log(params)
                // },
                fontSize: that.record.fontSize,
                fontWeight: 400,
                color: "#99ccff",
              },
            },
            itemStyle: {
              //opacity: 0.7, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
            },
            emphasis: {
              itemStyle: {
                opacity: 0.8, // 鼠标经过波浪颜色的透明度
              },
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 4,
                borderColor: "#003399",
                shadowBlur: 20,
              },
            },
            backgroundStyle: {
              color: "#003399",
            },
          },
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["90%", "91%"],
            hoverAnimation: false,
            borderCap: "round",
            data: [
              {
                name: "",
                value: that.realEvaluation,
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    borderColor: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#43ABF7",
                        },
                        {
                          offset: 1,
                          color: "#72D1FB",
                        },
                      ]
                    ),
                    borderWidth: 5,
                  },
                  emphasis: {
                    borderColor: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#43ABF7",
                        },
                        {
                          offset: 1,
                          color: "#72D1FB",
                        },
                      ]
                    ),
                    borderWidth: 5,
                  },
                },
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
              },

              {
                //画中间的图标
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: {
                    image:
                      "https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1694680535337.png",
                  },
                  shadowColor: "rgba(18, 64, 123, 0.38)",
                  shadowBlur: 8,
                  padding: 10,
                  distance: 50,
                },
              },
              {
                //画剩余的刻度圆环
                name: "",
                value: difference,
                itemStyle: {
                  normal: {
                    color: "#003399",
                  },
                  emphasis: {
                    color: "#003399",
                  },
                },
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                // 解决叠加明显的问题
                name: "",
                value: 0.2,
                itemStyle: {
                  color: "rgba(0,0,0,0)",
                },
              },
            ],
          },
        ],
      };
      myEcharts.setOption(options);
    },
    ScrollEnd() {
      console.log("ScrollEnd");
    },
  },
  mounted() {
    console.log(this.record);
    this.loadData();
  },
};
</script>

<style>
.EvaluationImplementation_box {
  width: 100%;
  height: 100%;
}

.EvaluationImplementation_box_top {
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EvaluationImplementation_box_top_left {
  width: 60%;
  /* height: 100%; */
  position: relative;
}

.EvaluationImplementation_box_top_left_img {
  width: 100%;
}

.EvaluationImplementation_box_top_left_span {
  color: #99ffff;
  position: absolute;
  left: 15%;
  top: 27%;
  font-size: 1em;
  font-weight: 700;
  word-wrap: break-word;
  display: inline-block;
  width: 75px;
}

.EvaluationImplementation_box_top_left_box {
  color: #ccffff;
  position: absolute;
  top: 2%;
  right: 0;
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EvaluationImplementation_box_top_left_box_div {
  height: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.EvaluationImplementation_box_top_left_box_div:first-child {
  padding-bottom: 8%;
}

.EvaluationImplementation_box_top_left_box_div_span {
  font-size: 0.8em;
}

.EvaluationImplementation_box_top_left_box_div_span2 {
  display: inline-block;
  margin-left: 10px;
  font-size: 1em;
  font-weight: 700;
}

.EvaluationImplementation_box_top_right {
  width: 40%;
  height: 100%;
}

.EvaluationImplementation_box_bottom {
  width: 100%;
  height: 67%;
}

.EvaluationImplementation_box_bottom_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 7px 40px;
  background-image: url("../../assets/img/imgStaly1/table_bg03.png");
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.EvaluationImplementation_box_bottom_box_span {
  font-size: 0.8em;
  color: #ccffff;
  display: inline-block;
  margin-bottom: 15px;
  width: 33%;
  text-align: center;
}
</style>
