<template>
  <!-- <a-spin :spinning="spinning" class="spinning_box"> -->
    <div class="dataScreen_content_3box_top">
      <div style="position: absolute;top: 2%;right: 0;left: 0;margin: auto;font-size: 1.5em;color: #b27513;font-weight: 700;display: flex;justify-content: center;">
        {{ record.title }}
      </div>
      <img src="../../assets/img/imgStaly1/jiaoxue_jiucuo_bg.png" class="dataScreen_content_3box_top_img" alt="">
      <div class="ErrorCorrectionLeaderboardTop">
        <div class="ErrorCorrectionLeaderboardTopBox">
          <span class="ErrorCorrectionLeaderboardTopBoxSpan"
          :class="studySubjectText==item?'ErrorCorrectionLeaderboardTopBoxActived':''"
          v-for="(item, index) in studySubject" :key="index">{{item}}</span>
        </div>
      </div>
      <div class="ErrorCorrectionLeaderboardBox">
        <div class="ErrorCorrectionLeaderboardBottom">
          <div class="dataScreen_content_3box_top_content_right_box" :style="{'width': (100/record.gradeIds.length) - 1 + '%'}" v-for="(item, index) in studyList" :key="index">
            <img src="../../assets/img/imgStaly1/jiaoxue_jiucuo_grade.png"
              class="dataScreen_content_3box_top_content_right_box_img" alt="">
            <div class="ErrorCorrectionLeaderboardBottomTitle">
              <span>{{item.gradeName}}纠错榜</span>
            </div>
            <div class="ErrorCorrectionLeaderboardBottomTag">
              <div>
                学生
              </div>
              <div class="ErrorCorrectionLeaderboardBottomTagRight">
                <div style="margin-right: 1em;">
                  纠错题数
                </div>
                <div>
                  纠错率
                </div>
              </div>
            </div>
            <div class="ErrorCorrectionLeaderboardBoxContent">
              <vue-seamless-scroll v-if="item.list.length>=1" :data="item.list" @ScrollEnd="ScrollEnd(item)"
                class="seamless-warp" style="margin-top: 0;" :class-option="defaultOption">
                <ul class="ul-scoll">
                  <li v-for="(items, indexs) in item.list" class="ul_scoll_li" :key="indexs">
                    <div class="ErrorCorrectionLeaderboardBottomBox">
                      <div class="ErrorCorrectionLeaderboardBottomBoxLeft">
                        <div class="ErrorCorrectionLeaderboardBottomBoxLeftNum">
                          <img src="../../assets/img/imgStaly1/jiaoxue_jiucuo_rank_bg.png"  class="ErrorCorrectionLeaderboardBottomBoxLeftNumImg" alt="">
                          <div class="ErrorCorrectionLeaderboardBottomBoxLeftNumIndex">
                            {{items.oderNum}}
                          </div>
                        </div>
                        {{items.stuName}}
                      </div>
                      <div class="ErrorCorrectionLeaderboardBottomBoxRight">
                        <div style="margin-right: 1em;">
                          {{items.controlledCount}}
                        </div>
                        <div style="width: 3em;">
                          {{ items.rate }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </a-spin> -->
</template>

<script>
  import {
    bigScreenErrorCorrecting
  } from '@/api/other'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "ErrorCorrectionLeaderboard",
    props:{
      record: {
        type: Object
      },
      currentSubSchoolId: {
        type: String
      }
    },
    watch: {
      currentSubSchoolId: function (e, n) {
        console.log(e, n);
      }
    },
    components: {
      vueSeamlessScroll
    },
    // 监听属性 类似于data概念      
    computed: {
      defaultOption() {
        return {
          step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
          limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
          hoverStop: false, // 是否开启鼠标悬停stop          
          direction: 1, // 0向下 1向上 2向左 3向右      
          openWatch: true, // 开启数据实时监控刷新dom                
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms) 
        }
      }
    },
    data() {
      return {
        spinning: false,
        studySubject: [],
        studyList: [],
        studySubjectText: '',
        index: 0
      };
    },
    methods: {
      loadData(xuekeId) {
        this.spinning = true
        const that = this
        bigScreenErrorCorrecting({
            disciplineId: xuekeId,
            gradeIds: this.record.gradeIds.join(','),
            subSchoolId: this.currentSubSchoolId,
            useFakeData: this.record.useFakeData
          },
          (resp) => {
            const resData = resp.data
              that.studyList = []
              for (let i = 0; i < resData.length; i++) {
                const e = resData[i]
                let list = []
                for (let j = 0; j < e.list.length; j++) {
                  const el = e.list[j]
                  el.oderNum = j + 1
                  list.push(el)
                }
                that.studyList.push({
                  id: i,
                  gradeName: e.title,
                  list: list
                })
              }
            this.spinning = false
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err)
            this.spinning = false
          }
        )
      },
      ScrollEnd(item) {
        if (item.id == 0) {
          if (this.record.xuekeNames.length>1) {
            this.index++
              if(this.index >= this.record.xuekeNames.length){
                this.index = 0
              }
              this.studySubjectText = this.record.xuekeNames[this.index]
              this.loadData(this.record.xuekeIds[this.index])
          }
        }
      }
    },
    mounted() {
      this.studySubject = this.record.xuekeNames
      this.studySubjectText = this.record.xuekeNames[0]
      this.loadData(this.record.xuekeIds[0])
    }
  };

</script>

<style scoped>
.ul_scoll_li {
  display: flex;
  justify-content: center;
}
.ErrorCorrectionLeaderboardTop {
  position: absolute;
  left: 0;
  top: 8%;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ErrorCorrectionLeaderboardBoxContent {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 20%;
  left: 0;
  padding: 5px 25px;
}
.ErrorCorrectionLeaderboardTopBox {
  display: flex;
  padding: 0 0.2em;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/img/imgStaly1/toggle_btn_bg02.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.ErrorCorrectionLeaderboardTopBoxSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 1em;
  text-align: center;
  padding: 1% 1.2em;
}
.ErrorCorrectionLeaderboardTopBoxActived {
  background-image: url('../../assets/img/imgStaly1/toggle_select_btn02.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.ErrorCorrectionLeaderboardBox {
  position: absolute;
  left: 0;
  top: 15%;
  width: 100%;
  height: 85%;
  padding: 5%;
  padding-bottom: 4%;
  display: flex;
}
.ErrorCorrectionLeaderboardBottom {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.ErrorCorrectionLeaderboardBottomTitle {
  position: absolute;
  top: 3%;
  left: 0;
  color: #DDFAFC;
  font-size: 1em;
  width: 100%;
  text-align: center;
  background-image: url('../../assets/img/imgStaly1/jiaoxue_jiucuo_gradetitle_bg.png');
  background-position: center;
  background-size: 10em 100%;
  background-repeat: no-repeat;
}
.ErrorCorrectionLeaderboardBottomTag {
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #03bdf5;
  font-size: 0.9em;
  padding: 0.2em 0.5em;
  background-color: #1137a7;
  border-radius: 5px;
}
.ErrorCorrectionLeaderboardBottomTagRight {
  display: flex;
}
.ErrorCorrectionLeaderboardBottomBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.ErrorCorrectionLeaderboardBottomBoxLeft {
  display: flex;
  align-items: center;
}
.ErrorCorrectionLeaderboardBottomBoxLeftNum {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
}
.ErrorCorrectionLeaderboardBottomBoxLeftNumImg {
  width: 100%;
  height: 100%;
}
.ErrorCorrectionLeaderboardBottomBoxLeftNumIndex {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ecce92;
}
.ErrorCorrectionLeaderboardBottomBoxRight {
  display: flex;
}
</style>
