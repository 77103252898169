<template>
    <a-spin :spinning="spinning" class="spinning_box">
        <div class="LearningOutcome">
            <div class="LearningOutcomeTop">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_currenttitle_bg.png" alt="">
                <div class="LearningOutcomeTitle">
                    {{ record.title }}
                </div>
            </div>
            <div class="LearningOutcomeContent">
                <img class="bgImg" src="../../assets/img/imgStaly1/study_currencon_bg.png" alt="">
                <div class="LearningOutcomeBox">
                    <div class="LearningOutcomeBoxTop">
                        <div class="LearningOutcomeItemName">
                            学生名称
                        </div>
                        <div class="LearningOutcomeItemCenter">
                            学习专题
                        </div>
                        <div class="textAnimation1">
                            正在学习
                        </div>
                    </div>
                    <div class="LearningOutcomeBoxCenter">
                        <vue-seamless-scroll v-if="dataInfo.length>=1" :data="dataInfo" class="seamless-warp"
                            :class-option="defaultOption">
                            <ul class="ul-scoll">
                                <li v-for="(item, index) in dataInfo" class="ul_scoll_li" :key="index">
                                    <div
                                        class="LearningOutcomeItem"
                                        :class="index % 2 === 0 ? 'LearningOutcomeItem1' : ''"
                                    >
                                        <div class="LearningOutcomeItemName">
                                            <img class="nameImg" v-if="item.headImg" :src="item.headImg" alt="">
                                            <img class="nameImg" v-else src="../../assets/img/imgStaly1/study_currencon_avatar.png" alt="">
                                            {{ item.stuName }}
                                        </div>
                                        <div class="LearningOutcomeItemCenter">
                                            <span>
                                                {{ item.studiedBatchs.join(',') }}
                                            </span>
                                        </div>
                                        <div
                                            class="textAnimation1"
                                            style="-webkit-animation: textAnimation1 1s linear infinite;"
                                        >
                                            {{ formatter(item.studyingBatch) }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import {
        bigScreenZixishiStudying,
        bigScreenZixishiClassRoomKeshi
    } from '@/api/other'
    export default {
        name: "LearningOutcome",
        props: {
            record: {
                type: Object
            },
            currentSubSchoolId: {
                type: String
            },
            classRoomId: {
                type: Number
            }
        },
        components: {
            vueSeamlessScroll
        },
        watch: {
            classRoomId () {
                this.classRoom()
            }
        },     
        computed: {
            defaultOption() {
                return {
                    step: this.record.step ? this.record.step : 1, // 数值越大速度滚动越快   
                    limitMoveNum: this.record.limitMoveNum ? this.record.limitMoveNum : 6, // 开始无缝滚动的数据量 this.dataList.length              
                    hoverStop: false, // 是否开启鼠标悬停stop          
                    direction: 1, // 0向下 1向上 2向左 3向右      
                    openWatch: true, // 开启数据实时监控刷新dom                
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1             
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3      
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)            
                }
            },
            index () {
                const myDate = new Date()
                const hours = myDate.getHours()
				const minutes = myDate.getMinutes()
                const myTime = myDate.setHours(hours, minutes, 0, 0)
                for (let i = 0; i < this.timeData.length; i++) {
                    const startTimeArr = this.timeData[i].startTime.split(':')
                    const endTimeArr = this.timeData[i].endTime.split(':')
                    const startTime = myDate.setHours(startTimeArr[0], startTimeArr[1], 0, 0)
                    const endTime = myDate.setHours(endTimeArr[0], endTimeArr[1], 0, 0)
                    if (myTime >= startTime && myTime <= endTime) {
                        return i
                    }
                    if (myTime < startTime) {
                        return i === 0 ? 0 : i - 1
                    }
                }
                return this.timeData.length === 0 ? 0 : this.timeData.length - 1
            }
        },
        data() {
            return {
                dataInfo: [],
                timeData: [],
                spinning: false
            }
        },
        methods: {
            formatter(val) {
                if (val.length > 10) {
                    return val.substring(0, 5) + '...' + val.substring(val.length - 5, val.length)
                } else {
                    return val
                }
            },
            time () {
                this.spinning = true
                bigScreenZixishiClassRoomKeshi(
                    {
                        classRoomId: this.classRoomId
                    },
                    (resp) => {
                        const resData = resp.data
                        this.timeData = resData
                        this.loadData()
                        this.spinning = false
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                        this.spinning = false
                    }
                )
            },
            loadData() {
                bigScreenZixishiStudying(
                    {
                        classRoomId: this.classRoomId,
                        useFakeData: this.record.useFakeData,
                        startTime: this.timeData[this.index].startTime,
                        endTime: this.timeData[this.index].endTime
                    },
                    (resp) => {
                        const resData = resp.data
                        this.dataInfo = resData
                    },
                    (err) => {
                        console.log('err', err)
                        this.$message.error(err)
                    }
                )
            },
            classRoom() {
                const that = this
                this.time()
                setInterval(function(){
                    that.loadData()
                },10000)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
@import '../../assets/css/animation.css';
.LearningOutcome {
    width: 100%;
    height: 100%;
    position: relative;
}
.LearningOutcomeTop {
    width: 100%;
    height: 8%;
    position: relative;
}
.LearningOutcomeTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
}
.LearningOutcomeContent {
    height: 92%;
    width: 100%;
    position: relative;
}
.LearningOutcomeBox {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding:  0.5em 1em;
}
.LearningOutcomeBoxTop {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3em;
    color: #94b2e5;
    font-size: 1em;
}
.LearningOutcomeBoxCenter {
    width: 100%;
    height: calc(100% - 6em);
}
.LearningOutcomeItem {
    height: 3em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    color: #fff;
}
.LearningOutcomeItem1 {
    border: 1px solid #1a59b2;
    background-color: #034293;
}
.LearningOutcomeItemName {
    display: flex;
    align-items: center;
    height: 100%;
    width: 20%;
    min-width: 10em;
    padding-left: 2em;
}
.LearningOutcomeItemCenter {
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(80% - 13em);
    white-space: nowrap;
    overflow: hidden;
}
.LearningOutcomeItemCenter>span{
    text-overflow: ellipsis;
    overflow: hidden;
}
.textAnimation1 {
    display: flex;
    align-items: center;
    height: 100%;
    width: 12em;
}
.nameImg {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    margin-right: 0.5em;
}
.bgImg {
    width: 100%;
    height: 100%;
}
</style>